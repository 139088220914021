import React from "react";
import './tooltip.css';
import info from '../../../../SharedAssets/info.svg'
import { Tvg } from "./Tvg";
import { Roe } from "./Roe";
import { GrowthRate } from "./GrowthRate";
import { MonthlyExpenses } from "./MonthlyExpenses";
import { ProjectExpenses } from "./ProjectExpenses";
import { Summary } from "./Summary";
import { AllTimeRunningTotalChartTip } from "./AllTimeRunningTotalChartTip";
import { ChartROETip } from "./ChartROETip"
import { ChartTotalValueGainedTip } from "./ChartTotalValueGainedTip"
import { IncludeCapExTip } from "./IncludeCapExTip"
import { IncludeAllPropertiesTip } from "./IncludeAllPropertiesTip"
import { PersonalExpensesMonthlyChartTip } from "./PersonalExpensesMonthlyChartTip"
import { PersonalExpensesYearlyChartTip } from "./PersonalExpensesYearlyChartTip"
import { PropertyValueChartTip } from "./PropertyValueChartTip"
import { WindowCashFlowChartTip } from "./WindowCashFlowChartTip"
import { YearCashFlowChartTip } from "./YearCashFlowChartTip"
import { YearCashFlowMonthChartTip } from "./YearCashFlowMonthChartTip"




export const Tooltip = (props) => {

    return (
        <div className='tooltipInfo' >
            <img src={info} />
            
            {   
                //calculator
                props.tip === 'growthRate'? <><p className='primaryLabel primaryTitle'>What "growth rate" should I use?</p><GrowthRate /> </> :
                props.tip === 'monthlyExpenses'? <><p className='primaryLabel primaryTitle'>What should my monthly expenses be?</p><MonthlyExpenses /> </> :
                props.tip === 'projectExpenses'? <><p className='primaryLabel primaryTitle'>Are you doing renovations?</p><ProjectExpenses /> </> :
            
                //edit properties
                props.tip === 'includeAllPropertiesTip'? <><p className='primaryLabel primaryTitle'>What is "All Properties" data? </p><IncludeAllPropertiesTip /> </> :
                
                //property stats
                props.tip === 'includeCapExTip'? <><p className='primaryLabel primaryTitle'>CapEx & Your Averages</p><IncludeCapExTip /> </> :
                props.tip === 'windowCashFlowChartTip'? <><p className='primaryLabel primaryTitle'>Track a specific window in time!</p><WindowCashFlowChartTip /> </> :
                props.tip === 'yearCashFlowMonthChartTip'? <><p className='primaryLabel primaryTitle'>Track your cash performance for the year!</p><YearCashFlowMonthChartTip /> </> :
                props.tip === 'yearCashFlowChartTip'? <><p className='primaryLabel primaryTitle'>Track your cash performance year over year!</p><YearCashFlowChartTip /> </> :
                props.tip === 'personalExpensesMonthlyChartTip'? <><p className='primaryLabel primaryTitle'>Let's get to passive income retirement!</p><PersonalExpensesMonthlyChartTip /> </> :
                props.tip === 'personalExpensesYearlyChartTip'? <><p className='primaryLabel primaryTitle'>Let's get to passive income retirement!</p><PersonalExpensesYearlyChartTip /> </> :
                props.tip === 'tvg' ? <><p className='primaryLabel primaryTitle'>How do I gain/lose value?</p><Tvg /> </> :
                props.tip === 'chartTotalValueGainedTip'? <><p className='primaryLabel primaryTitle'>Where is your value coming from?</p><ChartTotalValueGainedTip /> </> :
                props.tip === 'roe'? <><p className='primaryLabel primaryTitle'>What is "Return on Equity"?</p><Roe /> </> : 
                props.tip === 'chartROETip'? <><p className='primaryLabel primaryTitle'>Are other investment options better?</p><ChartROETip /> </> :
                props.tip === 'allTimeRunningTotalChartTip'? <><p className='primaryLabel primaryTitle'>How much have you earned overall?</p><AllTimeRunningTotalChartTip /> </> :
                props.tip === 'propertyValueChartTip'? <><p className='primaryLabel primaryTitle'>Property Value & Equity</p><PropertyValueChartTip /> </> :

                
               
                //unused?
                props.tip === 'summary'? <><p className='primaryLabel primaryTitle'>What do these Summary metrics mean?</p><Summary /> </> :

                <p className='primaryLabel primaryTitle'>Oops! Something went wrong!</p>}
            
            
            
            
            
        </div>
    )

}
