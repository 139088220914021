import React, {useState, useEffect} from 'react';
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './AccountRouting.css';
import { AvailableAccounts } from './AvailableAccount';
import loginPic from '../../../../SharedAssets/loginPic.png'
import Piggybank from './Piggybank.svg';
import secure from '../Add/Assets/secure.svg';
import { PlaidModal } from '../../../Plaid/Link/Link';
import { RoundGreenButton } from "../../../CoreComponents/CustomButton/RoundGreenButton";
import {PUT, GET} from "../../../../Utilities/networkRequests";
import axios from 'axios';


export const AccountRouting = (props) => {
    
    const [value, setValue] = useState('');
    const [availableAccounts, setAvailableAccounts] = useState(props.accounts)
    const [plaidLinkToken, setPlaidLinkToken] = useState('')

    let properties = props.properties;
    const [property, setProperty] = useState({id:-1});

    let disabled = !value || (value === 'a' && property.id === -1);


    useEffect(() => {
        availableAccounts.map((item) => {
            item.include=false;
        })
        generatePlaidLink()
    }, []);

    const handleClick = ({id}) => {
        let newProp;
        if (id === -1){
            newProp = {id:-1};
        } else if (id === -2) {
            newProp = {id:-2};
        } else if (id === -3) {
            newProp = {id:-3};
        }
        else {
            let index = properties.findIndex((prop) => prop.id === id);
            newProp = properties[index];
        }
        setProperty(newProp);
    }

  

    const handleChange = (e) => {
        setValue(e.target.value);
    };


    let handleSubmit = async () => {
        let arr = [];
        if (availableAccounts.some((obj) => obj.include === true)){
            if (value === 'a'){
                arr.push(property.id)
            } else if (value === 'b') {
                arr.push(-2)
            } else {
                arr.push(-1)
            }
            
            availableAccounts.map((item) => {
                if (item.include === true) {
                    arr.push(item.account_db_id);
                }
            })
        }


        let res = await PUT(`/plaid/availableAccounts`, {
            arr
        });

        let newAvailable = res.data;
        newAvailable.map((item) => {
            item.include=false;
        })

        setValue('');
        setAvailableAccounts(newAvailable);
        props.refreshSetter({type:'editAccount'});
        if (newAvailable.length<1 && props.misc.onboarding === false) {
            props.toggleDrawer({drawerType:''});
        } else if (newAvailable.length < 1 && props.misc.onboarding === true) {
            props.toggleDrawer({drawerType:'propertyMortgage', newProperty:props.newProperty});
        } 


    }


  
    let handleChangeAccounts = (e, index) => {
        let newValue = e.target.checked;
        
        const newAvailArr = [...availableAccounts];
        newAvailArr[index] = {...newAvailArr[index], [e.target.name]: newValue };
        setAvailableAccounts(newAvailArr);
    }

    const generatePlaidLink = async () => {
        try {
            const response = await GET(`/plaid/create-link-token`);

            if (response.data) {
                setPlaidLinkToken(response.data.link_token)
            }

        } catch (err) {
            console.log(err)
        }
    };

    let container = 'drawerContainer2'
    if (props.location === 'addMenu') {
    container = 'addMenuContainer';
    }
    
    return (
        <form className={container} onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
            
            }}>

            <div className={'scrollControl'}>
                {props.location==='addMenu' ? '' : props.addAccount ? <p className='primaryLabel primaryTitle'>Add a New Financial Institution</p> : <p className='primaryLabel primaryTitle'>Congrats! You have accounts linked through Plaid!</p>}
                
                <div className='center'>
                    {props.location==='addMenu' ? '' : props.addAccount ? <img src={Piggybank} style={{marginBottom:'20px'}} /> : <img src={loginPic} className='imgFit' /> } 
                   
                    {props.addAccount ? '' : <p className='margTop'>Now set routing rules so WayBoz can do the heavy lifting for you (or link more accounts).</p>}
                    
                    <div className='margTop'>
                        {plaidLinkToken ? <PlaidModal publicToken={plaidLinkToken} toggleDrawer={props.toggleDrawer} type='transaction' refreshSetter={props.refreshSetter} /> : <p>One moment please: connecting to Plaid</p>}
                        <div className='secureDiv spacing'>
                            <img src={secure} />
                            <p className='manualEnter' onClick={() => props.toggleDrawer({drawerType:'plaidexplainer', plaidToken:plaidLinkToken })}>Secure Connect - Click to Learn More</p>
                        </div>
                    </div>

                </div>
                
                
            {/*Select Account*/}
            {props.location==='addMenu' ? '' : availableAccounts.length > 0 ? <AvailableAccounts availableAccounts={availableAccounts} handleChangeAccounts={handleChangeAccounts} handleChange={handleChange} value={value} properties={properties} property={property} handleClick={handleClick} /> : ''}

        
                

            </div>
            {props.location==='addMenu' ? '' : <div>
                <CustomButton type='submit' disabled={disabled}>DONE</CustomButton>
            </div>}
        </form>
    )

}