import React, { useState } from "react";
import { PropertyAddress } from "../Shared/PropertyAddress";
import { PurchaseDetails } from "../Shared/PurchaseDetails";
import { textfieldColorChanges } from "../Shared/Misc";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox } from "@mui/material";
import { checkboxColorChanges } from "../Shared/Misc";

export const FirstPropertyBody = (props) => {

    return (
        <div>
                
                
                
                {/*PROPERTY ADDRESS*/}
                <p className='secondaryLabelBlue secondaryTitle'>PROPERTY ADDRESS</p>
                <PropertyAddress property={props.property} handleChange={props.handleChange} />

                {/*PURCHASE DETAILS*/}
                <p className='secondaryLabelBlue secondaryTitle'>PURCHASE DETAILS</p>
                <PurchaseDetails purchaseDetails={props.purchaseDetails} handleChangePurchase={props.handleChangePurchase} />

                {/*Current Value*/}
                <div className='groupContainer'>
                    <p className='secondaryLabelBlue secondaryTitle'>PROPERTY VALUE</p>
                    <TextField
                        required
                        id="standard-read-only-input"
                        label="Current Value"
                        name='value'
                        type='number'
                        value = {props.propertyValue.value}
                        variant="outlined"
                        fullWidth
                        onChange={props.handleChangeValue}
                        margin='normal'
                        sx={textfieldColorChanges}
                        onWheel={() => document.activeElement.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }} 
                    />
                </div>

                {/*Income & Expense*/}
                {/*
                <p className='secondaryLabelBlue secondaryTitle'>ESTIMATED INCOME & EXPENSES</p>
                <p className='bodyTextGray placeholderIncExpMargin'>This populates WayBoz with placeholder data to get you started. When you’re ready, go to the Transactions page or use the "+" to easily add all your real transactions so your ROI and tax metrics stay accurate. </p>

                <div className='MultiOnRow'>
                    <TextField
                    required
                    id="standard-read-only-input"
                    type='number'
                    label="Average Monthly Income"
                    name='income'
                    value = {props.income}
                    variant="outlined"
                    className='boxWidth3'
                    onChange={props.handleChangeIncExp}
                    margin='normal'
                    sx={textfieldColorChanges}
                    onWheel={() => document.activeElement.blur()}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }} 
                    />
                    <TextField
                        required
                        id="standard-read-only-input"
                        type='number'
                        label="Average Monthly Expenses"
                        name='expense'
                        value = {props.expense}
                        variant="outlined"
                        className='boxWidth3'
                        onChange={props.handleChangeIncExp}
                        margin='normal'
                        sx={textfieldColorChanges}
                        onWheel={() => document.activeElement.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }} 
                    />
                    </div>*/}
                    
                    {props.hadTrial === false ? '' :
                        <div className='termsRow'>
                            <Checkbox 
                                required
                                sx={checkboxColorChanges}
                                onChange={()=>props.handleChangeCheckbox()}
                                style={{ padding: 0 }}
                            />
                            {props.accountStatus === 'trial' || props.accountState === 'pretrial' ? 
                            <p className={'bodyTextGray leftMargin'}>I agree to be billed $6 per month for this property after my trial period has ended</p>
                            :
                            <p className={'bodyTextGray leftMargin'}>I agree to be billed $6 per month for this property</p>
                            }
                        </div>
                }
                        
                        

            </div>
          
    )
};