import React from 'react';
import './ChartTotalValueGained.css';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { tooltipMonthLabel, yAxisFormatter, axisMonthLabel, axisMonthLabel2 } from '../../../../../CoreStructure/Drawer/Shared/Misc';
import { 
    getColor
    } from '../../../../../../Utilities/functions';




export const ChartTotalValueGained = (props) => {

let tvgColor = getColor('--positive');
let cashFlowColor = getColor('--cashFlow');
let appreciationColor = getColor('--appreciation');
let principleColor = getColor('--principle');
let lineColor = getColor('--lineGray');

let currentDate = new Date();
let currentMonth = currentDate.getMonth();
let currentYear = currentDate.getFullYear();
   

function CustomTooltip({ payload, label, active }) {
    let getStyle = (item) => {
        if (item.name === 'Cash Flow' ){
            return 'row cashFlow'
        } else if (item.name === "Appreciation") {
            return 'row appreciation'
        } else if (item.name === 'Principal') {
            return 'row principle'
        } else {
            return 'row positive'
        }
    }

    let getStyle2 = (item) => {
        if (item.name === 'Overall') {
            return 'flexDisplay borderTop'
        } else {
            return 'flexDisplay'
        }
    }


    if (active) {
      return (
        <div className="custom-tooltip-wide">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{axisMonthLabel2({month:label})} {props.year}</p> 
                </div>

          <div className='payload'>
           
                {payload.map((item, i) => {
                    return (
                        <div key={i} className={getStyle2(item)}>
                            <p className={getStyle(item)} >{`${item.name}`}:</p>
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2
                            }).format((item.value.toFixed(2)))}`}</p>
                        </div>
                    )
                })}

          </div>
         
        </div>
      );
    }
    return null;
  }

 
  let combinedArray = props.monthlyRunningTotalCashFlowForYear.map((item, index) => {
    let total1;
    let total2;
    let total3;
    let total4;

    if(index > currentMonth && props.year === currentYear) {
        total1 = null;
        total2 = null;
        total3 = null;
        total4 = null;
    } else {
        total1 = props.monthlyRunningTotalCashFlowForYear[index] ? props.monthlyRunningTotalCashFlowForYear[index].total : 0;
        total2 = props.monthlyRunningTotalAppreciationForYear[index] ? props.monthlyRunningTotalAppreciationForYear[index].total : 0;
        total3 = props.monthlyRunningTotalPrincipleForYear[index] ? props.monthlyRunningTotalPrincipleForYear[index].total : 0;
        total4 = props.monthlyRunningTotalTVGForYear[index] ? props.monthlyRunningTotalTVGForYear[index].total : 0;
    }
    

    return {
        month: axisMonthLabel({month:item.month}),
        monthNum: item.month,
        cashFlow: total1,
        appreciation: total2,
        principle: total3,
        tvg: total4
    };
});


let arr = combinedArray;

let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;

return (
        <div>
            <ResponsiveContainer width="100%" height={300}>
            <LineChart data={arr} margin={{ top: 5, right: mobile ? 0 : 20, left: mobile ? 0 : 20, bottom: 5 }}>
        
                <CartesianGrid vertical={false} stroke={lineColor} />
                
                <Line name='Cash Flow' type="monotone" dataKey="cashFlow" stroke={cashFlowColor}  />
                <Line name='Appreciation' type="monotone" dataKey="appreciation" stroke={appreciationColor}  />
                <Line name='Principal' type="monotone" dataKey="principle" stroke={principleColor}  />
                <Line name='Overall' type="monotone" dataKey="tvg" stroke={tvgColor}  />
                <Line name='Trending' type="monotone" dataKey="trending" stroke="#03B09B" strokeDasharray="1 1 2" />
                <XAxis  dataKey='month' 
                        angle = '-45'
                        tickMargin='10'
                        interval={mobile ? 1 : 'preserveStartEnd' }
                        padding={{ left: 2 }}
                        fontSize={mobile ? 14 : 16}
                        />
                
                <YAxis  type='number' 
                        tickSize='8'
                        tickCount='5'
                        padding={{ top: 0 }}
                        tickFormatter={yAxisFormatter}
                        fontSize={mobile ? 14 : 16}
                        width={mobile ? 70 : 75}
                        />
                <Tooltip content={<CustomTooltip />} />
            </LineChart>
            </ResponsiveContainer>
        </div>
    )
};