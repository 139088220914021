import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg'
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { ChartTotalValueGained } from './ChartTotalValueGained/ChartTotalValueGained';


 export const TotalVG = (props) => {

return (
    <div>
        <div className='tvg section'> 
            <p className='primaryMetric sectionTitle'>{props.year} Total Earnings: {props.mobileType && props.mobileType !== 'large' ? <br />:''}<FormatNumber value={props.annualTVGForYear} type='dollarRounded' colorMe='yes' /></p>
            <p className='secondaryLabelBlueLight sectionTitle'>In addition to your cash flow earnings of <span className='cashFlow'><FormatNumber value={props.annualCashFlowForYear} type='dollar' /></span>, you've earned <span className='appreciation'><FormatNumber value={props.annualAppreciationForYear} type='dollar' /></span> in appreciation and <span className='principle'><FormatNumber value={props.annualPrincipleForYear} type='dollar' /></span> from having your principal paid, totaling <span className='positive'><FormatNumber value={props.annualTVGForYear} type='dollar' /></span> in total value gained from your {props.propertyID === -1 ? 'properties' : 'property'} in {props.year}.</p>
            <div className='charts'>
                <div className='chartCol secondaryLabelBlue'>
                    <div className='tooltip'>
                        <p>{props.year} Value Gained per Category</p>
                        <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'tvg'})} />
                    </div>
                    { props.mobileType && props.mobileType !== 'large' ? 
                    <div className='bodyTextBlue tableMarg' >
                        <div className='mobileTable cashBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='cashFlow'>Cash Flow</p>
                                <p className='semiBold'><FormatNumber value={props.annualCashFlowForYear} type='dollar'  /> </p>
                            </div>
                        </div>

                        <div className='mobileTable appreciationBorder'>                        
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='appreciation'>Appreciation</p>
                                <p className='semiBold'><FormatNumber value={props.annualAppreciationForYear} type='dollar' /></p>
                            </div>
                        </div>

                        <div className='mobileTable principleBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='principle'>Principal Paid</p>
                                <p className='semiBold'><FormatNumber value={props.annualPrincipleForYear} type='dollar' /></p>
                            </div>
                        </div>

                        <div className='mobileTable tvgBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='headerMetric positive'>Total Value Gained</p>
                                <p className='headerMetric'><FormatNumber value={props.annualTVGForYear} type='dollar' colorMe='yes' /></p>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='metricsTable bodyTextBlue tvgTable'>
                        <div className='metricsRow'>
                            <p className='cashFlow'>Cash Flow</p>
                            <p className='semiBold'><FormatNumber value={props.annualCashFlowForYear} type='dollar'  /> </p>
                        </div>
                        <div className='metricsRow'>
                            <p className='appreciation'>Appreciation</p>
                            <p className='semiBold'><FormatNumber value={props.annualAppreciationForYear} type='dollar' /></p>
                        </div>
                        <div className='metricsRow'>
                            <p className='principle'>Principal Paid</p>
                            <p className='semiBold'><FormatNumber value={props.annualPrincipleForYear} type='dollar' /></p>
                        </div>
                        <div className='line'>
                        </div>
                        <div className='metricsRow'>
                            <p className='headerMetric positive'>Overall</p>
                            <p className='headerMetric'><FormatNumber value={props.annualTVGForYear} type='dollar' colorMe='yes' /></p>
                        </div>
                    </div>
                    }
                </div>
                <div className='chartCol secondaryLabelBlue'>
                    <div className='tooltip'>
                        <p>{props.year} Value Gained Running Total (overall and per category)</p>
                        <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'chartTotalValueGainedTip'})} />
                    </div>
                    <div className='metricsTable bodyTextBlue '>
                        <ChartTotalValueGained 
                            monthlyRunningTotalCashFlowForYear={props.monthlyRunningTotalCashFlowForYear}
                            monthlyRunningTotalAppreciationForYear={props.monthlyRunningTotalAppreciationForYear}
                            monthlyRunningTotalPrincipleForYear={props.monthlyRunningTotalPrincipleForYear}
                            monthlyRunningTotalTVGForYear={props.monthlyRunningTotalTVGForYear}
                            year={props.year}
                            mobileType={props.mobileType}
                        />
                    </div>
                </div>
            </div>
            {/*<div className='tip'>
            <Tip />
        </div>*/}
            
        </div>     
            
    </div>
      
    )

 }