import React, { useState, useEffect } from 'react';
import './Properties.css';
import { PropertyMenu } from './PropertyMenu/PropertyMenu';
import { InfoContainer } from '../../CoreStructure/InfoContainer/InfoContainer';
import { PropertyStats } from './PropertyStats/PropertyStats';
import { Loading } from '../../CoreComponents/Loading/Loading';
import  tooltip from './../../../SharedAssets/tooltip.svg'


import { textfieldColorChanges, checkboxColorChanges } from '../../CoreStructure/Drawer/Shared/Misc'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox } from "@mui/material";

export const Properties =  (props) => {
    let properties = props.properties;
    const [label, setLabel] = useState('All Properties');
    

    let handleChangeCheckbox=props.handleChangeCheckbox;
    let includeCapEx=props.includeCapEx;
    let windowsArr=props.windowsArr;
    let window = props.window;
    let year = props.year;
    let yearsArr=props.yearsArr
    let handleChangeYear=props.handleChangeYear
    let menuSize = props.mobile ? 'small' : 'small'
    let handleChangeYearLocal = (e) => {
        handleChangeYear({e, type:'stats'})
    }
    let handleWindowLocal = (e) => {
        handleChangeYear({e, type:'window'})
    }


    let property=props.property;
    let updateProperty=props.updateProperty;
   

    let selectionStatus=false;
    let allPropsSelected=false;
    if (property.id < 0) {
        allPropsSelected=true;
    } else {
        allPropsSelected=false;
    }


    let idLabel = () => {
        if (property.id < 0) {
            allPropsSelected=true;
            return 'All Properties';
        } else {
            allPropsSelected=false;
            return property.street + ', ' + property.city + ' ' + property.state;
        }
    }

    useEffect(() => { 
        setLabel(idLabel());
    });

let data = props.propertyData;
    
     if (!data) {
        return <Loading />
    }
    else {
        
    return (
        <div className='properties'>
            {/*<InfoContainer label='Portfolio Summary' toggleDrawer={props.toggleDrawer} id={property.id} type='statsSummary' data={data} mobileType={props.mobileType}/>*/}   
           <div>
                <PropertyMenu properties={properties} updateProperty={updateProperty} property={property} type='stats' />
           </div>
           
                <div className='checkboxWrap'>
                    <div className='yearSelectorStats'>
                        <div className='windowSelector'>
                            <TextField
                                id="outlined-select-state"
                                select
                                required
                                name='year'
                                label="Year"
                                value={year}
                                onChange={handleChangeYearLocal}
                                className='selectClass'
                                sx={{...textfieldColorChanges, width: '100%'}}
                                InputProps={{ sx: { textAlign: 'left' } }} // Align text to the left
                                margin='normal'
                                size={menuSize}
                            >
                                {yearsArr.map((option) => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='windowSelector'>
                            <TextField
                                id="outlined-select-state"
                                select
                                required
                                name='window'
                                label="Window"
                                value={window}
                                onChange={handleWindowLocal}
                                className='selectClass'
                                sx={{...textfieldColorChanges, width: '100%'}}
                                InputProps={{ sx: { textAlign: 'left' } }} // Align text to the left
                                margin='normal'
                                size={menuSize}
                            >
                                {windowsArr.map((option) => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div> 
                    <div className='checkboxLine'>
                            <Checkbox
                                checked={includeCapEx}
                                sx={checkboxColorChanges}
                                onChange={()=>props.handleChangeCheckbox()}
                                style={{ padding: 0 }}
                            />
                        
                        <div className='tooltip'>
                            <p className= 'tertiaryLabel'>Include Capital Expenditures in Averages</p>
                            <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'includeCapExTip'})} />
                        </div>
                    </div>
                </div>
            <div className='propertyStats'>
                    <InfoContainer label={label} toggleDrawer={props.toggleDrawer} id={property.id} type='stats' >
                        <PropertyStats data={data} 
                                        id={property.id} 
                                        toggleDrawer={props.toggleDrawer} 
                                        mobileType={props.mobileType} 
                                        year={year}
                                        loading={props.loading} />
                    </InfoContainer>
            </div>
        </div>
    )
    }
};