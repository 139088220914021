import React from "react";
import { useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import './Login.css';
import loginPic from '../../SharedAssets/loginPic.png';
import logo from '../../SharedAssets/logo.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { RoundGreenButton } from '../CoreComponents/CustomButton/RoundGreenButton';
import { passwordColorChanges } from "../CoreStructure/Drawer/Shared/Misc";
import { setToken } from "../../Utilities/auth/auth";
import { API_ENDPOINT } from "../../api/index"


export const ResetPassword = (props) => {
    const {token,id} = useParams(); 
    const [values, setValues] = useState({
        password: '',
        password2: '',
        showPassword: false,
        token: token,
        id: id
      });

    const [isError, setIsError] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const goToLogin = (e) => {
        e.preventDefault()
        window.location.href='/login'
    }

    const handleError = (err) => {
        if (err && err.response && err.response.status >= 400) {
            setIsError(true);
            setValues((prevState) => ({
                ...prevState,
                message: err.response.data[0]
            }));
        } else {
            console.log(err);
            setValues((prevState) => ({
                ...prevState,
                message: "an unknown error occured please try again later",
            }));
        }
    };

    const setNewPassword = async () => {

        const data = {
            password: values.password,
            password2: values.password2,
            token: values.token,
            id: values.id
        };

        try {
            const response = await axios.post(`${API_ENDPOINT}/auth/send-new-password`, {
                data,
            });


            if (response.status === 200) {
                setResetSuccess(true)
            }
            
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <div className='top'>
            {props.width > props.breakpoint ? <LeftSideArtwork/> : ''}
            <div className={props.width > props.breakpoint ? 'col' : 'colThin'}>
                <form onSubmit={(e) => {e.preventDefault()}}>
                    <div className='contentDiv widthControl'>
                        <img className='space' src={logo}></img>
                        {resetSuccess ? 
                        <>
                            <p style={{"width": "300px", "marginTop": "15px", "marginBottom": "15px", "color": "#202B49", "textAlign": "center"}}>Password change successful! Please click below to log in.</p>
                            <RoundGreenButton onClick={goToLogin} className='space' type='submit'>Login</RoundGreenButton>
                        </>
                        :
                        <>
                        <div className=' fields'>
                                <TextField 
                                id="standard-basic" 
                                label="Enter New Password" 
                                variant="standard" 
                                value={values.password}
                                type='password'
                                onChange={handleChange('password')}
                                fullWidth 
                                sx={passwordColorChanges}
                                error={isError}
                                helperText={isError ? "an error occured please try again later" : ""}
                            />
                        </div>
                    
                        <div className='space fields'>
                            <FormControl variant="standard" sx={passwordColorChanges} fullWidth >
                                <InputLabel htmlFor="standard-adornment-password">Enter Password Again</InputLabel>
                                <Input
                                    
                                    id="standard-adornment-password"
                                    label="Enter Password Again" 
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password2}
                                    onChange={handleChange('password2')}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>     
                        
                        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                            {!values.password || !values.password2 ? <RoundGreenButton className='space' disabled >SET NEW PASSWORD</RoundGreenButton> : <RoundGreenButton onClick={setNewPassword} className='space' type='submit'>SET NEW PASSWORD</RoundGreenButton>}
                        </div>
                        </>}
                    </div>
                </form>
            </div>
        </div>
    )
}

const LeftSideArtwork = (props) => {
    return (
        <div className='col firstcol'>
            <div className='contentDiv'>
                <img className='space' src={loginPic}></img>
                <p className='space primaryLabel'>Welcome to WayBoz</p>
                <p className='space secondaryLabelGray'>Portfolio ROI tracking for real estate investors</p>
            </div>
        </div>
    )
}

