import React, { useRef, useEffect, useState } from 'react';
import { BarChart, Line, ComposedChart, ReferenceLine, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Bar, ResponsiveContainer, ReferenceDot, Brush } from 'recharts';
import { 
    getColor
    } from '../../../../../Utilities/functions';
import { yAxisFormatter, axisMonthLabel, axisMonthLabel2 } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { tooltipMonthLabel } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';

export const YearCashFlowMonthChart = (props) => {
    
    const [width, setWidth] = useState(null);
    const divRef = useRef(null);

    // this is all wrong, should it be based on window? or shrunk to fit an entire year?
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth()+1;

    let startingIndex = 0;
    if (currentMonth <= 3 ) {
        startingIndex = 0;
    } else if (currentMonth >= 9) {
        startingIndex = 6;
    } else {
        startingIndex = currentMonth - 3;
    }
    
  
    useEffect(() => {
        const handleResize = () => {
          if (divRef.current) {
            const currentWidth = divRef.current.clientWidth;
            setWidth(currentWidth);
          }
        };
    
        // Initial width calculation
        handleResize();
    
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);



    let expense = getColor('--negative');
    let income = getColor('--positive');
    let lineColor = getColor('--lineGray');
    let cashflow = getColor('--cashFlow');
    let data=props.data;

    let getStyle = (item) => {
        if (item === 'Income' ){
            return 'positive'
        } else if (item === "Expense") {
            return 'negative'
        } else {
            return 'cashFlow'
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    let formatNum = (num) => {
        return formatter.format(num);
    }

    let getStyle2 = (item) => {
        if (item.name === 'Cashflow') {
            return 'flexDisplay borderTop sumPadTop'
        } else {
            return 'flexDisplay'
        }
    }


    function CustomTooltip({ payload, label, active }) {
        
        if (active) {
            let index = payload[0].payload.monthNum-1;
          return (
            <div className="custom-tooltip">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{axisMonthLabel2({month:label})} {props.year}</p> 
                </div>
    
                <div className='payload'>
                    
                        {payload.map((item, i) => {

                            return (
                                <div>
                                    <div key={i} className={getStyle2(item)}>
                                        <p className={getStyle(item.name)} >{item.name === 'Cashflow' ? "Cash Flow" : item.name}:</p>
                                        <p className={"value"}>{`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2
                                        }).format((item.value.toFixed(2)))}`}</p>
                                    </div>
                                    <div className='quadLabelLight avgMarBottom'>
                                        <p className='semiBold avgMonthMarBottom'>
                                            <FormatNumber value={item.name === 'Cashflow' ? props.averageMonthlyCashFlowByMonth[index].total : item.name === 'Income' ? props.averageMonthlyIncomeByMonth[index].total : props.averageMonthlyExpenseByMonth[index].total} type='dollar'  />
                                        </p>
                                        <p><em>
                                            <FormatNumber value={item.name === 'Cashflow' ? props.averageMonthlyCashFlow : item.name === 'Income' ? props.averageMonthlyIncome : props.averageMonthlyExpense} type='dollar'  />
                                        </em></p>
                                        
                                    </div>
                                </div>
                            )
                        })}
                </div>

                <div className='quadLabelLight avgDisclaimer ' >
                 <p className='semiBold'>* all time {axisMonthLabel2({month:label})} averages</p>
                 <p><em>* all time monthly averages </em></p>
                 </div>
               
               
             
            </div>
          );
        }
        return null;
      }

    
    let combinedArray = props.monthlyIncomeForYear.map((item, index) => {
        let total1 = props.monthlyIncomeForYear[index] ? props.monthlyIncomeForYear[index].total : 0;
        let total2 = props.monthlyExpenseForYear[index] ? props.monthlyExpenseForYear[index].total : 0;
        let total3 = props.monthlyCashFlowForYear[index] ? props.monthlyCashFlowForYear[index].total : 0;
        

    
        return {
            year: props.year,
            month: axisMonthLabel({month:item.month}),
            monthNum: item.month,
            Income: total1,
            Expense: total2,
            Cashflow: total3,
            
        };
    });



    let mobileArray = combinedArray.slice(startingIndex, startingIndex + 6);
    
    let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;


    return (
        
        <div >
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
                <ComposedChart data={mobile ? mobileArray : combinedArray} barGap={2}  margin={{bottom:30}}>
                    <CartesianGrid vertical={false} horizontal={false} stroke={lineColor} />

                    <XAxis dataKey="month" angle={-45} tickMargin='20' interval='preserveStartEnd' fontSize={mobile ? 14 : 16}/>
                    <YAxis tickFormatter={yAxisFormatter} domain={['auto', 'auto']} allowDataOverflow={true} fontSize={mobile ? 14 : 16} width={mobile ? 70 : 75}/>
                    <ReferenceLine y='0'  stroke={lineColor} strokeWidth={1} />

                    <Tooltip content={<CustomTooltip />} />

                    <Bar className='hoverPointer' dataKey="Income" fill={income} barSize={mobile ? 10 : 15} onClick={(payload) => {
                    props.toggleDrawer({drawerType:'trans', window, payload, id:props.propertyID})
                    }}/>
                    <Bar className='hoverPointer' dataKey="Expense" fill={expense} barSize={mobile ? 10 : 15} onClick={(payload) => {
                    props.toggleDrawer({drawerType:'trans', window, payload, id:props.propertyID})
                    }}/>
                    <Line type="monotone" dataKey="Cashflow" stroke={cashflow} strokeWidth={2}  />

                    <ReferenceLine y={props.averageMonthlyIncome} stroke={income} strokeDasharray="7 7" strokeWidth={1} />
                    <ReferenceLine y={props.averageMonthlyExpense} stroke={expense} strokeDasharray="7 7" strokeWidth={1} />
                    <ReferenceLine y={props.averageMonthlyCashFlow} stroke={cashflow} strokeDasharray="7 7" strokeWidth={1} />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
        
    )
}
