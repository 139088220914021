import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/PersonalExpVsPropCashFlowImg.png'

export const PersonalExpensesMonthlyChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin'>One of the main reasons our users love WayBoz is the ability to see if they can retire!</p>

            <p className='paragraphMargin'>Obviously, a key step to retirement is making sure your investments - wherever they may be - cover your personal expenses.
            From a real estate perspective, WayBoz can give you complete transparency into this.</p>
            
            <p className='paragraphMargin'>Although managing your personal expenses is NOT the purpose of WayBoz, we've set it up so you can easily add your personal incomes and expenses as desired and then compare them to your property cash flow. Then, voila, you can see if you are earning enough from your properties to cover any and all personal expenses. </p>

            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin'>In the above chart, the red solid line shows how your personal expenses are trending for each month during the selected year, 
                while the blue solid line shows your property cash flow for each month throughout the year. The horizontal dashed lines represent your <em>average monthly</em> personal expenses and property cash flow for the year.
                As with other charts, you can hover on the chart to see the specific values for each month.</p>
            
            <p className='paragraphMargin'>What's the goal? Get the blue line above the red line. This means that your property cash flow can sufficiently cover all your personal expenses and you can look forward to sunburn on the beach for the rest of your life.</p>
          
        </div>
    
    )

}