import React, {useState, useEffect}  from "react";
import {PUT, GET, DELETE} from "../../../../Utilities/networkRequests";

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import EditGray from '../../../CoreStructure/InfoContainer/Assets/EditGray.svg';
import {PlaidUpdateModal } from '../../../Plaid/Link/Link';

export const ManageInstitution = (props) => {
   
    const [plaidUpdateLinkToken, setPlaidUpdateLinkToken] = useState('')
    const [accounts, setAccounts] = useState([])
   
    useEffect(() => {
        GET(`/plaid/institution/${props.institution}`).then((res)=> {            
            setAccounts(res.data);
            receiveDuplicateAccount(res.data[0].id)
        });
    }, []);


    async function receiveDuplicateAccount(itemID){
        const response = await GET(`/plaid/create-link-token-update?item_id=${itemID}`)

        if (response.data){
            setPlaidUpdateLinkToken(response.data.link_token)
        }
    }
   
    
    let showAccounts = accounts.map((item, index) => {
        return (
                 <div key={index} className='accountGroup acctLeftMarg'>
                    <div className='secondaryLabelBlueSemi'><p>{item.institution_name}</p></div>
                    <div className='numNameContainer secondaryLabelGray'><p>{item.name}</p><p> #xxxxxx{item.account_num}</p></div>
                        
                </div>
        )
    })
    

    let handleRemove = async (e) => {
        if (window.confirm(`This will remove your connection to ${accounts[0].institution_name} entirely. This deletes all accounts and transactions that you have linked. Are you sure you want to continue?`)) {
            props.toggleDrawer({drawerType:''});
            let res = await DELETE(`/plaid/institution/${accounts[0].accountid}`);
            props.refreshSetter({type:'editAccount'});
            //window.location.reload(false);

        } 

    }


    return (
        <div className='drawerContainer2' id="edit-account-container" >
            {accounts.length < 1 ? 'Loading your Data' : 
                <div className='scrollControl'>
                    <p className='primaryLabel primaryTitle'>You've Already Added {accounts[0].institution_name}!</p>
                    <div className='center'>
                        <img className='editImg' src={EditGray} />
                    </div>
                    
        
                        
                            <p className='secondaryLabelBlue secondaryTitle moreMargin'>{accounts.length < 2 ? 'THE ACCOUNT YOU CONNECTED IS:' : 'THE ACCOUNTS YOU CONNECTED ARE:'}</p>            
                            
                            {showAccounts}

                            <p className='secondaryLabelBlue secondaryTitle moreMargin margTop'>WANT TO ADD OR REMOVE ACCOUNTS FOR THIS INSTITUTION?</p>
                            <div className='center moreMargin'>
                                {plaidUpdateLinkToken ? <PlaidUpdateModal publicToken={plaidUpdateLinkToken} type='transaction' toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ""}
                            </div>
                            <p className='removeAccount' onClick={(e) => handleRemove()}>Disconnect {accounts[0].institution_name} & Delete all Insitution Data </p>
                

                </div>
            }

            <div>
                <CustomButton onClick={() => props.toggleDrawer({drawerType:''})} >DONE</CustomButton>
            </div>
        </div>
    )
};