import * as React from 'react';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';

const color = {
    500: '#03B09B',
    600: '#03B09B',
    700: '#03B09B',
  };

export const RoundWhiteButton = styled(ButtonUnstyled)`
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
  font-size: 1rem;
  background-color: white;
  padding: 12px 24px;
  border-radius: 25px;
  color: ${color[500]};
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid #03B09B;
  width: 100%;

  &:hover {
    background-color: white;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: white;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default function UnstyledButtonsSimple() {
  return (
    <Stack spacing={2} direction="row">
      <RoundWhiteButton>Button</RoundWhiteButton>
      <RoundWhiteButton disabled>Disabled</RoundWhiteButton>
    </Stack>
  );
}
