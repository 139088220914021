import React from "react";
import './Add.css';
import { textfieldColorChanges } from "../Shared/Misc";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatDate } from "../Shared/Misc";
import MenuItem from '@mui/material/MenuItem';
import { categories } from '../../../../Utilities/categories';
import { display } from "@mui/system";



export const IncExp = (props) => {
let transaction = props.transaction;
let handleChange = props.handleChangeTransaction



let amountWidth='250px';
if (transaction.amount) {
  amountWidth = `${transaction.amount.length*50+10}px`;

}

let amountStyle = {fontSize: 84};

//need to remove hard coded colors eventually...
let source='Source: Who paid/charged you?'

if (!transaction.category) {

} else if (transaction.category === 'Income: Deposit' || transaction.category === 'Income: Paycheck' || transaction.category === 'Income: Rent' || transaction.category === 'Income: Misc'){
    amountStyle = { fontSize: 84, color:'#03B09B' };
    source = 'Source: Who paid you?'
} else {
    amountStyle = { fontSize: 84, color:'#E55873' }
    source = 'Source: Who charged you?'
}

let getRowStyle = () => {
    let rowStyle = 'amountRow'
    if (!transaction.category) {
        return rowStyle;
    } else if (transaction.category === 'Income: Deposit' || transaction.category === 'Income: Paycheck' || transaction.category === 'Income: Rent' || transaction.category === 'Income: Misc'){
        amountStyle = { fontSize: 84, color:'#03B09B' };
        return rowStyle = 'amountRow amountRowIncome';
    } else {
        amountStyle = { fontSize: 84, color:'#E55873' }
        return rowStyle = 'amountRow amountRowExpense';
    }
}

return (
    <div>
        <TextField
            required
            id="standard-read-only-input"
            label="Date"
            name='date'
            type='date'
            value = {formatDate({date:transaction.date, format:'ymd'})}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            margin='normal'
            sx={textfieldColorChanges}
        />

        <TextField
            fullWidth
            id="outlined-select-state"
            select
            required
            name='category'
            label="Category"
            value={transaction.category}
            onChange={handleChange}
            className='boxWidth2'
            margin='normal'
            sx={textfieldColorChanges}
        >
            
            {categories.map((option) => (
                <MenuItem key={option} value={option} >
                {option}
                </MenuItem>
            ))}

        </TextField>

        
        <TextField
            required
            id="standard-read-only-input"
            label={source}
            name='vendor'
            value = {transaction.vendor}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            margin='normal'
            sx={textfieldColorChanges}
        />

        
        
            <div className={getRowStyle()}>

                {
                transaction.amount && transaction.category && transaction.category !== 'Income: Deposit' && transaction.category !== 'Income: Paycheck' && transaction.category !== 'Income: Rent' && transaction.category !== 'Income: Misc' ? <p>-$</p>
                : transaction.amount ? <p>$</p>
                : !transaction.amount && transaction.category && transaction.category !== 'Income: Deposit' && transaction.category !== 'Income: Paycheck' && transaction.category !== 'Income: Rent' && transaction.category !== 'Income: Misc' ? <p>-</p>
                : ''
                }

                <TextField
                required
                id="standard-number"
                type="number"
                name='amount'
                value = {transaction.amount}
                variant="standard"
                placeholder="$0.00"
                onChange={handleChange}
                style = {{width: amountWidth}}
                sx={{input: {textAlign: "center"}}}
                onWheel={() => document.activeElement.blur()}
                InputProps={{
                    
                    disableUnderline: true,
                    style: amountStyle 
                    }}
                    
                />
            </div>
    </div>
)
}