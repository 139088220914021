import React from 'react';
// Should add defaults for type and colorMe


export const FormatNumber = (props) => {
    let value = props.value;
    let styles= '';

    

    if (props.colorMe==='yes'){
       let color = (value >= 0) ? 'positive' : 'negative';
        styles = `${props.className} ${color}`;
    }else if (props.colorMe==='expense'){
        let color = (value > 0) ? 'negative' : 'positive';
        styles = `${props.className} ${color}`;
    } else if (props.colorMe==='green'){
        let color = 'positive';
        styles = `${props.className} ${color}`;
    } else if (props.colorMe==='red'){
        let color = 'negative';
        styles = `${props.className} ${color}`;
    }

    
    if (props.value < props.average) {
        styles += `negative`
    }
    

    if (props.type==='dollar') {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })

        value = formatter.format(value);
    } else if (props.type === 'dollarRounded') {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
          })

        value = formatter.format(value);
    }
    else if (props.type==='percent') {
        value = `${new Intl.NumberFormat('en-US', { style:'decimal',  minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}%`;
    } else if (props.type==='percentRounded') {
        value = `${new Intl.NumberFormat('en-US', { style:'decimal',  minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)}%`;
    }  else if (props.type==='breakeven') {
        value = `${new Intl.NumberFormat('en-US', { style:'decimal',  minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)} Months`;
        styles = `${props.className} `;
    } else {
        value = value;
    }
    
    
        return <span className={styles}>{value}</span>
    
};