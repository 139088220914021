import React, {useState, useEffect} from 'react';

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './Onboarding.css';

import plaidGreen from './Assets/plaidGreen.svg'
import plaidWhite from './Assets/plaidWhite.svg'
import placeholderGreen from './Assets/placeholderGreen.svg'
import placeholderWhite from './Assets/placeholderWhite.svg'
import spreadsheetGreen from './Assets/spreadsheetGreen.svg'
import spreadsheetWhite from './Assets/spreadsheetWhite.svg'

import { GET } from "../../../../Utilities/networkRequests";
import { PlaidExplainer } from '../Plaid/PlaidExplainer';

import { textfieldColorChanges } from "../Shared/Misc";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { addPlaceholders } from '../../../../api/properties';
import { BulkAdd } from '../BulkAdd/BulkAdd';

export const Transactions = (props) => {
let newProperty = props.newProperty
let propertyID = newProperty.id;
let properties = [newProperty];

// --------- Selections ------------------------ 
const [selected, setSelected] = useState('')

let handleClick = ({clicked}) => {
    setSelected(clicked)
}


// ------- Income and Expense ----------------
const [income, setIncome] = useState('');
    const [expense, setExpense] = useState('');

let handleChangeIncExp = (e) => {
    if (e.target.name === 'income') {
        setIncome(e.target.value);
    } else {
        setExpense(e.target.value);
    }
}


// ------------- Plaid -----------------
    const [plaidLinkToken, setPlaidLinkToken] = useState('')

    useEffect(() => {
        generatePlaidLink()
    }, []);

    const generatePlaidLink = async () => {
        try {
            const response = await GET(`/plaid/create-link-token`);

            if (response.data) {
                setPlaidLinkToken(response.data.link_token)
            }

        } catch (err) {
            console.log(err)
        }
    };


let handleSubmit = async (e) => {
    e.preventDefault();
    if(selected === 'placeholder') {
        await addPlaceholders({
            propertyID,
            income: income,
            expense: expense*-1,})
    }
    props.toggleDrawer({drawerType:'propertyMortgage', newProperty:newProperty});
}






    return (

        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                
                <p className='primaryLabel primaryTitle'>Add Initial Transactions</p> 
                <div className='content introMargin bodyTextBlue'>
                    <p>In order to accurately see how your properties are performing, you'll obviously need to add your transaction history: incomes, expenses, etc</p>
                    <br />
                    <p>Don't worry, we've made it super easy. Select one of the options below to get started.</p>
                </div>

                <div className='buttonFlex'>
                    <div className={selected === 'plaid' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'plaid'})}>
                        {selected === 'plaid' ? <img src={plaidWhite} /> : <img src={plaidGreen} />}
                        <p>Connect Accounts with Plaid</p>
                    </div>
                    <div className={selected === 'spreadsheet' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'spreadsheet'})}>
                        {selected === 'spreadsheet' ? <img src={spreadsheetWhite} /> : <img src={spreadsheetGreen} />}
                        <p>Upload Your Own Spreadsheet</p>
                    </div>
                    <div className={selected === 'placeholder' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'placeholder'})}>
                        {selected === 'placeholder' ? <img src={placeholderWhite} /> : <img src={placeholderGreen} />}
                        <p>Add Placeholder Transactions</p>
                    </div>
                </div>
                <div className='skip tertiaryLabelLight'>
                    <p className={selected === 'skip' ? 'tertiaryLabel hover' : 'hover'} onClick={() => handleClick({clicked:'skip'})}>Skip for Now</p>
                </div>
                
                <p className='bodyTextGray'><em>Pro Trip: If you already have a spreadsheet with all your historical data, first add that! Then connect your accounts with Plaid to manage everything going forward. </em></p>

                {
                selected === 'plaid' ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>Your best option! Learn more below. </p>
                    <PlaidExplainer plaidToken={plaidLinkToken} toggleDrawer={props.toggleDrawer} newProperty={newProperty} type='onboarding'/>
                </div>  

                : selected === 'spreadsheet' && props.mobile ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>Oops! Looks like this option is only available on a laptop or desktop computer! </p>
                </div>  

                : selected === 'spreadsheet' ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>Great Option! Just keep in mind this will only be for your PAST transactions you have on your spreadsheet. We recommend you still connect your accounts with Plaid so adding future transactions is easy. </p>
                    <BulkAdd properties={properties} toggleDrawer={props.toggleDrawer} misc='onboarding' />
                </div>  

                : selected === 'placeholder' ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>This populates WayBoz with placeholder data to get you started. When you’re ready, go to the Transactions page or use the "+" to easily add all your real transactions so your ROI and tax metrics stay accurate. </p>

                    <div className='MultiOnRow'>
                        <TextField
                        required
                        id="standard-read-only-input"
                        type='number'
                        label="Average Monthly Income"
                        name='income'
                        value = {income}
                        variant="outlined"
                        className='boxWidth3'
                        onChange={handleChangeIncExp}
                        margin='normal'
                        sx={textfieldColorChanges}
                        onWheel={() => document.activeElement.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }} 
                        />
                        <TextField
                            required
                            id="standard-read-only-input"
                            type='number'
                            label="Average Monthly Expenses"
                            name='expense'
                            value = {expense}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={handleChangeIncExp}
                            margin='normal'
                            sx={textfieldColorChanges}
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }} 
                        />
                    </div> 
                </div> 
                : selected === 'skip' ? 



                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>Okay... but fair warning, your metrics will look quite sparse until you add transactions... </p>
                </div> 
                : ''}
                     
            </div>
            
            {selected === 'placeholder' || selected === 'skip' ?
            <div>
                <CustomButton type='submit'>NEXT</CustomButton>
            </div>
            : ''}
            
        </form>


    
    )

}