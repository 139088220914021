import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import './reset.css';
import App from './Components/App/App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { PostHogProvider } from 'posthog-js/react'

ReactGA.initialize('G-VSJ3LPTY60')



let REACT_APP_PUBLIC_POSTHOG_HOST = 'https://us.i.posthog.com'
let REACT_APP_PUBLIC_POSTHOG_KEY = 'phc_D29f0sfswGiHHXSS6dJHrYY163gULPGTRWZjt2XaUSZ'

const options = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
}



ReactDOM.render(
    <React.StrictMode>
      <PostHogProvider 
          apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
         >

          <App />
          
      </PostHogProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
