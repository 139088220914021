import React, {useState, useEffect} from 'react';
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './AccountRouting.css';

import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getColor } from '../../../../Utilities/functions';

import { PropertyTab } from '../../../PropertiesMain/Properties/PropertyTab/PropertyTab';

import { checkboxColorChanges } from "../Shared/Misc";

export const AvailableAccounts = (props) => {
let availableAccounts = props.availableAccounts;
let handleChangeAccounts = props.handleChangeAccounts
let properties = props.properties;
let property = props.property;
let handleClick=props.handleClick;

let waybozGreen = getColor('--positive');


let selectionStatus=false;
    let allPropsSelected=false;
    let unassignedSelected=false;

    let personalSelected = false;
    if (property.id === -1) {
        allPropsSelected=true;
    } else if (property.id === -2){
        unassignedSelected=true;
    } else if (property.id === -3){
        personalSelected=true;
    }
    else {
        allPropsSelected=false;
        unassignedSelected=false;
    }


let showProperties = properties.map((item, index) => {
    if (item.id === property.id) {
         selectionStatus=true;
    } else {
         selectionStatus=false;
    }
     return (<div key={index} className='individualTab'><PropertyTab
                key={index}
                street={item.street}
                city={item.city}
                state={item.state}
                onClick={() => handleClick ({id:item.id})}
                selectionStatus={selectionStatus}
                image={item.street_view}
                id = {item.id}
                included={true}
            /></div>)
});


let showAccount = availableAccounts.map((item, index) => {
    return (
        <div key={index} className='accountContainer'>
            <div className='accountGroup'>
                <Checkbox 
                    checked={item.include}
                    onChange={(e)=>handleChangeAccounts(e, index)}
                    sx={checkboxColorChanges} 
                    name="include" 
                />
            </div>
            <div className='accountGroup'>
                <div className='secondaryLabelBlueSemi'><p>{item.institution_name}</p></div>
                <div className='numNameContainer secondaryLabelGray'><p>{item.name}</p><p>#xxxxxx{item.account_num}</p></div>
            </div>
            
            
        </div>
    )
})

    return (
     

            <div>
                
                <p className='secondaryLabelBlue secondaryTitle'>EXISTING ACCOUNTS NEEDING ROUTING RULES:</p>
                {showAccount}




                { availableAccounts.some((obj) => obj.include === true) ?
                <div className='margTop'>
                    <p className='secondaryLabelBlue secondaryTitle'>ALL TRANSACTIONS FOR THE ACCOUNT(S) (select one):</p>
                    <div className='buttonMargin'>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={props.value}
                        onChange={props.handleChange}
                        sx={{
                            "& .MuiRadio-root": { color: waybozGreen },
                            "& .MuiRadio-root.Mui-checked": { color: waybozGreen },
                          }}
                    >
                        <FormControlLabel value="a" control={<Radio required={true} />} label="Are for just ONE property" />
                        <FormControlLabel value="b" control={<Radio required={true} />} label="Are solely personal or NON-PROPERTY transactions " />
                        <FormControlLabel value="c" control={<Radio required={true} />} label="Neither of the above" />
                    </RadioGroup>
                    </div>
                </div>
                :
                ''
                }

                {
                    availableAccounts.some((obj) => obj.include === true) && props.value === 'c' ?
                    <div className='secondaryLabelBlueSemi margTop' style={{textAlign:'center'}}>
                        <p>Perfect, so transactions for this account are either for multiple properties or might include non-property income or expenses too.</p> 
                        <br />
                        <p>We’ve made it so you can very quickly tag new transactions by property or type so all your metrics stay accurate!</p>
                    </div> 
                    : 
                    availableAccounts.some((obj) => obj.include === true) && props.value === 'b' ? 
                    <div className='secondaryLabelBlueSemi margTop' style={{textAlign:'center'}}>
                        <p>Great! We’ll auto-tag all these transactions as “personal” so they won’t impact your property cashflows</p> 
                    </div> 
                    :
                    availableAccounts.some((obj) => obj.include === true) && props.value === 'a' ? 
                    <div className='secondaryLabelBlueSemi margTop'>
                        <p>Great - which property?</p>
                        <div className='propTab'> {showProperties} </div>
                       
                    </div>
                    : ''
                }



            </div>
            
    )

}