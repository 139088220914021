import React from "react";
import './tooltip.css';

export const Roe = () => {

    return (
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin'>Return on Equity (RoE), or Equity Return, basically tells you how all the money you have stuck in your property -- your equity, or the money you would get if you sold the property -- is performing. </p>

            <p className='paragraphMargin'>It’s calculated by comparing how much equity you started the year with against how much value that equity earned you for the year (a.k.a., your Total Value Gained). So if you started the year with <strong>$100,000 in equity</strong>, and you gained <strong>$10,000 in Total Value Gained</strong> (Cash Flow + Appreciation + Principal Paid), then your <strong>RoE was 10%</strong>.</p>

            <p className='paragraphMargin'>Since Return on Equity is a percentage (not a dollar amount), you can compare it to annual returns of other investment options like stocks, CDs, crypto, savings accounts, etc (see next chart). </p>

            <p className='paragraphMargin'>For example, the historical annual return for the S&P 500 is 11.88%. If your RoE is consistently much lower than this, you’re comfortable riding waves, and you want a more hands-off investment, some might see it as a good idea to cash out and move your money there. </p>

            <p className='paragraphMargin'>While Cash on Cash Return or Cap Rate might be good metrics when buying a property, Return on Equity is vital if you want to know how well your current investments are actually performing and whether or not you should cash them out and look elsewhere.</p>
            

        </div>
    )

}