import React, { useRef, useEffect, useState } from 'react';
import { BarChart, Line, ComposedChart, ReferenceLine, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Bar, ResponsiveContainer, ReferenceDot } from 'recharts';
import { 
    getColor
    } from '../../../../../Utilities/functions';
import { yAxisFormatter, axisMonthLabel, axisMonthLabel2 } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { tooltipMonthLabel } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber'; 

export const PersonalExpensesMonthlyChart = (props) => {
    
    



    let expense = getColor('--negative');
    let income = getColor('--positive');
    let lineColor = getColor('--lineGray');
    let cashflow = getColor('--cashFlow');
    let data=props.data;

    let getStyle = (item) => {
        if (item === 'personal_expenses' ){
            return 'negative'
        } else if (item === "Expense") {
            return 'negative'
        } else {
            return 'cashFlow'
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    let formatNum = (num) => {
        return formatter.format(num);
    }

    let getStyle2 = (item) => {
        if (item.name === 'Cashflow') {
            return 'flexDisplay borderTop sumPadTop'
        } else {
            return 'flexDisplay'
        }
    }


    function CustomTooltip({ payload, label, active }) {
        
        if (active) {
            
          return (
            <div className="custom-tooltip-wide">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{axisMonthLabel2({month:label})} {props.year}</p> 
                </div>
    
                <div className='payload'>
                    
                        {payload.map((item, i) => {

                            return (
                                
                                <div >

                                    <div key={i} className={getStyle2(item)}>
                                        <p className={getStyle(item.name)} >{item.name === 'personal_expenses' ? "Personal Expenses" : 'Property Cash Flow'}:</p>
                                        <p className={"value"}>{`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2
                                        }).format((item.value.toFixed(2)))}`}</p>
                                    </div> 
                                    
                                    <div className='quadLabelLight avgMarBottom'>
                                        <p><em><FormatNumber 
                                        value={item.name === 'personal_expenses' ? props.averageMonthlyPersonalExpenseForYear : props.averageMonthlyCashFlowForYear} type='dollar'  />
                                        </em></p>
                                    </div>

                                </div>
                                
                            )
                        })}
                </div>

                <div >

                    <div className='flexDisplay borderTop sumPadTop'>
                        <p >Difference:</p>
                        <p className={"value"}><FormatNumber 
                        value={payload[0].value - payload[1].value} type='dollar' colorMe='yes' /></p>
                    </div> 
                    
                    <div className='quadLabelLight avgMarBottom'>
                        <p><em><FormatNumber 
                        value={props.averageMonthlyCashFlowForYear - props.averageMonthlyPersonalExpenseForYear} type='dollar'  />
                        </em></p>
                    </div>

                </div>

                <div className='quadLabelLight avgDisclaimer ' >
                    <p><em>* monthly averages for {props.year} </em></p>
                 </div>



                
                    
                
             
            </div>
          );
        }
        return null;
      }
    

    
    let combinedArray = props.monthlyPersonalExpenseForYear.map((item, index) => {
        let total1 = props.monthlyPersonalExpenseForYear[index] ? props.monthlyPersonalExpenseForYear[index].total : 0;
        let total2 = props.monthlyCashFlowForYear[index] ? props.monthlyCashFlowForYear[index].total : 0;
        
    
        return {
            month: axisMonthLabel({month:item.month}),
            monthNum: item.month,
            personal_expenses: total1,
            property_cashflow: total2
        };
    });

    
    let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;


// consider not having the average month horizontal line, just write out the numbers in text to unclutter the chart...

// Perhaps for a single metric, use the other chart and use a line chart over the bar for averages... only use the complicated one below for multi metric



    return (
       
        <div>
            
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
                <ComposedChart data={combinedArray} barGap={2} margin={{bottom:30}}>
                    <CartesianGrid vertical={false} horizontal={false} stroke={lineColor} />

                    <XAxis dataKey="month" angle={-45} tickMargin='20' interval={mobile ? 1 : 'preserveStartEnd'} fontSize={mobile ? 14 : 16}/>
                    <YAxis tickFormatter={yAxisFormatter} domain={['auto', 'auto']} allowDataOverflow={true} fontSize={mobile ? 14 : 16} width={mobile ? 70 : 75} />
                    <ReferenceLine y='0'  stroke={lineColor} strokeWidth={1} />

                    <Tooltip content={<CustomTooltip />} />

                    <Bar dataKey="spacing-only"  barSize={mobile ? 10 : 15} />
                    <Bar dataKey="spacing-only" barSize={mobile ? 10 : 15} />  
                    <Line type="monotone" dataKey="property_cashflow" stroke={cashflow} strokeWidth={1.5} />
                    <Line type="monotone" dataKey="personal_expenses" stroke={expense} strokeWidth={1.5}  />

                    <ReferenceLine y={props.averageMonthlyPersonalExpenseForYear} stroke={expense} strokeDasharray="7 7" strokeWidth={1} />
                    <ReferenceLine y={props.averageMonthlyCashFlowForYear} stroke={cashflow} strokeDasharray="7 7" strokeWidth={1} />

                </ComposedChart>
            </ResponsiveContainer>
        
        </div>
        
    )
}
