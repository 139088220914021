import React, {useState, useEffect} from 'react';
import './Add.css';
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import { LinkAccount } from "./LinkAccount";
import { FirstPropertyBody } from "../FirstProperty/FirstPropertyBody";

import { addProperty } from "../../../../api/properties";
import { addMortgage } from "../../../../api/properties";
import { addTransaction } from "../../../../api/properties";
import { formatDate } from "../Shared/Misc";

import propertiesOn from './Assets/propertiesOn.svg';
import propertiesOff from './Assets/propertiesOff.svg';

import mortgageOn from './Assets/mortgageOn.svg';
import mortgageOff from './Assets/mortgageOff.svg';

import incomeOn from './Assets/incomeOn.svg';
import incomeOff from './Assets/incomeOff.svg';

import expenseOn from './Assets/expenseOn.svg';
import expenseOff from './Assets/expenseOff.svg';

import accountOn from './Assets/accountOn.svg';
import accountOff from './Assets/accountOff.svg';

import { AccountRouting } from "../AccountRouting/AccountRouting";

import { calculatePayoff } from "../Shared/Misc";



export const Add = (props) => {
    let properties=props.properties;
    
    const [addType, setAddType] = useState('property');
    const [checkbox, setCheckbox] = useState(false);

    let handleChangeCheckbox = () => {
        setCheckbox(!checkbox);
    }
    

    const handleClick = ({type}) => {
        setAddType(type);
    }
   
    let propertiesImg;
    let mortgageImg;
    let incomeImg;
    let accountImg;
    let newArr;

    if (addType==='property') {
        propertiesImg = propertiesOn;
        mortgageImg = mortgageOff;
        incomeImg = incomeOff;
        accountImg = accountOff;
    } else if (addType==='mortgage') {
        propertiesImg = propertiesOff;
        mortgageImg = mortgageOn;
        incomeImg = incomeOff;
        accountImg = accountOff;
        newArr=props.properties
    } else if (addType==='transaction') {
        propertiesImg = propertiesOff;
        mortgageImg = mortgageOff;
        incomeImg = incomeOn;
        accountImg = accountOff;
        newArr = [{id:-2, street:'Non Property (Personal) Transaction', city:'', state:''}]
        for (let i=0; i<properties.length; i++)
        {
        newArr.push(properties[i])
        }
    } else {
        propertiesImg = propertiesOff;
        mortgageImg = mortgageOff;
        incomeImg = incomeOff;
        accountImg = accountOn;
        newArr = [{id:-2, street:'Non Property (Personal) Transaction', city:'', state:''}]
        for (let i=0; i<properties.length; i++)
        {
        newArr.push(properties[i])
        }
    }



    let newDate = new Date();
    let date = formatDate({date:newDate, format:'ymd'})

//which property?
const [property, setProperty] = useState({id:'', street:'', city:'', state:'', zip:''});
const [method, setMethod] = useState('');


//selected property
let handleChange = (e) => {
    let index = newArr.findIndex((element) => element.id === e.target.value);
    let newProp = newArr[index];
    setProperty(newProp);
}

let handleChangeMethod = (e) => {
    let newMethod = e.target.value;
    setMethod(newMethod);
}

//FOR NEW PROPERTY
const [purchaseDetails, setPurchaseDetails] = useState({purchase_price:'', purchase_date:date, cash_to_close:''});
const [propertyValue, setPropertyValue] = useState({date:date, value:''});
const [propertyNew, setPropertyNew] = useState({street:'', city:'', state:'', zip:''});


let handleChangeNew = (e) => {
    let propInfo = { ...propertyNew, [e.target.name]: e.target.value };
    setPropertyNew(propInfo);
}

let handleChangePurchase = (e) => {
    let propInfo = { ...purchaseDetails, [e.target.name]: e.target.value };
    setPurchaseDetails(propInfo);
}

let handleChangeValue = (e) => {
    let propInfo = { ...propertyValue, [e.target.name]: e.target.value };
    setPropertyValue(propInfo);
}





//MORTGAGE STUFF
    

    const [mortgage, setMortgage] = useState({
        mortgage_name:'',
        loan_amount:'',
        monthly_payment:'',
        rate:'',
        term:'',
        start_date:date,
        payoff_date:date,
        includes_pmi:false,
        includes_taxes:false,
        includes_insurance:false
    });

let handleChangeMortgage = (e, index) => {
    let newValue;
    if (e.target.type === 'checkbox') {
        newValue = e.target.checked;
        } else {
        newValue = e.target.value;
    }
    let newMortgage = {...mortgage}; 
    newMortgage = {...newMortgage, [e.target.name]: newValue };
    setMortgage(newMortgage);

    if ((e.target.name === 'start_date' || e.target.name === 'term') && newMortgage.term && newMortgage.start_date) {
        let value = calculatePayoff({start_date:newMortgage.start_date, term:newMortgage.term});
        newMortgage = {...newMortgage, 'payoff_date': value };
        setMortgage(newMortgage);
    }
 }
//TRANSACTION STUFF
    

const [transaction, setTransaction] = useState({
    vendor:'',
    date:date,
    amount:'',
    category:''
});

let handleChangeTransaction = (e) => {
    let transactionNew = { ...transaction, [e.target.name]: e.target.value };
    setTransaction(transactionNew);
}



let disabled = false;

 
    let submit = async () => {
        if (addType==='property') {
            let newProperty = await addProperty({
                street:propertyNew.street,
                city:propertyNew.city,
                state:propertyNew.state,
                zip:propertyNew.zip,
                purchase_price:purchaseDetails.purchase_price,
                purchase_date:purchaseDetails.purchase_date,
                cash_to_close:purchaseDetails.cash_to_close,
                value:propertyValue.value,
                date:propertyValue.date
            });
            props.refreshSetter({type:'newProperty2'});
            props.toggleDrawer({drawerType:'propertyTransactions', newProperty:newProperty});


        } else if (addType==='mortgage') {
           await addMortgage({
                propertyID: property.id,
                loan_amount: mortgage.loan_amount,
                rate: mortgage.rate,
                term: mortgage.term,
                start_date: mortgage.start_date,
                payoff_date: mortgage.payoff_date,
                monthly_payment: mortgage.monthly_payment,
                includes_pmi: mortgage.includes_pmi,
                includes_taxes: mortgage.includes_taxes,
                includes_insurance: mortgage.includes_insurance,
                mortgage_name: mortgage.mortgage_name
            })
            props.refreshSetter({type:'newMortgage'});
            props.toggleDrawer({drawerType:''});


        } else if (addType === 'transaction'){
            if (transaction.category === 'Income: Deposit' || transaction.category === 'Income: Paycheck' || transaction.category === 'Income: Rent' || transaction.category === 'Income: Misc'){
                await addTransaction({
                    propertyID: property.id,
                    category: transaction.category,
                    date: transaction.date,
                    vendor: transaction.vendor,
                    visibility: true,
                    amount: transaction.amount,
                })
            } else {
                await addTransaction({
                    propertyID: property.id,
                    category: transaction.category,
                    date: transaction.date,
                    vendor: transaction.vendor,
                    visibility: true,
                    amount: transaction.amount*-1,
                })
            }
            
            props.refreshSetter({type:'newTrans'});
            props.toggleDrawer({drawerType:''});

        } 
        else {
            props.toggleDrawer({drawerType:''});
        }
    };



    return (
           
        <form className='drawerContainer2' onSubmit={(e) => {
            e.preventDefault();
            {submit()}

            }}>

            <div className='scrollControl' >
                <p className='primaryLabel primaryTitle'>What do you want to add?</p>
                
                <div className='addMenu'>
                    <div className='menuGroup' onClick={() => handleClick ({type:'property'})}>
                        <img src={propertiesImg} />
                        <p className='secondaryLabelGreen'>Property</p>
                    </div>
                    <div className='menuGroup' onClick={() => handleClick ({type:'mortgage'})}>
                        <img src={mortgageImg} />
                        <p className='secondaryLabelGreen'>Mortgage</p>
                    </div>
                    <div className='menuGroup' onClick={() => handleClick ({type:'transaction'})}>
                        <img src={incomeImg} />
                        <p className='secondaryLabelGreen'>Transaction</p>
                    </div>
                    <div className='menuGroup' onClick={() => handleClick ({type:'account'})}>
                        <img src={accountImg} />
                        <p className='secondaryLabelGreen'>Account</p>
                    </div>
                </div>

                {addType === 'property' 
                ? 
                <FirstPropertyBody accountStatus={props.accountStatus} hadTrial={props.hadTrial} handleChangeCheckbox={handleChangeCheckbox} property={propertyNew} purchaseDetails={purchaseDetails} propertyValue={propertyValue} handleChange={handleChangeNew} handleChangePurchase={handleChangePurchase} handleChangeValue={handleChangeValue} /> :
                addType === 'account' ? <AccountRouting accounts={[]} toggleDrawer={props.toggleDrawer} properties={properties} addAccount={true} location={'addMenu'} refreshSetter={props.refreshSetter} /> :
                <LinkAccount type={addType} mortgage={mortgage} handleChangeMortgage={handleChangeMortgage} transaction={transaction} handleChangeTransaction={handleChangeTransaction} handleChange={handleChange} properties={newArr} property={property} toggleDrawer={props.toggleDrawer} handleChangeMethod={handleChangeMethod} method={method} refreshSetter={props.refreshSetter} />
                }
                
                
            </div>
            <div>
                <CustomButton type='submit' disabled={disabled} >ADD</CustomButton>
            </div>
    </form>
    )

};