import React from "react";
import { addProperty } from "../../../../api/properties";
import { RoundGreenButton2 } from "../../../CoreComponents/CustomButton/RoundGreenButton2";
import { RoundWhiteButton } from "../../../CoreComponents/CustomButton/RoundWhiteButton";
import './StartTrial.css';

import image from '../../../../SharedAssets/StartTrial.svg'

import { getStripeLink, createShortTrial } from "../../../../api/properties";

export const StartTrial = (props) => {

    


let handleBillingClick = async () => {
    let link = await getStripeLink({})
    // If user has a subscription, it is the update subscription link
    // If user doesnt have a subscription, it is the create subscription link
    window.location.href = link;

    //TBD If need these, depends redirect
    //props.refreshSetter({type:'newProperty'});
    //props.toggleDrawer({drawerType:'', id:''});

}

let handleNoBillingClick = async () => {
    // if users says no it creates a 60 day trial via the BE with on billing info
    await createShortTrial({});

    props.toggleDrawer({drawerType:'', id:''});
}

    return (
        <div className="scrollControl">
            <div className='trialImg'>
                <img src={image}  />
            </div>
            <p className='headerMetric blue'>Get 3 Months Free!</p>
            <div className='content bodyTextBlue'>
                <br />
                <p>Congrats on adding your first Property!</p>
                <br />
                <p>Take your time to enjoy and get to know WayBoz during your <b>2 month free trial.</b></p>
                <br />
                <p>After your trial ends, it’s only $6 per month for each property you want to track - which for most people is by far their smallest property expense and the ONLY one that helps make sure your property is a good, profitable investment.</p>
            </div>

            <div className='trialFlex'>
                <div className='shortContainer trialContainer'>
                    <div className='headerMetric blue'>
                        <p>60-day free trial</p>
                    </div>
                    <div className='textMargins paragraphContainer blue'>
                        <p>Try WayBoz free without adding any payment info. When your trial ends, add a card and keep using WayBoz exactly as before. </p>
                    </div>
                    <RoundWhiteButton onClick={handleNoBillingClick}>Add Billing Later</RoundWhiteButton>
                </div>

                <div className='longContainer trialContainer'>
                    <div className='headerMetric white'>
                        <p>90-day free trial</p>
                    </div>
                    <div className='textMargins white paragraphContainer'>
                        <p>Add your billing info now for an <b>EXTRA FREE MONTH</b>. Then when your trial ends, you'll be able to keep using WayBoz without any interruption.</p>
                    </div>
                    <RoundWhiteButton onClick={handleBillingClick}>Add Billing Now</RoundWhiteButton>      
                </div>
            </div>
            
        </div>

    )

}
