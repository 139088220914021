import React from "react";
import './Shared.css';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatDate, textfieldColorChanges } from "./Misc";

export const PurchaseDetails = (props) => {
    let purchaseDetails = props.purchaseDetails;
    let handleChange=props.handleChangePurchase

    return (
            <div className='groupContainer'>
                    <div className='MultiOnRow'>
                                <TextField
                                    required
                                    id="standard-read-only-input"
                                    label="Purchase Price"
                                    name='purchase_price'
                                    type='number'
                                    value= {purchaseDetails.purchase_price}
                                    variant="outlined"
                                    className='project'
                                    onChange={handleChange}
                                    margin='normal'
                                    sx={textfieldColorChanges} 
                                    onWheel={() => document.activeElement.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}  
                                />
                                <TextField
                                    required
                                    id="standard-read-only-input"
                                    label="Cash to Close"
                                    name='cash_to_close'
                                    type='number'
                                    value= {purchaseDetails.cash_to_close}
                                    variant="outlined"
                                    className='project'
                                    onChange={handleChange}
                                    margin='normal'
                                    sx={textfieldColorChanges} 
                                    onWheel={() => document.activeElement.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}  
                                />
                                <TextField
                                    required
                                    id="standard-read-only-input"
                                    label="Purchase Date"
                                    name='purchase_date'
                                    type='date'
                                    value={formatDate({date:purchaseDetails.purchase_date, format:'ymd'})}
                                    variant="outlined"
                                    className='project'
                                    onChange={handleChange}
                                    margin='normal'
                                    sx={textfieldColorChanges} 
                                />
                        </div>
                </div>
    )
                                    
};