import './App.css';
import { Header } from '../CoreStructure/Header/Header';
import { Nav } from '../CoreStructure/Nav/Nav';
import { PropertiesMain } from '../PropertiesMain/PropertiesMain';
import { TaxesMain } from '../Taxes/TaxesMain';
import { Transactions } from '../Transactions/Transactions';
import { CalculatorContainer } from '../Calculator/CalculatorContainer';
import { useState, useEffect } from 'react';
import { Drawer } from '../CoreStructure/Drawer/Drawer';
import {NavDrawer} from './../CoreStructure/Nav/NavDrawer';
import { getProperties, getNewPlaidTransactions, startupItems, createShortTrial, updateTrial, addStreetViewImage, getPropertyStats2, getTaxData } from '../../api/properties';
import { ComingSoon } from '../ComingSoon/ComingSoon';
import { GET } from '../../Utilities/networkRequests';
import {PlaidReconnectModal} from '../Plaid/Link/Link'

import {isMobile} from 'react-device-detect';
import { NarrowBrowserError } from '../NarrowBrowserError/NarrowBrowserError';
import { TaxesLoad } from '../Taxes/TaxesLoad';



export const Main = (props) => {
  const [drawerOpen, setDrawerOpen] = useState({status:false, type:'', id:'', month:'', accountID:'', accounts:[], addAccount:false, plaidToken:'', institution:'', transInfo:{}, newProperty:{}, misc:{}, window:'', year:'', payload:{}});
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);

  const [properties, setProperties] = useState();
  const [propertiesNoPics, setPropertiesNoPics] = useState();
  const [property, setProperty] = useState();
  const [plaidAccount, setPlaidAccount] = useState(-1);

  const [page, setPage] = useState('properties');
  const [showPlaidErrAlert, setShowPlaidErrAlert] = useState(false);
  const [plaidReconnectLinkToken, setPlaidReconnectLinkToken] = useState('')

  
  
  const [selectedYearStats, setSelectedYearStats] = useState();
  const [selectedYearTaxes, setSelectedYearTaxes] = useState();
  const [selectedWindow, setSelectedWindow] = useState();
  const [includeCapEx, setIncludeCapEx] = useState (false);
  const [yearsArr, setYearsArr] = useState();
  const [windowsArr, setWindowsArr] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Spring (Mar - May)',
    'Summer (Jun - Aug)',
    'Fall (Sep - Nov)',
    'Winter (Dec - Feb)'
  ])

  let handleChangeCheckbox = () => {
    setIncludeCapEx(!includeCapEx);
}
  
let getInitialWindow = (value) => {
  switch(value) {
    case (1):
      setSelectedWindow('January');
        break;
    case (2):
      setSelectedWindow('February');
        break;
    case (3):
      setSelectedWindow('March');
        break;
    case (4):
      setSelectedWindow('April');
        break;
    case (5):
      setSelectedWindow('May');
        break;
    case (6):
      setSelectedWindow('June');
        break;
    case (7):
      setSelectedWindow('July');
        break;
    case (8):
      setSelectedWindow('August');
        break;
    case (9):
      setSelectedWindow('September');
        break;
    case (10):
      setSelectedWindow('October');
        break;
    case (11):
      setSelectedWindow('November');
        break;
    case (12):
      setSelectedWindow('December');
        break;
    case (20):
      setSelectedWindow('Spring (Mar - May)');
        break;
    case (30):
      setSelectedWindow('Summer (Jun - Aug)');
        break;
    case (40):
      setSelectedWindow('Fall (Sep - Nov)');
        break;
    case (50):
      setSelectedWindow('Winter (Dec - Feb)');
        break;
    default:
      setSelectedWindow(new Date().getMonth()+1);
        break;    
}
}

  let handleChangeYear = ({e, type}) => {
      if (type === 'taxes') {
        setSelectedYearTaxes(e.target.value);
      } else if (type === 'stats') {
        setSelectedYearStats(e.target.value);
      } else {
        setSelectedWindow(e.target.value);
      } 
    }





  const [accountStatus, setAccountStatus] = useState('');
  const [hadTrial, setHadTrial] = useState(false);
  //const [stripeUpdateBillingLink, setStripeUpdateBillingLink] = useState('');
  //const [stripeCreateBillingLink, setStripeCreateBillingLink] = useState('');


  // -------------------- REFRESHER STUFF -----------------------------------------------------------
  //this is used to refresh part of app when other parts change, the refreshsetter is passed to wherever a user can input / change something and is called on submit, when it is called, it sets refresher state to its opposite, refresher state is passed to various components and used in useEffect to rerender page when refreshsetter called
  const [propertiesRefresher, setPropertiesRefresher] = useState(false);
  const [institutionsRefresher, setInstitutionsRefresher] = useState(false);
  const [equityRefresher, setEquityRefresher] = useState(false);
  const [cashflowRefresher, setCashflowRefresher] = useState(false);
  const [transactionsRefresher, setTransactionsRefresher] = useState(false);
  const [taxRefresher, setTaxRefresher] = useState(false);


  const refreshSetter = ({type}) => {
      if (type ==='newTrans') {
        setCashflowRefresher(!cashflowRefresher);
        setTransactionsRefresher(!transactionsRefresher)
        setPropertiesRefresher(!propertiesRefresher);
      }

      if (type ==='tax') {
        setTaxRefresher(!taxRefresher)
        setCashflowRefresher(!cashflowRefresher);
        setPropertiesRefresher(!propertiesRefresher);
      }

      if (type === 'newProperty'){
        setPropertiesRefresher(!propertiesRefresher);
        setCashflowRefresher(!cashflowRefresher);
        setEquityRefresher(!equityRefresher);
        setTransactionsRefresher(!transactionsRefresher)
      }

      if (type === 'newProperty2') {
        setPropertiesRefresher(!propertiesRefresher);
      }

      if (type === 'newMortgage'){
        setEquityRefresher(!equityRefresher);
        setPropertiesRefresher(!propertiesRefresher);
      }

      if (type === 'newMortgage2'){
        setEquityRefresher(!equityRefresher);
      }

      if (type === 'deletePropValue'){
        setPropertiesRefresher(!propertiesRefresher);
        setEquityRefresher(!equityRefresher);
      }

      if (type === 'editProperty'){
        setPropertiesRefresher(!propertiesRefresher);
        setEquityRefresher(!equityRefresher);
        setCashflowRefresher(!cashflowRefresher);
      }

      if (type === 'deleteProperty'){
        // this is to make sure user cant hit trial again if status didnt refresh when adding first property 
        if (accountStatus === 'pretrial'){
          startupItems().then(res => setAccountStatus(res.trial_status));
        }
        setPropertiesRefresher(!propertiesRefresher);
        setCashflowRefresher(!cashflowRefresher);
        setEquityRefresher(!equityRefresher);
      }

      if (type === 'newAccount'){
        setTransactionsRefresher(!transactionsRefresher);
        setInstitutionsRefresher(!institutionsRefresher);
      }

      if (type === 'editAccount'){
        setCashflowRefresher(!cashflowRefresher);
        setInstitutionsRefresher(!institutionsRefresher);
        setPlaidAccount(-1)
        setTransactionsRefresher(!transactionsRefresher);
      }

      if (type === 'editTransactions'){
        setCashflowRefresher(!cashflowRefresher);
        setPropertiesRefresher(!propertiesRefresher);
      }

      if (type === 'deleteTransactions'){
        setTransactionsRefresher(!transactionsRefresher);
        setPropertiesRefresher(!propertiesRefresher);
      }
  }
  // -------------------- END REFRESHER STUFF -----------------------------------------------------------


  let updateProperty = ({property}) => {
    setProperty(property);
}
  let updatePlaidAccount = ({accountID}) => {
    setPlaidAccount(accountID);
}

const [propertyData, setPropertyData] = useState();
const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProperties().then((res) => {
      if (res.length > 0) {
          if (!property) {
            setProperty({id:-1});
          } else {
            let find = res.find(item => item.id === property.id);
            if (!find){
              setProperty({id:-1});
            }
          }

          res.forEach(async property => {
            if (!property.street_view){
              const data = await addStreetViewImage({street:property.street, city: property.city, state: property.state, propertyID:property.id});
              // if they have a bad address, this might call the google API more than i need to so might want to remove this in the future...
              //technically should update res here before setProperties... but won't really matter...
            }
          })


          // setting yearsArr
          if (!selectedYearStats || !selectedYearTaxes || !selectedWindow) {
          let newDate = new Date();
          let currentYear = newDate.getFullYear();
          let currentMonth = newDate.getMonth()+1;
          setSelectedYearStats(currentYear);
          setSelectedYearTaxes(currentYear);
          getInitialWindow(currentMonth);
          let currentMin = currentYear;
          res.map((item) => {
              let purchaseDate = new Date (item.purchase_date);
              let year = purchaseDate.getFullYear();
              if (year < currentMin) {
                  currentMin = year;
              }
          })
          let years = [];
          for (let i = currentYear; i > currentMin-1; i--){
              years.push(i);
          }
          setYearsArr(years);
        }

     // creates a newPropertiesArr but without the street view for request to BE
      const newArray = res.map(obj => {
      const newObj = { ...obj };
      delete newObj.street_view;
      return newObj;
      });
      setPropertiesNoPics(newArray);

      // ----------- NEED TO UPDATE THIS TO ACCEPT THE SELECTEDYEARSTATS -----------
      // --------- i dont want this in here.... i want only this to re run when year changes, not the rest...
      if (selectedYearStats && selectedWindow) {
        
        // dont forget to chanage year, includesRepairs, and window to state variables....
        setLoading(true);
        getPropertyStats2({properties: newArray, year:selectedYearStats, includeCapEx:includeCapEx, window:selectedWindow}).then((res) => {
          setPropertyData(res);
        setLoading(false);
        });
      }

      } else {
        setProperty({id:-1});
      }
      setProperties(res);
      

  })
}, [propertiesRefresher, selectedWindow, selectedYearStats, includeCapEx]);










useEffect( () => {
    
  toggleDrawer({drawerType:'whereStart'})
}, []);



  useEffect( () => {
    getNewPlaidTransactions().then((res)=> {
      
      if(Array.isArray(res.data)){
        setPlaidReconnectLinkToken(res.data[0].link_token)
        setShowPlaidErrAlert(true)
      } 
    })
  }, []);

  useEffect( () => {
    startupItems().then(async res => {

      if (res?.trial_status === 'payment-bad'){
        toggleDrawer({drawerType:'paymentError'})
      }
      
      setHadTrial(res.had_trial)
      setAccountStatus(res.trial_status)
   
    });   
    
  }, []);



  async function generatePlaidReconnectLink(itemID){
    if (itemID) {
      const response = await GET(`/plaid/create-link-token-reconnect?item_id=${itemID}`)

      if (response.data){
          setPlaidReconnectLinkToken(response.data.link_token)
      }
    }
}

  const toggleDrawer =  ({drawerType, id, month, accounts, accountID, addAccount, plaidToken, institution, transInfo, newProperty, misc, window, year, payload}) => {
    if (!drawerOpen.status) {
      setDrawerOpen({status:true, type:drawerType, id:id, month:month, accounts:accounts, accountID:accountID, addAccount:addAccount, plaidToken:plaidToken, institution:institution, transInfo:transInfo, misc:misc, window:window, year:year, payload:payload});
    } else if (drawerType === 'plaid' || drawerType === 'plaidexplainer' || drawerType ==='loadingPlaid' || drawerType === 'accountRouting' || drawerType === 'manageInstitution' || drawerType === 'splitTransaction' || drawerType === 'startTrial' || drawerType === 'propertyTransactions' || drawerType==='propertyMortgage' || drawerType==='firstProperty' || drawerType==='tooltip') {
      // this else if is for when the drawer is already open and we are opening a new drawer
      setDrawerOpen({status:true, type:drawerType, id:id, month:month, accounts:accounts, accountID:accountID, addAccount:addAccount, plaidToken:plaidToken, institution:institution, transInfo:transInfo, newProperty:newProperty, misc: misc, window:window, year:year, payload:payload});
    }else {
      setDrawerOpen(false);
    }
  }

  const toggleNavDrawer = () => {
    if (!navDrawerOpen) {
        setNavDrawerOpen(true);
    } else {
      setNavDrawerOpen(false);
    }
  }

  const pageSetter = ({response}) => {
    setPage(response);
  }

  

  const updatePropertyData = ({propertyID, property}) => {
    let index = properties.findIndex((e) => e.id === propertyID);
    let newProperties = [...properties];
    newProperties[index]=property;
    setProperties(newProperties);
  }

  //Screen size stuff....
  let deviceWidth=props.width;
  const breakpointLarge = 800;
  const breakpointMedium = 600;
  const breakpointSmall = 500
  const breakpointXSmall = 400


  let mobile = deviceWidth < breakpointLarge ? true : false;
  let mobileType = 'small';
  if (deviceWidth < breakpointLarge && deviceWidth >= breakpointMedium) {
    mobileType='large';
  } else if (deviceWidth < breakpointMedium && deviceWidth >= breakpointSmall) {
    mobileType='medium';
  } else if (deviceWidth < breakpointSmall && deviceWidth >= breakpointXSmall) {
    mobileType='small';
  } else {
    mobileType='xsmall';
  }
let newlayout = true;

  return (
      
    <>
    { deviceWidth < breakpointLarge ? 
        <div>
          <Drawer 
            type={drawerOpen.type} 
            status={drawerOpen.status} 
            toggleDrawer={toggleDrawer} 
            updatePropertyData={updatePropertyData} 
            propertyID={drawerOpen.id} 
            properties={properties} 
            month={drawerOpen.month}
            window={drawerOpen.window}
            year={drawerOpen.year}
            payload={drawerOpen.payload}
            accounts={drawerOpen.accounts} 
            accountID={drawerOpen.accountID} 
            addAccount={drawerOpen.addAccount}
            refreshSetter={refreshSetter}
            plaidToken={drawerOpen.plaidToken}
            institution={drawerOpen.institution}
            transInfo={drawerOpen.transInfo}
            newProperty={drawerOpen.newProperty}
            mobile={mobile}
            mobileType={mobileType}
            accountStatus={accountStatus}
            hadTrial={hadTrial}
            property={property}
            misc={drawerOpen.misc}
            pageSetter={pageSetter}
            page={page}
            />
          <div >
        
            <div className='mobileHeader'><Header properties={properties} cashflowRefresher={cashflowRefresher} equityRefresher={equityRefresher} mobile={mobile} page={page} mobileType={mobileType} /></div>
            <div className='mobileContainer' style={{textAlign:'center'}}>
              <div className='mobileMain scrollControlMobile'>
                {page === 'transactions' ? <Transactions toggleDrawer={toggleDrawer} 
                                                        properties={properties} 
                                                        property={property} 
                                                        plaidAccount={plaidAccount} 
                                                        updatePlaidAccount={updatePlaidAccount} 
                                                        transactionsRefresher={transactionsRefresher} 
                                                        institutionsRefresher={institutionsRefresher} 
                                                        refreshSetter={refreshSetter}
                                                        mobile={mobile}
                                                        accountStatus={accountStatus}
                                                        hadTrial={hadTrial}
                                                        /> 
              : page === 'calculator' ? <CalculatorContainer toggleDrawer={toggleDrawer} mobileType={mobileType} /> 
              : page === 'comingsoon' ? <ComingSoon pageSetter={pageSetter} type='desktop'/>
              : page === 'taxes' ? <TaxesLoad properties={properties} 
                                                  property={property} 
                                                  mobile={mobile}
                                                  deviceWidth={deviceWidth}
                                                  accountStatus={accountStatus}
                                                  hadTrial={hadTrial}
                                                  yearsArr={yearsArr} 
                                                  handleChangeYear={handleChangeYear} 
                                                  year={selectedYearTaxes}
                                                  propertiesNoPics={propertiesNoPics}
                                                  toggleDrawer={toggleDrawer}
                                                  taxRefresher={taxRefresher}
                                                  refreshSetter={refreshSetter}
                                                  />
              : <PropertiesMain toggleDrawer={toggleDrawer} 
                                properties={properties} 
                                property={property} 
                                updateProperty={updateProperty}
                                mobile={mobile}
                                mobileType={mobileType}
                                accountStatus={accountStatus}
                                hadTrial={hadTrial}
                                propertyData={propertyData}
                                windowsArr={windowsArr}
                                yearsArr={yearsArr} 
                                handleChangeYear={handleChangeYear}
                                year={selectedYearStats}
                                window={selectedWindow}
                                loading={loading}
                                handleChangeCheckbox={handleChangeCheckbox}
                                includeCapEx={includeCapEx}
                                />
              }
            
          
              </div>
              {/*<div className='mobileNav'><Nav toggleDrawer={toggleDrawer} pageSetter={pageSetter} page={page} properties={properties} type={'mobile'}/></div>*/}
           </div>
          {<div className='mobileNav mobileFooter'><Nav toggleDrawer={toggleDrawer} pageSetter={pageSetter} page={page} properties={properties} type={'mobile'}/></div>}
        </div>

        {showPlaidErrAlert ? 
        <div className='plaidErrAlert'>
          <p className='headerMetric alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal publicToken={plaidReconnectLinkToken}/></p>
        </div> 
        : <div className='plaidErrAlert newTransAlertHidden'><p className='headerMetric negative alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal/></p></div>
        }
      </div> 
      :  
      <div>
            <Drawer 
              type={drawerOpen.type} 
              status={drawerOpen.status} 
              toggleDrawer={toggleDrawer} 
              updatePropertyData={updatePropertyData} 
              propertyID={drawerOpen.id} 
              properties={properties} 
              month={drawerOpen.month} 
              window={drawerOpen.window}
              year={drawerOpen.year}
              payload={drawerOpen.payload}
              accounts={drawerOpen.accounts} 
              accountID={drawerOpen.accountID} 
              addAccount={drawerOpen.addAccount}
              refreshSetter={refreshSetter}
              plaidToken={drawerOpen.plaidToken}
              institution={drawerOpen.institution}
              transInfo={drawerOpen.transInfo}
              newProperty={drawerOpen.newProperty}
              mobile={mobile}
              accountStatus={accountStatus}
              hadTrial={hadTrial}
              property={property}
              misc={drawerOpen.misc}
              pageSetter={pageSetter}
              page={page}
              />

            <NavDrawer status={navDrawerOpen} toggleDrawer={toggleDrawer} toggleNavDrawer={toggleNavDrawer} 
                        pageSetter={pageSetter} page={page} properties={properties} />
            <div className="App">
                <div className='header'><Header properties={properties} cashflowRefresher={cashflowRefresher} equityRefresher={equityRefresher} mobile={mobile} page={page} mobileType={mobileType} /></div>
                
                <div className='body'>
                  {page === 'transactions' ? <Transactions toggleDrawer={toggleDrawer} 
                                                            properties={properties} 
                                                            property={property} 
                                                            plaidAccount={plaidAccount} 
                                                            updatePlaidAccount={updatePlaidAccount} 
                                                            transactionsRefresher={transactionsRefresher} 
                                                            institutionsRefresher={institutionsRefresher} 
                                                            refreshSetter={refreshSetter}
                                                            mobile={mobile}
                                                            deviceWidth={deviceWidth}
                                                            accountStatus={accountStatus}
                                                            hadTrial={hadTrial}
                                                            /> 
                  : page === 'calculator' ? <CalculatorContainer toggleDrawer={toggleDrawer} mobileType={mobileType} /> 
                  : page === 'comingsoon' ? <ComingSoon pageSetter={pageSetter} type='desktop'/> 
                  : page === 'taxes' ? <TaxesLoad properties={properties} 
                                                  property={property} 
                                                  mobile={mobile}
                                                  deviceWidth={deviceWidth}
                                                  accountStatus={accountStatus}
                                                  hadTrial={hadTrial}
                                                  yearsArr={yearsArr} 
                                                  handleChangeYear={handleChangeYear} 
                                                  year={selectedYearTaxes}
                                                  propertiesNoPics={propertiesNoPics}
                                                  toggleDrawer={toggleDrawer}
                                                  taxRefresher={taxRefresher}
                                                  refreshSetter={refreshSetter}
                                                  />
                  : <PropertiesMain toggleDrawer={toggleDrawer} 
                                    properties={properties} 
                                    property={property} 
                                    updateProperty={updateProperty}
                                    mobile={mobile}
                                    accountStatus={accountStatus}
                                    hadTrial={hadTrial}
                                    propertyData={propertyData}
                                    windowsArr={windowsArr}
                                    yearsArr={yearsArr} 
                                    handleChangeYear={handleChangeYear}
                                    year={selectedYearStats}
                                    window={selectedWindow}
                                    loading={loading}
                                    handleChangeCheckbox={handleChangeCheckbox}
                                    includeCapEx={includeCapEx}
                                    />
                  }
                </div>
            </div>
          {showPlaidErrAlert ? 
          <div className='plaidErrAlert'>
            <p className='headerMetric alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal publicToken={plaidReconnectLinkToken}/></p>
          </div> 
          : <div className='plaidErrAlert newTransAlertHidden'><p className='headerMetric negative alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal/></p></div>
          }
        </div>
    }
    </>
);
}

