import React, { useState, useEffect } from "react";
import './Add.css';
import secure from './Assets/secure.svg';
import { MortgageDetails } from "../Shared/MortgageDetails";
import { IncExp } from "./IncExp";
import { textfieldColorChanges } from "../Shared/Misc";
import { getPropertyDetails } from "../../../../api/properties";
import { GET } from "../../../../Utilities/networkRequests";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { BulkAdd } from "../BulkAdd/BulkAdd";
import { PlaidModal } from "../../../Plaid/Link/Link";

export const LinkAccount = (props) => {
    
    
    let type=props.type;
    let properties = props.properties;
    let property=props.property;
    

    const [plaidLinkToken, setPlaidLinkToken] = useState('')

    useEffect(() => {
        generatePlaidLink()
    }, []);


    const generatePlaidLink = async () => {

        try {
            const response = await GET(`/plaid/create-link-token`);

            if (response.data) {
                setPlaidLinkToken(response.data.link_token)
            }

        } catch (err) {
            console.log(err)
        }
    };

    let text1 = '';

    if (type ==='mortgage'){
        //text1='Let’s save you a huge headache by connecting your mortgage account.';
    } else {
        text1='Having accurate income and expense history is key to calculating all your metrics (including your tax deductions).';
    }

    let value = property.street + ' ' + property.city + ', ' + property.state;
    let method = props.method;
    let methods = ['Connect to Plaid (Best, fastest option to automate income/expense management)', 'Upload Spreadsheet (Great option if you’ve already tracked your incomes/expenses)', 'Manually Add Transactions (Great for capturing cash transactions)']

    return (
        <div>
            {type === 'account' ? '' : <TextField
                fullWidth
                id="outlined-select-state"
                select
                required
                name='property'
                label="For which property?"
                value={property.id}
                onChange={props.handleChange}
                className='boxWidth2'
                margin='normal'
                sx={textfieldColorChanges}
            >
                

               
                {properties.map((option) => (
                    <MenuItem key={option.id} value={option.id} >
                    {option.id === -2 ? `${option.street}` : `${option.street}, ${option.city}, ${option.state}`}
                    </MenuItem>
                ))}

            </TextField>}

            { !property.id ? '' : 
            
            (<div style={{textAlign:'center'}}>

                <p className='bodyTextBlue spacing'>{text1}</p>

                {type === 'mortgage' ? 
                
            <MortgageDetails purchaseDate={property.purchase_date} mortgage={props.mortgage} handleChangeMortgage={props.handleChangeMortgage} index={0}  /> 
                
                : 
                
                <div style={{textAlign:'left'}}>
                    <TextField
                        fullWidth
                        id="outlined-select-state"
                        select
                        required
                        name='method'
                        label="How would you like to add your new transaction?"
                        value={method}
                        onChange={props.handleChangeMethod}
                        className='boxWidth2'
                        margin='normal'
                        sx={textfieldColorChanges}
                    >
                        
                        {methods.map((option) => (
                            <MenuItem key={option} value={option} style={{whiteSpace: 'normal'}}>
                            {option}
                            </MenuItem>
                        ))}

                    </TextField>

                    {method === 'Connect to Plaid (Best, fastest option to automate income/expense management)' ?
                        <div className='spacing center'>
                            {plaidLinkToken ? <PlaidModal publicToken={plaidLinkToken} toggleDrawer={props.toggleDrawer} type='transaction' refreshSetter={props.refreshSetter} /> : ""}
                             <div className='secureDiv spacing'>
                                <img src={secure} />
                                <p className='manualEnter' onClick={() => props.toggleDrawer({drawerType:'plaidexplainer', plaidToken:plaidLinkToken})}>Secure Connect - Click to Learn More</p>
                            </div> 
                        </div>
                    
                    : method === 'Upload Spreadsheet (Great option if you’ve already tracked your incomes/expenses)' ? 
                        <BulkAdd properties={properties} toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} misc='not-onboarding' /> 
                    
                    : method === 'Manually Add Transactions (Great for capturing cash transactions)' ? 
                        <div >
                           <IncExp transaction={props.transaction} handleChangeTransaction={props.handleChangeTransaction}/>
                        </div> 
                    
                    : ''}
                </div> 
                
                }
                
            </div>)}
            
        </div>
       
    )
};