import React from "react";
import { FormatNumber } from "../CoreComponents/FormatNumber/FormatNumber";
import { ChartSaleCashFlow } from "./ChartSaleCashFlow";
import { ChartSaleROE } from "./ChartSaleROE";
import { ChartSaleCashTVG } from './ChartSaleCashTVG';



export const Results = (props) => {
    let {income, expense, cashflow, investment, breakeven, sale_month, sale_cashflow, sale_roe, sale_lifetime_tvg, sale_cash, chart_arr, mortgage_payments, coc} = props.results;
    
  
     



    return (
        <div>
            <p className='primaryLabel primaryTitle '>Your Results</p>



            <p className='secondaryLabelBlue secondaryTitle'>GENERAL INFO</p>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={income} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' />
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Monthly Rental Income</p>
                    <p className='bodyTextGray'>Beginning on month {props.income_start_month}.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={-expense} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' />
                </div>                
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Total Monthly Expenses</p>
                    <p className='bodyTextGray'>Including your Mortgage Payment of <FormatNumber value={mortgage_payments} type='dollar'  /> / month.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={cashflow} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' />
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Monthly Cash Flow</p>
                    <p className='bodyTextGray'>Cash you can earn each month after your major projects.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={-investment} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' />
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Total Initial Investment</p>
                    <p className='bodyTextGray'>The sum of your down payment and initial major projects.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={coc*100} type='percent' colorMe='yes' className='primaryMetricCalc rightAlign' />
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Cash on Cash Return</p>
                    <p className='bodyTextGray'>Your cash return compared to your total cash investment.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    {breakeven === 'Never' ? <p className='primaryMetricCalc rightAlign'>{breakeven}</p> : <FormatNumber value={breakeven} type='breakeven'  className='primaryMetricCalc rightAlign' />}
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Cash Flow Breakeven Point</p>
                    <p className='bodyTextGray'>How long before you recover your full cash investment.</p>
                </div>
            </div>



            <div className='chartMarg secondaryLabelBlue'>
                <p>MONTHLY CASHFLOW OVER TIME</p> 
                <p className='bodyTextGray pTweaks'>Don't underestimate the importance of cash flow - it helps you buy that next property and helps keep you profitable during a downturn </p> 
                <div className='chart bodyTextBlue'>
                    <ChartSaleCashFlow data={chart_arr} />
                </div>
            </div>




            <div className='chartMarg secondaryLabelBlue'>
                <p><span className='positive'>TOTAL VALUE GAINED</span> & <span className='cashFlow'>CASH AT SALE</span> OVER TIME</p>
                <p className='bodyTextGray pTweaks'>Pick any month - based on the data you entered (growth rate, interest payments, incomes, expenses), it shows you how much cash you'd get if you sold at that moment & how much value your investment made you overall</p> 
                <div className='chart bodyTextBlue'>
                    <ChartSaleCashTVG data={chart_arr} />
                </div>
            </div>






            <div className='chartMarg secondaryLabelBlue'>
                <p>ANNUAL RETURN ON EQUITY</p>
                <p className='bodyTextGray pTweaks'>On any month, makes sure your property equity is giving you a good return, otherwise, you should rethink your investment...</p> 
                <div className='chart bodyTextBlue'>
                    <ChartSaleROE data={chart_arr} />
                </div>
            </div>



            <p className='secondaryLabelBlue secondaryTitle'>IF YOU SOLD AT MONTH: {sale_month}</p>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={sale_cashflow} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' /> 
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Current Cash Flow</p>
                    <p className='bodyTextGray'>Monthly Cash Flow at the time of sale.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={sale_roe*100} type='percent' colorMe='yes' className='primaryMetricCalc rightAlign' /> 
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Current Return on Equity</p>
                    <p className='bodyTextGray'>Annual return you were making on your equity this month.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={sale_lifetime_tvg} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' /> 
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Lifetime Total Value Gained</p>
                    <p className='bodyTextGray'>How much you made on the investment since purchase.</p>
                </div>
            </div>

            <div className='resultsRow'>
                <div className='number'>
                    <FormatNumber value={sale_cash} type='dollarRounded' colorMe='yes' className='primaryMetricCalc rightAlign' /> 
                </div>
                <div className='pTweaks text'>
                    <p className='secondaryLabelBlue'>Cash to Recieve</p>
                    <p className='bodyTextGray'>How much cash you’d recieve if you sold this month.</p>
                </div>
            </div>












        </div>
    )

};