import React, {useState,useEffect} from 'react';
import './Transaction.css';
import './TransRowMobile.css';

import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

import { formatDate, altFormatNumber } from '../CoreStructure/Drawer/Shared/Misc';
import { getColor } from '../../Utilities/functions';
import ai from '../../SharedAssets/ai.svg';



export const TransRowMobile = (props) => {
   
const [trans, setTrans] = useState(props.item);


let displayDate = formatDate({date:trans.date, order:'ymd'})


// dealing with displaying negative signs elsewhere....
const [displayValue, setDisplayValue] = useState(trans.amount);



useEffect(() => {
    setDisplayValue(altFormatNumber({value:trans.amount, type:'dollar'}))
    }, []);

    // trashing or changing visibility of transaction
    const [visibility, setVisibility] = useState(trans.visibility);
    const [rowStyle, setRowStyle] = useState('transactionRowMobile rowPadding');

    useEffect(() => {
        let sty;
        if (visibility === false) {
            sty = 'transactionRowMobile rowPadding hidden'
        } else if (!trans.street) {
            sty = 'transactionRowMobile rowPadding unassignedRow'
        } else {
            sty = 'transactionRowMobile rowPadding'
        }
        setRowStyle(sty)
      }, [])
   
    let red = getColor('--negative');
    let green = getColor('--positive');
    let blue = getColor('--waybozBlue')
    let color = blue;
    let amountColor = trans.amount > 0  ? green : red;

 

   //put this in the category MUI component if i want to column that column
   let typeColor = trans.category === 'Income: Deposit' || trans.category === 'Income: Paycheck' || trans.category === 'Income: Rent' || trans.category === 'Income: Misc' ? green : red;

    return (
       <div onClick={() => props.toggleDrawer({drawerType:'editTransaction', transInfo:trans})} className='outer narrowHover'>
            <div className={rowStyle}>
                <div className="transMobileLeftCol">
                    <div className='vendor'>
                        <p className='secondaryLabelBlue vendor'>{trans.vendor}</p>
                        <p className='bodyTextGray'>{displayDate}</p>
                    </div>
                    {trans.street ? <p className='tertiaryLabel itemMargin vendor' style={{fontStyle:'italic'}}>{trans.street}</p> : <p className='tertiaryLabel itemMargin vendor' style={{fontStyle:'italic'}}>No Property Assigned</p>}
                </div>
                <div className="transMobileRightCol">
                    <p style={{color:amountColor}} className='headerMetric'>{displayValue}</p>
                    <div className='aiFlexMobile'>
                    {trans.ai ? 
                        <img className='aiImageMobile'  src={ai}/>
                        : <div className='aiImage'></div>}
                        <p style={{color:typeColor}} className='itemMargin category'>{trans.category}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}