import React from 'react';
import loading from './assets/loading.svg';
import './Loading.css';


export const Loading = () => {
    
    
    return (
        <div className="spinner-container">
            <p className='primaryLabel'>Crunching all your data!</p>
            <div className="spinner"></div>
        </div>
       
    )
};