import React, {useState, useEffect} from 'react';
import { Loading } from '../CoreComponents/Loading/Loading';
import income from './Assets/income.svg';
import expense from './Assets/expense.svg';
import open from './Assets/open.svg';
import close from './Assets/close.svg';
import trash from '../../SharedAssets/trash.svg'
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';

import { RoundGreenButtonThin } from '../CoreComponents/CustomButton/RoundGreenButtonThin';

import { addTransaction } from '../../api/properties';
import { deleteTransaction } from '../../api/properties';

import { textfieldColorChanges } from '../CoreStructure/Drawer/Shared/Misc'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import './TaxesBody.css';



export const TaxesBody = (props) => {
    let properties = props.propertiesNoPics;
    let property = props.property;

    let year = props.year;
    let yearsArr=props.yearsArr
    let handleChangeYear=props.handleChangeYear
    let menuSize = props.mobile ? 'small' : 'normal'
    let handleChangeYearLocal = (e) => {
        handleChangeYear({e, type:'taxes'})
    }

    let taxRefresher=props.taxRefresher;
    let data = props.data;
    


    const [business, setBusiness] = useState(false);
    const [insurances, setInsurances] = useState(false);
    const [interest, setInterest] = useState(false);
    const [legal, setLegal] = useState(false);
    const [maintenance, setMaintenance] = useState(false);
    const [management, setManagement] = useState(false);
    const [miscellaneous, setMiscellaneous] = useState(false);
    const [purchases, setPurchases] = useState(false);
    const [basicRepairs, setBasicRepairs] = useState(false);
    const [majorRepairs, setMajorRepairs] = useState(false);
    const [propertyTaxes, setPropertyTaxes] = useState(false);
    const [utilities, setUtilities] = useState(false);
    
    
    const [hover, setHover] = useState(false);
    const [hoverTrash, setHoverTrash] = useState(false);
    const [globalTrashKey, setGlobalTrashKey] = useState(false);
    const [globalKey, setGlobalKey] = useState();


    let handleHover = ({i}) => {
        setHover(true);
        setGlobalKey(i);
    }

    let handleLeave = ({index}) => {
        setHover(false);
        setGlobalKey(null);
    }

    let handleHoverTrash = ({i}) => {
        setHoverTrash(true);
        setGlobalTrashKey(i);
    }

    let handleLeaveTrash = () => {
        setHoverTrash(false);
        setGlobalTrashKey(null);
    }


    let handleClick = async ({name, amount, propertyID}) => {
        let vendor = 'User added interest for mortgage: ' + name;
        let date = year + '-12-01'
        await addTransaction({propertyID:propertyID, category:'Payments: Mortgage Interest (via Mortgage)', date:date, vendor:vendor, visibility:true, amount:amount})
        props.refreshSetter({type:'tax'});
    }

    let handleDelete = async ({transID}) => {
        if (window.confirm('Are you sure you want to delete this transaction?')) {
            await deleteTransaction({transID});
            props.refreshSetter({type:'tax'});
        }
    }

  
        let index = data.findIndex((e) => e.propertyID === property.id);

        // ----- !!!!!! if expenses are changed to positive numbers this will need to change !!!!! ------
        let taxableIncome = data[index].income.taxable + data[index].expense.nondepreciable;


        let changeState = ({type}) => {
            switch (type) {
                case 'business':
                  setBusiness(!business);
                  break;
                case 'insurances':
                    setInsurances(!insurances);
                    break;
                case 'interest':
                    setInterest(!interest);
                    break;
                case 'legal':
                    setLegal(!legal);
                    break;
                case 'maintenance':
                    setMaintenance(!maintenance);
                    break;
                case 'management':
                    setManagement(!management);
                    break;
                case 'miscellaneous':
                    setMiscellaneous(!miscellaneous);
                    break;
                case 'purchases':
                    setPurchases(!purchases);
                    break;
                case 'basicRepairs':
                    setBasicRepairs(!basicRepairs);
                    break;
                case 'majorRepairs':
                    setMajorRepairs(!majorRepairs);
                    break;
                case 'propertyTaxes':
                    setPropertyTaxes(!propertyTaxes);
                    break;
                case 'utilities':
                    setUtilities(!utilities);
                    break;
                default:
                  console.log(`Oops. Something went wrong.`);
              }
        }

        let showInterestActuals = data[index].expense.interest.interestActuals < 1 
            ?   <div className='totalsRow grow subRow '>
                    <p className='bodyTextGray'>No payments added</p>
                </div> 
            : data[index].expense.interest.interestActuals.map((item, i) => {
                return (
                    <div key={i} className={hoverTrash && globalTrashKey === i ? 'interestActualsRowHover' : 'interestActualsRow'}>
                        <div className='totalsRow rowLeftPadding interestGrow'>
                            <p className='bodyTextGray rowTextLabel'>{item.vendor}</p>
                            <p className='bodyTextGray rowValueLabel'><FormatNumber value={item.amount} type='dollar' /></p>
                        </div>
                        <div className='trashDiv'>
                            <img src={trash} className='hover' onMouseEnter={() => handleHoverTrash({i})} onMouseLeave={() => handleLeaveTrash()} onClick={() => handleDelete({transID:item.id})}/>
                        </div>
                    </div>
                 )
                })

        let showInterestEstimates = data[index].expense.interest.interestEstimates < 1 
            ?   <div className='totalsRow grow subRow '>
                    <p className='bodyTextGray'>No mortgages added</p>
                </div> 
            : data[index].expense.interest.interestEstimates.map((item, i) => {
                
                    if(props.mobile){
                        return ( 
                            <div key={i} className='totalsRow grow subRow hover' onClick={() => handleClick({name:item.vendor, amount:item.amount, propertyID:item.propertyID})} >
                                <p className='bodyTextGray'>{item.vendor}</p>
                                <p className='bodyTextGray'><FormatNumber value={item.amount} type='dollar' /></p>
                             </div>)
                    }  else {
                    
                    return ( 
                        <div key={i} className='totalsRow grow subRow hover' onClick={() => handleClick({name:item.vendor, amount:item.amount, propertyID:item.propertyID})} onMouseEnter={() => handleHover({i})} onMouseLeave={() => handleLeave({i})}>
                            <p className='bodyTextGray'>{item.vendor}</p>
                            {hover && globalKey === i ? <p className='bodyTextGray'><span className='bodyTextGreenBold'>Click to Quick Add</span>  <FormatNumber value={item.amount} type='dollar' /></p> : <p className='bodyTextGray'><FormatNumber value={item.amount} type='dollar' /></p> }
                            {/*<p className='bodyTextGray'><FormatNumber value={item.amount} type='dollar' /></p>*/}
                         </div>)
                    }
                

            
        })
    


        return (

            <div>
                <div className='totalTaxed'>
                    <div className='totalTaxesText'>
                        <div className='totalTaxLine'>
                            <p className='primaryLabel'>Approximate Income to be Taxed:</p> 
                            <p className=' primaryLabel prewrap'><FormatNumber value={taxableIncome} type='dollar'  colorMe='yes' /> *</p>
                        </div>
                        <p className='tertiaryLabelLight'><em>* Does not include depreciation savings. Always consult a licensed accountant for exact tax responsibilities.</em></p>
                    </div>
                    {props.mobile ? '' : <div className='yearSelectorTaxes'>
                
                    <TextField
                        id="outlined-select-state"
                        select
                        required
                        name='year'
                        label="Year"
                        value={year}
                        onChange={handleChangeYearLocal}
                        className='selectClass'
                        sx={textfieldColorChanges}
                        margin='normal'
                        size={menuSize}
                    >
                        {yearsArr.map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                
                    </div> }
                </div>


                <div className='incExpCols'>
{/* ------- START INCOME COLUMN --------- */}
                    <div className='incExpCol'>
                        <div className='colLabel'>
                            <img src={income} />
                            <p className='primaryLabel'>Income</p>
                        </div>

                        <div className='colTotals'>
                            <div className='totalsRow addingMargin'>
                                <p className='secondaryLabelBlue'>Total</p>
                                <p className='secondaryLabelBlue'><FormatNumber value={data[index].income.totalInc} type='dollar' /></p>
                            </div>
                            <div className='totalsRow addingMargin'>
                                <p className='bodyTextGray'>Taxable Income (before expenses)</p>
                                <p className='bodyTextGray'><FormatNumber value={data[index].income.taxable} type='dollar' /></p>
                            </div>
                            <div className='totalsRow addingMargin'>
                                <p className='bodyTextGray'>Non Taxable Income*</p>
                                <p className='bodyTextGray'><FormatNumber value={data[index].income.nontaxable} type='dollar' /></p>
                            </div>
                            <div className='disclaimer'>
                                <p className='tertiaryLabelLight'><em>*IRS does not require reporting deposits if intention is to return them</em></p>
                            </div>
                        </div>

                        <div className='colRowItem'>
                            <div className='rowItemContainer'>
                                <div className='totalsRow rowItemContainerMargin'>
                                    <p className='bodyTextBlueBold'>Deposits</p>
                                    <p className='bodyTextBlueBold'><FormatNumber value={data[index].income.deposits} type='dollar' /></p>
                                </div>
                            </div>
                            <div className='rowItemContainer'>
                                <div className='totalsRow rowItemContainerMargin'>
                                    <p className='bodyTextBlueBold'>Misc</p>
                                    <p className='bodyTextBlueBold'><FormatNumber value={data[index].income.miscInc} type='dollar' /></p>
                                </div>
                            </div>
                            <div className='rowItemContainer'>
                            <div className='totalsRow rowItemContainerMargin'>
                                    <p className='bodyTextBlueBold'>Paycheck</p>
                                    <p className='bodyTextBlueBold'><FormatNumber value={data[index].income.paycheck} type='dollar' /></p>
                                </div>
                            </div>
                            <div className='rowItemContainer'>
                                <div className='totalsRow rowItemContainerMargin'>
                                    <p className='bodyTextBlueBold'>Rent</p>
                                    <p className='bodyTextBlueBold'><FormatNumber value={data[index].income.rent} type='dollar' /></p>
                                </div>
                            </div>
                        </div>

                    </div>

{/* ------- START EXPENSE COLUMN --------- */}
                    
                    <div className='incExpCol'>
                        <div className='colLabel'>
                            <img src={expense} />
                            <p className='primaryLabel'>Expense</p>
                        </div>

                        <div className='colTotals'>
                            <div className='totalsRow addingMargin'>
                                <p className='secondaryLabelBlue'>Total</p>
                                <p className='secondaryLabelBlue'><FormatNumber value={data[index].expense.totalExp} type='dollar' /></p>
                            </div>
                            <div className='totalsRow addingMargin'>
                                <p className='bodyTextGray'>Immediate Deductions</p>
                                <p className='bodyTextGray'><FormatNumber value={data[index].expense.nondepreciable} type='dollar' /></p>
                            </div>
                            <div className='totalsRow addingMargin'>
                                <p className='bodyTextGray'>Expenses to Depreciate*</p>
                                <p className='bodyTextGray'><FormatNumber value={data[index].expense.depreciable} type='dollar' /></p>
                            </div>
                            <div className='disclaimer'>
                                <p className='tertiaryLabelLight'><em>*Major improvements must be depreciated over time</em></p>
                            </div>
                        </div>

                        <div className='colRowItem'>

{/* ---------------------------- Business Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin' >
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'business'})}>
                                    <div className='totalsRow grow '   >
                                        <p className='bodyTextBlueBold'>Business Items</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.business.total} type='dollar' /></p>
                                    </div>
                                    {business ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {business ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Federal/State Taxes</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.business.businessTaxes} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Misc Expense</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.business.miscBusiness} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Phone</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.business.phone} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Software </p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.business.software} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Travel & Fuel</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.business.travel} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Insurance Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin '>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'insurances'})}>
                                    <div className='totalsRow grow ' >
                                        <p className='bodyTextBlueBold'>Insurances</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.insurance.total} type='dollar' /></p>
                                    </div>
                                    {insurances ? <img src={close}  /> : <img src={open}  />} 
                                </div>
                                {insurances ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Homeowners</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.insurance.homeowners} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>PMI</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.insurance.pmi} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow paddingAndBorder'>
                                            <p className='bodyTextGray'>Umbrella </p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.insurance.umbrella} type='dollar' /></p>
                                        </div>
                                        <div className='addNewRow grow'>
                                            <p className='bodyTextGray'>Pay Homeowners with your mortgage? </p>
                                            <RoundGreenButtonThin onClick={() => props.toggleDrawer({drawerType:'escrowInsurance'})} >Add Now</RoundGreenButtonThin>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Interest Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'interest'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Interest</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.interest.total} type='dollar' /></p>
                                    </div>
                                    {interest ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {interest ? 
                                <div>
                                    <div className='totalsRow'>
                                        <div className='rowLeftPadding'>
                                            <p className='bodyTextGrayBold'>Payments You've Added</p>
                                        </div>
                                        <img src={close} className='hideImage'/>
                                    </div>
                                    <div className='paddingAndBorder'>
                                        {showInterestActuals}
                                    </div>       
                                    <div className='totalsRow grow'>
                                        <div className='grow'>
                                            <div className='grow subRow tadMorePadding'>
                                                <p className='bodyTextGrayBold'>Estimated Interest Based on Mortgage <span className='bodyTextGray'>(Click to Add)</span></p>
                                                {showInterestEstimates}
                                            </div>
                                            <div className='addNewRow grow '>
                                                <p className='bodyTextGray'>Add your own value?</p>
                                                <RoundGreenButtonThin onClick={() => props.toggleDrawer({drawerType:'addInterest'})} >Add Now</RoundGreenButtonThin>
                                            </div>
                                        </div>
                                        <img src={close} className='hideImage'/>
                                    </div>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Legal & Tax Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'legal'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Legal & Tax Services</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.legalFees.total} type='dollar' /></p>
                                    </div>
                                    {legal ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {legal ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Legal</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.legalFees.legal} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Tax</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.legalFees.tax} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Maintenance Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'maintenance'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Maintenance Services</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.maintenance.total} type='dollar' /></p>
                                    </div>
                                    {maintenance ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {maintenance ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Cleaning</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.maintenance.cleaning} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Pest Control</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.maintenance.pestControl} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Misc</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.maintenance.servicesMisc} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Yard Care</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.maintenance.yard} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Management Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'management'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Management Services</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.management.total} type='dollar' /></p>
                                    </div>
                                    {management ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {management ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>HOA</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.management.hoa} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Property Management</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.management.propertyManagement} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Security</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.management.security} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Miscellaneous Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'miscellaneous'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Miscellaneous</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.miscExp} type='dollar' /></p>
                                    </div>
                                    {miscellaneous ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {miscellaneous ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Miscellaneous Expenses</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.miscExp} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Purchase Expenses ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'purchases'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Purchases</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.purchases.total} type='dollar' /></p>
                                    </div>
                                    {purchases ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {purchases ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Appliances</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.purchases.appliances} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Furnishings</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.purchases.furnishings} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Misc Supplies</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.purchases.miscSupplies} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Basic Repairs ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'basicRepairs'})} >
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Repairs & Improvements: Basic</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.basicRepairs.total} type='dollar' /></p>
                                    </div>
                                    {basicRepairs ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {basicRepairs ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Appliances & Furnishings</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.basicRepairs.appliancesAndFurnishings} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Home</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.basicRepairs.basic} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Major Repairs ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'majorRepairs'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Repairs & Improvements: Major</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.majorRepairs} type='dollar' /></p>
                                    </div>
                                    {majorRepairs ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {majorRepairs ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Major Improvements (per IRS)</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.majorRepairs} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Taxes ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'propertyTaxes'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Taxes</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.propertyTaxes} type='dollar' /></p>
                                    </div>
                                    {propertyTaxes ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {propertyTaxes ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow paddingAndBorder'>
                                            <p className='bodyTextGray'>Property Taxes</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.propertyTaxes} type='dollar' /></p>
                                        </div>
                                        <div className='addNewRow grow'>
                                            <p className='bodyTextGray'>Pay Property Taxes with your mortgage? </p>
                                            <RoundGreenButtonThin onClick={() => props.toggleDrawer({drawerType:'escrowTaxes'})} >Add Now</RoundGreenButtonThin>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>

{/* ---------------------------- Utilities ------------------------------*/}
                            <div className='rowItemContainer rowItemContainerMargin'>
                                <div className='totalsRow grow pointer' onClick={()=> changeState({type:'utilities'})}>
                                    <div className='totalsRow grow'>
                                        <p className='bodyTextBlueBold'>Utilities</p>
                                        <p className='bodyTextBlueBold'><FormatNumber value={data[index].expense.utilities.total} type='dollar' /></p>
                                    </div>
                                    {utilities ? <img src={close} /> : <img src={open} />} 
                                </div>
                                {utilities ? 
                                <div className='totalsRow grow'>
                                    <div className='grow'>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Electric</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.electric} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Gas</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.gas} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Internet</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.internet} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Sewer & Misc</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.sewer} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>TV</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.tv} type='dollar' /></p>
                                        </div>
                                        <div className='totalsRow grow subRow'>
                                            <p className='bodyTextGray'>Water</p>
                                            <p className='bodyTextGray'><FormatNumber value={data[index].expense.utilities.water} type='dollar' /></p>
                                        </div>
                                    </div>
                                    <img src={close} className='hideImage'/>
                                </div>
                                : ''}
                            </div>


                        </div>

                    </div>
                    
                </div>



          
            </div>
        )

    
  
};