import React from 'react';
import './Header.css';
import { FormatNumber } from '../../CoreComponents/FormatNumber/FormatNumber';
import { LoadingSmall } from '../../CoreComponents/Loading/LoadingSmall';
import { useState, useEffect } from 'react';

import {
    getCurrentEquity,
    getCurrentMonthlyCashFlow
} from '../../../api/properties';

export const Header = (props) => {
    const [currentEquity, setCurrentEquity] = useState();
    const [currentMonthlyCashFlow, setCurrentMonthlyCashFlow] = useState();
   

    let menuSize = props.mobile ? 'small' : 'normal'

   //I dont think this will update until we add something to make it do so
    useEffect(() => {
        getCurrentEquity({propertyID:-1}).then(setCurrentEquity);
      }, [props.equityRefresher]);

      useEffect(() => {
        getCurrentMonthlyCashFlow({propertyID:-1}).then(setCurrentMonthlyCashFlow);
      }, [props.cashflowRefresher]);
    
    let month = new Date().getMonth() + 1;
    let monthSmall;
    let monthLarge;
    switch (month) {
        case 1:
            monthSmall = 'Jan';
            monthLarge = 'January'
            break;
        case 2:
            monthSmall = 'Feb';
            monthLarge = 'February'
            break;
        case 3:
            monthSmall = 'Mar';
          monthLarge = 'March'
            break;
        case 4:
            monthSmall = 'Apr';
          monthLarge = 'April'
            break;
        case 5:
            monthSmall = 'May';
          monthLarge = 'May'
            break;
        case 6:
            monthSmall = 'Jun';
          monthLarge = 'June'
            break;
        case 7:
            monthSmall = 'Jul';
            monthLarge = 'July'
            break;
        case 8:
            monthSmall = 'Aug';
            monthLarge = 'August'
            break;
        case 9:
            monthSmall = 'Sep';
            monthLarge = 'September'
            break;
        case 10:
            monthSmall = 'Oct';
            monthLarge = 'October'
            break;
        case 11:
            monthSmall = 'Nov';
            monthLarge = 'November'
            break;
        case 12:
            monthSmall = 'Dec';
            monthLarge = 'December'
            break;
        default:
          console.log(`Oops. Something went wrong.`);
      }

  


//if properties null, loading
//if properties <1, inactive button
// add a confirmed no mortgage column to properties?
// if not confirmed, get button
//if confirmed, equity
//follow pattern from PropertiesMain

if (!props.properties) {
    return <LoadingSmall />;
} else {

    return (
        <div className='parent'>
            
            <div className='someFlex'>
                <div className='child'>
                    <p className='headerLabel'>Total Equity</p>
                    <p className='headerMetric'><FormatNumber value={currentEquity} type='dollarRounded' colorMe='yes' /></p>

                </div>
                <div className='child'>
                    {props.mobile && (props.mobileType === "small" || props.mobileType === 'xsmall') ? <p className='headerLabel'>Cash Flow ({monthSmall})</p> : <p className='headerLabel'>{monthLarge} Cash Flow</p>}
                    <p className='headerMetric'><FormatNumber value={currentMonthlyCashFlow} type='dollarRounded' colorMe='yes' /></p>
                </div>
            </div>
            
        </div>
    )
}
    
};