import React, {useState, useEffect} from 'react';
import { Loading } from '../CoreComponents/Loading/Loading';
import { PropertiesEmpty } from '../PropertiesMain/PropertiesEmpty/PropertiesEmpty';

import { PaymentError } from '../CoreStructure/Drawer/PaymentError/PaymentError';
import { TaxesMain } from './TaxesMain';

export const TaxesLoad = (props) => {
    let properties=props.properties;

    if (!properties  || !props.accountStatus) {
        return <Loading />;
    }  else if (properties.length < 1) {
            return <PropertiesEmpty toggleDrawer={props.toggleDrawer} type='properties'/>
    } else if (props.accountStatus === 'payment-bad' || (props.accountStatus === 'pretrial' && props.hadTrial === true)) {
        return <PaymentError body='body'/>
    } else {
        return <TaxesMain properties={props.properties} 
            property={props.property} 
            mobile={props.mobile}
            deviceWidth={props.deviceWidth}
            accountStatus={props.accountStatus}
            hadTrial={props.hadTrial}
            yearsArr={props.yearsArr}
            handleChangeYear={props.handleChangeYear} 
            year={props.year}
            propertiesNoPics={props.propertiesNoPics}
            toggleDrawer={props.toggleDrawer}
            taxRefresher={props.taxRefresher}
            refreshSetter={props.refreshSetter}
            />
    }
  
};