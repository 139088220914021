import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/YearOverYearImg.png'


export const YearCashFlowChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin'>While the previous chart helped you see how you are performing month over month in a specific year, this chart allows you to see how you are perfoming year over year!</p>
            
            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin'>As with other charts, the green and red bars represent your total income and expenses for each month, while the 
             solid blue line represents your cash flow (the difference between your income and expenses). The horizontal dashed lines (again: green, red, and blue) represent your <em>average yearly</em> income, expenses, and cash flow for all time.*</p>
            <p className='paragraphMargin'>Pay special attention to how this solid blue cash flow line is trending over time.
            Are there certain years when you had a large dip in cash flow? Why? What happened?</p>

             
            <p className='paragraphMargin'>As you hover on the chart, you will see a window or "tool tip" appear providing you with more information.
            Not only will you see the total income, expenses, and cash flow for the specific month you are hovering, but you will also see your all time annual averages.
            How are you trending? Above average? Below average? </p>

            <hr className = 'horLineSolid'/>
             <p className='paragraphMargin bodyTextGray'><em>* In case you're wondering, calculations for your averages are based on the date of your oldest transaction. In other words,
                if you purchased your home in 2008 but have only added data to WayBoz as far back as 2020, your averages will NOT be impacted by a series of $0 income and expenses from 2008 until 2020. </em></p>
            <p className='paragraphMargin bodyTextGray'><em>Also, remember, averages are impacted by whether or not you have elected to "Include Capital Expenditures in Averages."  See checkbox at the top of your screen. </em></p>
           
          
        </div>
    
    )

}