import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/monthByMonthImg.png'


export const YearCashFlowMonthChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin'>The below chart is vital to see how your annual performance is impacted by specific months of the year.</p>
            
            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin'>As with other charts, the green and red bars represent your total income and expenses for each month, while the 
             solid blue line represents your cash flow (the difference between your income and expenses). The horizontal dashed lines (again: green, red, and blue) represent your <em>average monthly</em> income, expenses, and cash flow for all time.*</p>
            <p className='paragraphMargin'>Pay special attention to how this solid blue cash flow line is trending throughout the year.
            Which month(s) are high cash flow months compared to others? Are you impacted by seasonality? Do certain months have higher expenses than others? Why?</p>

             
            <p className='paragraphMargin'>As you hover on the chart, you will see a window or "tool tip" appear providing you with more information.
            Not only will you see the total income, expenses, and cash flow for the specific month you are hovering, but you will also see two averages. 
            The first average is the all time average for the specific month you are hovering (e.g., January),
            while the second average is the all time average for all months. Comparing these two metrics allows you to see if
            one month typically performs above or below the average month. </p>

            <p className='paragraphMargin'>As you review the chart you might be wondering what transactions make up the total income or expenses for the month. Just click on a bar for that month and you'll see!</p>


            <hr className = 'horLineSolid'/>
             <p className='paragraphMargin bodyTextGray'><em>* In case you're wondering, calculations for your averages are based on the date of your oldest transaction. In other words,
                if you purchased your home in 2008 but have only added data to WayBoz as far back as 2020, your averages will NOT be impacted by a series of $0 income and expenses from 2008 until 2020. </em></p>
            <p className='paragraphMargin bodyTextGray'><em>Also, remember, averages are impacted by whether or not you have elected to "Include Capital Expenditures in Averages."  See checkbox at the top of your screen. </em></p>
           
          
        </div>
    
    )

}