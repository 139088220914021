import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import { 
    getColor
    } from '../../Utilities/functions';
import { yAxisFormatter, tooltipMonthLabel } from '../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from "../CoreComponents/FormatNumber/FormatNumber";




export const ChartSaleCashTVG = (props) => {
    let data=props.data;
    let cashLine = getColor('--cashFlow');
    let tvgLine = getColor('--positive')
    let gridColor = getColor('--lineGray');

  

 
    //{tooltipMonthLabel(label)}
    function CustomTooltip({ payload, label, active }) {
       let getStyle = (item) => {
        if (item.name === 'Total Value Gained' ){
            return 'row positive'
        } else {
            return 'row cashFlow'
        }
    } 
       
       
       if (active) {
          return (
            <div className="custom-tooltip" style={{width:'auto'}}>
                <div className='label'>
                    <p className='secondaryLabelBlue'>Earnings at Month: {payload[0].payload.month}</p>
                    
                </div>
    
              <div className='payload '>
               
                    {payload.map((item, i) => {
                        return (
                            <div key={i} className='flexDisplay'>
                                <p className={getStyle(item)} >{`${item.name}:`}</p>
                                <p className=' row' style={{marginLeft:15}}><FormatNumber value={item.value} type='dollarRounded'   /></p>
                            </div>
                        )
                    })}
    
              </div>
             
            </div>
          );
        }
        return null;
      }

      

    

    return (
        <div>
            <ResponsiveContainer width='100%' height={400}>
              <LineChart data={data} margin={{ top: 5, right: 20, left: 20, bottom: 25 }}>
              
                  <CartesianGrid vertical={false} stroke={gridColor} />
                  
                  <Line name='Total Value Gained' type="monotone" dataKey="sale_tvg" stroke={tvgLine} dot={false} />
                  <Line name='Cash to Recieve at Sale' type="monotone" dataKey="sale_cash" stroke={cashLine} dot={false} />
                  <XAxis  dataKey='month' 
                          angle = '-45'
                          tickMargin='10'
                          interval='preserveStartEnd'
                          type='number' 
                          ticks={[1,12,24,36,48,60,72,84,96,108,120,132,144,156,168,180]}
                          label={{ value: "Month", position: 'bottom' }}
                          />
                  
                  <YAxis  type='number' 
                          tickSize='8'
                          tickCount='5'
                          padding={{ top: 0 }}
                          tickFormatter={yAxisFormatter}
                          />
                  <Tooltip content={<CustomTooltip />} />
                      

              </LineChart>
            </ResponsiveContainer>
      
        </div>
    )
};