import React from 'react';
import '../TotalVG/ChartTotalValueGained/ChartTotalValueGained.css';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { tooltipMonthLabel, yAxisFormatter, axisMonthLabel, axisMonthLabel2 } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { 
    getColor
    } from '../../../../../Utilities/functions';




export const AllTimeRunningTotalChart = (props) => {

let tvgColor = getColor('--positive');
let cashFlowColor = getColor('--cashFlow');
let appreciationColor = getColor('--appreciation');
let principleColor = getColor('--principle');
let lineColor = getColor('--lineGray');


   

function CustomTooltip({ payload, label, active }) {
    let getStyle = (item) => {
        if (item.name === 'Cash Flow' ){
            return 'row cashFlow'
        } else if (item.name === "Appreciation") {
            return 'row appreciation'
        } else if (item.name === 'Principal') {
            return 'row principle'
        } else {
            return 'row positive'
        }
    }

    let getStyle2 = (item) => {
        if (item.name === 'Overall') {
            return 'flexDisplay borderTop'
        } else {
            return 'flexDisplay'
        }
    }


    if (active) {
      return (
        <div className="custom-tooltip">
            <div className='label secondaryLabelBlue'>
                    <p className="label">{label}</p> 
                </div>

          <div className='payload'>
           
                {payload.map((item, i) => {
                    return (
                        <div key={i} className={getStyle2(item)}>
                            <p className={getStyle(item)} >{`${item.name}`}:</p>
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2
                            }).format((item.value.toFixed(2)))}`}</p>
                        </div>
                    )
                })}

          </div>
         
        </div>
      );
    }
    return null;
  }

  
  


let allYears = [...new Set(props.yearlyRunningTotalTVGForAllTime.map(item => item.year))];

// Map over each unique year and create a new object for it
let combinedArray = allYears.map(year => {

    // Find the object with the corresponding year in each array, or default to 0 if not found
    let obj1 = props.yearlyRunningTotalTVGForAllTime.find(item => item.year === year) || { year: year, total: 0 };
    let obj2 = props.yearlyRunningTotalCashFlowForAllTime.find(item => item.year === year) || { year: year, total: 0 };
    let obj3 = props.yearlyRunningTotalAppreciationForAllTime.find(item => item.year === year) || { year: year, total: 0 };
    let obj4 = props.yearlyRunningTotalPrincipleForAllTime.find(item => item.year === year) || { year: year, total: 0 };

    // Return a new object containing the merged information for the current year
    return {
        year: year,
        tvg: obj1.total,
        cashFlow: obj2.total,
        appreciation: obj3.total,
        principle: obj4.total
    };
});

  


let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;


    return (
        <div>
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
            <LineChart data={combinedArray} margin={{ top: 5, right: 20, left: 20, bottom: 10 }}>
        
                <CartesianGrid vertical={false} stroke={lineColor} />
                
                <Line name='Cash Flow' type="monotone" dataKey="cashFlow" stroke={cashFlowColor}  />
                <Line name='Appreciation' type="monotone" dataKey="appreciation" stroke={appreciationColor}  />
                <Line name='Principal' type="monotone" dataKey="principle" stroke={principleColor}  />
                <Line name='Overall' type="monotone" dataKey="tvg" stroke={tvgColor}  />
                <Line name='Trending' type="monotone" dataKey="trending" stroke="#03B09B" strokeDasharray="1 1 2" />
                <XAxis  dataKey='year' 
                        angle = '-45'
                        tickMargin='10'
                        padding={{ left: 2 }}
                        fontSize={mobile ? 14 : 16}
                        />
                
                <YAxis  type='number' 
                        tickSize='8'
                        tickCount='5'
                        padding={{ top: 0 }}
                        tickFormatter={yAxisFormatter}
                        fontSize={mobile ? 14 : 16} 
                        width={mobile ? 70 : 75}
                        />
                <Tooltip content={<CustomTooltip />} />
            </LineChart>
            </ResponsiveContainer>
        </div>
    )
};