import React from "react";
import './tooltip.css';

export const Summary = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p>While we encourage you to scroll down and dive into the details on each of your properties, we've included a quick summary of some key metrics here. </p>
            <br />
            <p>First, for each property you can <strong>look at your earnings from whatever metric is most important to you</strong>: </p>
            <br />
            <ol>
                <li><strong>Cash Flow: </strong> What's left over from your rental income after you subtract expenses</li>
                <li><strong>Appreciation: </strong> How much your property has grown in value</li>
                <li><strong>Principal Paid: </strong> How much of your mortgage principal has been paid down</li>
                <li><strong>Total Value Gained: </strong> The sum of all three of the above</li>
            </ol>
            <br />
            <p>Second, you can quickly <strong>look at your earnings and returns from four key time periods</strong>: </p>
            <br />
            <ol>
                <li><strong>Current Month:</strong> How much you have made so for this month up to today</li>
                <li><strong>Current Year:</strong> How much you have made so far this year up to today</li>
                <li><strong>Predicted End of Year (EoY):</strong> How much you are predicted to make by year end</li>
                <li><strong>All Time:</strong> How much you have made since you bought the property up to today</li>
            </ol>
            <br />
            <p>Finally, every earning also has a return percentage associated with it so you know whether the earning is good or bad. Here's how each is calculated:</p>
            <br />
            <ol>
                <li><strong>Current Month:</strong> This takes what you've made this month and applies it for an entire year. It then compares this against your equity since that equity is what you currently have invested and is what is generating a [hopefully] good return for you.</li>
                <li><strong>Current Year:</strong> This takes how much you've made so far this year but annualizes it for an entire year. It is then compared against how much equity you <em>started</em> this year with, since you could have choosen to sell and invest that money elsewhere.</li>
                <li><strong>Predicted EoY:</strong> This first calculates how much you're predicted to make by year end. However, this is different from the item just mentioned because it ignores major improvements since they are likely one time only. It also is compared against how much equity you started the year with.</li>
                <li><strong>All Time:</strong> This takes how much you've made from the time you bought your property until today and then compares it against how much you put down on the property. Feel free to divide it against how long you've owned the property for a psuedo (though not exact) annual return.</li>
            </ol>
            

        </div>
    
    )

}