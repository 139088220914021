import axios from 'axios'
import {API_ENDPOINT} from "../api/index.js"

export const GET = async (endpoint) => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem('jwtToken')

      if (token) {
        config.headers.Authorization =  token;
      }

      return config;
    });

    try {
        return await axios.get(`${API_ENDPOINT}${endpoint}`);
    } catch(err){
        console.log(err)
        return err
    }
};

export const PUT = async (endpoint, body) => {

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwtToken')

    if (token) {
      config.headers.Authorization =  token;
    }

    return config;
  });

  try {
      return await axios.put(`${API_ENDPOINT}${endpoint}`, body);
  } catch(err){
      console.log(err)
      return err
  }
};

export const POST = async (endpoint, body) => {

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwtToken')

    if (token) {
      config.headers.Authorization =  token;
    }

    return config;
  });

  try {
      return await axios.post(`${API_ENDPOINT}${endpoint}`, body);
  } catch(err){
      console.log(err)
      return err
  }
};

export const DELETE = async (endpoint) => {

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwtToken')

    if (token) {
      config.headers.Authorization =  token;
    }

    return config;
  });

  try {
      return await axios.delete(`${API_ENDPOINT}${endpoint}`);
  } catch(err){
      console.log(err)
      return err
  }
};