export const categories = [
    'Expense: Misc',
    'Income: Misc',

    'Income: Deposit',
    'Income: Paycheck',
    'Income: Rent',

    'Insurances: Homeowners',
    'Insurances: Homeowners (via Escrow)',
    'Insurances: PMI',
    'Insurances: Umbrella',


    'Payments: Downpayment & Closing Costs',
    'Payments: Mortgage',
    'Payments: Mortgage Interest (via Mortgage)',
    'Payments: Property Taxes',
    'Payments: Property Taxes (via Escrow)',

    'Purchases: Appliances',
    'Purchases: Furnishings',
    'Purchases: Misc Supplies',

    'Repairs: Appliance/Furnishing',
    'Repairs: Home - Basic',
    'Repairs: Home - Major Improvement (per IRS)',
    
    'Services: Accounting',
    'Services: Cleaning',
    'Services: HOA',
    'Services: Legal',
    'Services: Misc',
    'Services: Pest Control',
    'Services: Property Management',
    'Services: Security',
    'Services: Yard Care',

    'Utilities: Electric',
    'Utilities: Gas',
    'Utilities: Internet',
    'Utilities: Sewer & Misc',
    'Utilities: TV',
    'Utilities: Water',

    'Business: Federal/State Taxes',
    'Business: Misc Expense',
    'Business: Phone',
    'Business: Software',
    'Business: Travel & Fuel'
]
      