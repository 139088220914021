
import { getColor } from "../../../../Utilities/functions";

let waybozGray = getColor('--secondaryGreen');
let waybozGreen = getColor('--positive');
let midGreen = getColor('--midGreen');

export let textfieldColorChanges = {
    "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
                borderColor: waybozGreen
                            }
                        },
    "& .MuiFormLabel-root.Mui-focused": {
                color: waybozGreen
                        },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: midGreen
        }
        },
        "& .MuiOutlinedInput-root": { // Target the root element of the outlined input
            backgroundColor: 'white' // Set the background color to white
        }
                    }


export let yearSelectorColorChanges = {
    "& .MuiOutlinedInput-root": {
        "& > fieldset": {
            borderColor: waybozGray
                        }
                    },
    "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
                borderColor: waybozGray
                            }
                        },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: waybozGray
        }
        },
        
        "& .MuiInputBase-root": {
            color: waybozGray
                    },
        "& .MuiSvgIcon-root": {
            color: waybozGray
                    },

    "& .MuiFormLabel-root": {
        color: waybozGray
                },
    "& .MuiFormLabel-root.Mui-focused": {
                color: waybozGray
                        },
    }
  

export let passwordColorChanges = {
    "& .MuiInputLabel-root.Mui-focused": {color: waybozGreen},//styles the label
    "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": { borderColor: waybozGreen },
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: midGreen
        }
    },
    '& .MuiInput-underline:after': {
    borderBottomColor: waybozGreen,
    },
     
 }

export let checkboxColorChanges = { '&.Mui-checked': {color: waybozGreen}};


export const calculatePayoff = ({start_date, term}) => {
    try {
    let startDate = new Date(start_date);
    let time = parseInt(term);
    let month = startDate.getMonth() + 1;
    let year = startDate.getFullYear();
    if (month === 1) {
      year = year+time-1;
      month=12;
    } else {
      year = year + time;
      month = month-1;
    }
    // have to do -1 again b/c Date is 0-indexed
    return new Date (year, month-1, '02');
  
    } catch(err){
        console.log(err)
        return err
    }
  }


export let altFormatNumber = ({value, type}) => {
   
   
    if (type==='dollar') {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })

          value = formatter.format(value);
    }
    if (type==='numberWithDecimals'){
            value = parseFloat(value).toFixed(2);
    }
        return value;
}


export let formatDate = ({date, order}) => {
    let year;
    let month;
    let day;
    if (date instanceof Date) {
        year = date.getFullYear();
        month = date.getMonth()+1;
        day = date.getDate();
    } else {
        year = date.substr(0,4);
        month = date.substr(5,2);
        day = date.substr(8,2);
    }
    if (month.toString().length < 2 ){
        month = '0' + month;
    }
    if (day.toString().length < 2 ){
        day = '0' + day;
    }
  
    if (order ==='mdy') {
        let newDate = month + '-' + day + '-' + year;
        return newDate;
    } else {
        let newDate = year + '-' + month + '-' + day;
        return newDate;
    }
}

export let tooltipMonthLabel = (label) => {
    return <div className='label secondaryLabelBlue'>
                {label==='Jan' ? <p className="label">January</p> : 
                label==='Feb' ? <p className="label">February</p> :
                label==='Mar' ? <p className="label">March</p> :
                label==='Apr' ? <p className="label">April</p> :
                label==='May' ? <p className="label">May</p> :
                label==='Jun' ? <p className="label">June</p> :
                label==='Jul' ? <p className="label">July</p> :
                label==='Aug' ? <p className="label">August</p> :
                label==='Sep' ? <p className="label">September</p> :
                label==='Oct' ? <p className="label">October</p> :
                label==='Nov' ? <p className="label">November</p> :
                label==='Dec' ? <p className="label">December</p> : <p className="label">Oops</p>}
            </div>
}

export let axisMonthLabel = ({month}) => {
    let value = 'Jan';
    value = month === 1 ? 'Jan' :
            month === 2 ? 'Feb' :
            month === 3 ? 'Mar' :
            month === 4 ? 'Apr' :
            month === 5 ? 'May' :
            month === 6 ? 'Jun' :
            month === 7 ? 'Jul' :
            month === 8 ? 'Aug' :
            month === 9 ? 'Sep' :
            month === 10 ? 'Oct' :
            month === 11 ? 'Nov' :
            'Dec';

    return value;
}

export let axisMonthLabel2 = ({month}) => {
    let value = 'January';
    value = month === 'Jan' ? 'January' :
            month === 'Feb' ? 'February' :
            month === 'Mar' ? 'March' :
            month === 'Apr' ? 'April' :
            month === 'May' ? 'May' :
            month === 'Jun' ? 'June' :
            month === 'Jul' ? 'July' :
            month === 'Aug' ? 'August' :
            month === 'Sep' ? 'September' :
            month === 'Oct' ? 'October' :
            month === 'Nov' ? 'November' :
            'December';

    return value;
}

export let yAxisFormatter = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      })
    let value = formatter.format(number);
    return value;
}

export let yAxisFormatterPercent = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 2
      })
    let value = formatter.format(number);
    return value;
}