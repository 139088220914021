import React from 'react';
import { useState, useEffect } from 'react';
import Automate from './Assets/Automate.svg';
import Calculator from './Assets/Calculator.svg';
import Grow from './Assets/Grow.svg';
import './NextSteps.css';


export const NextSteps = (props) => {


return (
    <div className='tvg section '>
        <div>
            <p className='primaryLabel sectionTitle'>What now? Make sure you do the following:</p>
        </div>

        <div className='charts'>
            <div className='align'>
                <p className='secondaryLabelBlue'>USE OUR NEW PURCHASE CALCULATOR</p>
                <div className='imgP'>
                    <img src={Calculator}/>
                    <p className='bodyTextBlue pText'>The same calculator that the creators of WayBoz use when buying properties is now available to you to ensure your next purchase produces a great return.</p>
                </div>
            </div>

            <div className='align'>
                <p className='secondaryLabelBlue'>AUTOMATE PROPERTY FINANCES</p>
                <div className='imgP'>
                    <img src={Automate}/>
                    <p className='bodyTextBlue pText'>Make sure all your property metrics are accurate by automating the tracking of your incomes and expenses. It’s easy - just securely connect your accounts.</p>
                </div>
            </div>

            <div className='align'>
                <p className='secondaryLabelBlue'>WATCH YOUR EARNINGS GROW!</p>
                <div className='imgP'>
                    <img src={Grow}/>
                    <p className='bodyTextBlue pText'>Every day your total value gained will be changing! Check back regularly to see your progress and easily assign new transactions to the right property.</p>
                </div>
            </div>

        </div>
  
    </div>
)

}