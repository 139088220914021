import React from "react";
import './tooltip.css';

export const IncludeCapExTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className="secondaryLabelBlue">Summary:</p>
            <br></br>
            <p className='paragraphMargin'> In Real Estate, <strong>Capital Expenditures</strong> (CapEx) are typically your less common, big 
                ticket costs related to acquiring (e.g., your downpayment) or improving (e.g., a major renovation)
                 your property. </p>

            <p className='paragraphMargin'>On the other hand, <strong>Operational Expenditures</strong> (OpEx) are your regular, ongoing
            costs to maintain and run your property / business (e.g., utility bills, management fees, supplies, cleaning, etc).</p>
            
            <p className='paragraphMargin'>In WayBoz, certain metrics and trends shown are calculated based on histroical averages of your expenses. Since CapEx aren't 
            typical expenses (and are often quite large), we default to have them NOT included in your averages. This prevents data shown from becoming inflated and potentially misleading. 
            However, we give you the option to include CapEx in your averages if desired. </p>
            
            <p><em>Note, even if you include CapEx, downpayments will NEVER be included in your averages.</em></p>
            <hr className = 'horLineSolid'/>
            <p className="secondaryLabelBlue">A Deeper Dive...</p>
            <br></br>
            <p>Are you sure you want a long-winded explanation? Okay, coming soon.</p>
          
        </div>
    
    )

}