import React from "react";
import './tooltip.css';

export const Tvg = () => {

    return (   
            <div className='content bodyTextBlue' >
                <p>When you own a property there are three ways each month that you are gaining or losing value:</p>
                <br />
                <ol>
                    <li><strong>Cash Flow:</strong> This is the cash left over from your rental income after you’ve paid all your expenses. Ideally, that difference is positive, and that’s cash in your pocket.</li>
                    <br />
                    <li><strong>Appreciation:</strong> When your property value grows each month, all that new value belongs to you. That value is currently in the form of equity, but when you sell your property, that’s additional cash in your pocket.</li>
                    <br />
                    <li><strong>Principal Paid:</strong>Your mortgage payment isn't totally an ‘expense’. Everytime you pay your mortgage you are actually gaining value back because the principal portion of your monthly payment is paying your debt down. Like appreciation, this is in the form of equity until you sell your property.</li>
                </ol>
                <br />
                
                <p>Though having strong cash flow is ideal, by understanding all the ways you gain value each month you can more confidently make real estate investing decisions.</p>
                <br />
                <br />
                <br />
                <br />
                <p className='secondaryLabelBlue'>Example</p>
                <br />
                <p>Let's say you've found a $500,000 house you want to buy. However, you are concered the property value will likely drop a bit the next few years. You estimate it will drop around 5% per year, or $25,000 the first year which is $2,084 per month.</p>
                <br />
                <p>To buy the house, you plan on putting down 20% or $100,000. That leaves you with a $400,000 loan with roughly a $1,900 payment. After adding in your taxes, insurance, and misc expenses, your monthly costs are around $2,500 per month.</p>
                <br />
                <p>Finally, let's say you'll AirBnb your house and can pull in around $6,000 per month (obvioulsy this is higher than renting it).</p>
                <br />
                <p>We have all our data, now let's see if buying a house that will lose 5% in value every year is still worth it. We need to look at each way we gain/lose value.</p>
                <br />
                <ol>
                    <li><strong>Cash Flow:</strong> Your income is $6,000 and total expenses are $2,500 so your cash flow is $3,500 per month.</li>
                    <br />
                    <li><strong>Appreciation:</strong>  We covered this above already. We predict your appreciation will be -$2,084 per month. </li>
                    <br />
                    <li><strong>Principal Paid:</strong> For your $1,900 mortgage payment, around $580 is principal being paid off, which means more equity for you. Note, the principal portion actually goes UP each month - which is great for you.  </li>
                </ol>
                <br />
                <p>So when you add $3,500, -$2,084, and $580, you get $1,996 per month or $23,952 per year. When you compare that against your investment of $100,000 (your down payment), that is a 23.95% return which is awesome! So even though you are losing property value each month, understanding your overall value gained lets you know that it likely is worth it.</p>
                <br />
                <p>However, obviously if you can find an investment which gives you a return better than 24%, you might consider putting $100,000 there instead (after weighing any risks).</p>
                <br />
                <p>For more info on annual returns and comparing investments, scroll further down the page you're on.</p>
            </div>
    )

}
