import React, {useState, useEffect} from 'react';
import './InfoContainer.css';
import Edit from './Assets/Edit.svg';
import { TransRow } from '../../Transactions/TransactionRow';
import { TransRowMobile } from '../../Transactions/TransRowMobile';
import { PropertiesEmpty } from '../../PropertiesMain/PropertiesEmpty/PropertiesEmpty';
import { CalculatorData } from '../../Calculator/CalculatorData';
import { Results } from '../../Calculator/Results';
import { StatsSummary } from '../../PropertiesMain/Properties/PropertyStats/StatsSummary/StatsSummary';
import { getColor } from '../../../Utilities/functions';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon } from '@mui/material';
import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import tooltipWhite from '../../../SharedAssets/tooltipWhite.svg';

export const InfoContainer = (props) => {
    let properties=props.properties;
    let trans = props.transactions;


// -------- Misc Stats Summary Stuff that has to be here b/c I use a useEffect ---------
    const [typeIndex, setTypeIndex] = useState(0);
    const [typeIndexDisplay, setTypeIndexDisplay] = useState(0);
    const [cashFlowClass, setCashFlowClass] = useState()
    const [appreciationClass, setAppreciationClass] = useState()
    const [principleClass, setPrincipleClass] = useState()
    const [tvgClass, setTvgClass] = useState()

    useEffect(() => {
        let basic = 'hover tab tertiaryLabel';
        if (typeIndex === 0) {
            setTypeIndexDisplay('Cash Flow')
            setCashFlowClass(basic + ' cashFlow tabBorderCashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive')
        } else if (typeIndex === 1) {
            setTypeIndexDisplay('Appreciation')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation tabBorderAppreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive')
        } else if (typeIndex === 2 ){
            setTypeIndexDisplay('Principle')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle tabBorderPrinciple')
            setTvgClass(basic + ' positive')
        } else {
            setTypeIndexDisplay('Total Value Gained')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive tabBorderTVG')
        }
      }, [typeIndex])
// -------- End Misc Stats Summary Stuff that has to be here b/c I use a useEffect ---------

    
    

// --------------- CALULATOR CONTENT -----------------

    if (props.type === 'calculator') {

        return (
            <div className='infoContainer' >
               <div className='containerHeader secondaryLabelWhite' >
                    <p>New Purchase Calculator</p>
               </div>
               <div className='containerBody calculatorBody' >
                    <CalculatorData toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} />
               </div>
            </div>
            )

    }
// --------------- END CALULATOR CONTENT -----------------


// --------------- PROPERTY STATS CONTENT -----------------

    else if (props.type === 'stats') {

    return (
        <div className='infoContainer' >
           <div className='containerHeader secondaryLabelWhite' >
            <p>{props.label}</p>
            
            {props.label === 'All Properties' ? '' : (
                <div className='edit' onClick={() => props.toggleDrawer({drawerType:'editProperty', id:props.id})}>
                    <p>Edit Property</p>
                    <img src={Edit} />
                </div>) }
            
           </div>
           <div className='containerBody' >
               <div style={{opacity:props.opacity}}>{props.children}</div>
           </div>
        </div>
        )
    }

// --------------- END PROPERTY STATS CONTENT -----------------



// --------------- STATS SUMMARY CONTENT -----------------

    else if (props.type === 'statsSummary') {


        let metrics = ['Cash Flow', 'Appreciation', 'Principle', 'Total Value Gained'];

        let cashFlowColor = getColor('--cashFlow');
        let appreciationColor = getColor('--appreciation');
        let principleColor = getColor('--principle');
        let tvgColor = getColor('--positive');
    
        let typeColor = typeIndex === 0 ? cashFlowColor : typeIndex === 1 ? appreciationColor : typeIndex === 2 ? principleColor : tvgColor;
    
    
        let handleClick = ({type}) => {
            if (type === 'CashFlow'){
                setTypeIndex(0);
            } else if (type === 'Appreciation'){
                setTypeIndex(1);
            } else if (type === 'Principle') {
                setTypeIndex(2);
            } else {
                setTypeIndex(3);
            }
        }
    
        let handleChangeMetric = (e) => {
            let type;
            type = e.target.value;
            if (type === 'Cash Flow'){
                setTypeIndex(0);
            } else if (type === 'Appreciation'){
                setTypeIndex(1);
            } else if (type === 'Principle') {
                setTypeIndex(2);
            } else {
                setTypeIndex(3);
            }
        }
    



        return (
            <div className='infoContainer' >
               <div className='containerHeader secondaryLabelWhite' >
                    <div className='labelSummary'>
                        <p className='labelSummaryPadding'>{props.label}</p>
                        <img src={tooltipWhite} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'summary'})} className='hover' />
                    </div>
                    
                    
                    {props.mobileType && props.mobileType !== 'large' ? 
                    
                        <TextField
                        id="standard-basic"
                        variant="standard"
                        select
                        required
                        name='metric'
                        value={typeIndexDisplay}
                        onChange={handleChangeMetric}
                        size='small'
                        InputProps={{ disableUnderline: true }}
                        sx={{"& .MuiSelect-select": {color: typeColor, fontWeight: 'bold'}, "& .MuiSelect-icon": {color: typeColor}}}
                      
                        >
                        {metrics.map((option) => (
                            <MenuItem key={option} value={option} >
                            {option}
                            </MenuItem>
                        ))}
                        </TextField>
                    

                    : 
                    
                    <div className='summaryMetrics' >
                        <p className={cashFlowClass} onClick={() => handleClick({type:"CashFlow"})}>Cash Flow</p>
                        <p className={principleClass} onClick={() => handleClick({type:"Principle"})}>Principle Paid</p>
                        <p className={appreciationClass} onClick={() => handleClick({type:"Appreciation"})}>Appreciation</p>
                        <p className={tvgClass} onClick={() => handleClick({type:"TVG"})}>Total Value Gained</p>
                    </div>
                    }
                
               </div>
               <div className='containerBody' >
                   <div style={{opacity:props.opacity}}>
                        <StatsSummary
                        statsData={props.data.statsData}
                        month={props.data.month}
                        currentYear={props.data.currentYear}
                        mobileType={props.mobileType}
                        typeIndex={typeIndex}
                        id={props.id}
                    />
                    </div>
               </div>
            </div>
            )

    }
// --------------- END STATS SUMMARY CONTENT -----------------


// --------------- TRANSACTIONS CONTENT -----------------

    else {

    let showTrans;
    if  (trans.length <1 ) {
            return (
                <div className='infoContainer' >
                    {props.mobile ? <div className='transactionHeader secondaryLabelWhite' >
                        <p >Your Transactions</p>
                    </div>
                    : <div className='transactionHeader secondaryLabelWhite' >
                    <p className='transDate '>Date</p>
                    <p className='transMerchant '>Merchant</p>
                    <p className='transAmount '>Amount</p>
                    <p className='transType '>Category</p>
                    <p className='transProperty '>Property</p>
                    {props.deviceWidth && props.deviceWidth <1050 ? '' : <p className='transAccount '>Account</p>}
                    <p className='transHide '>Split</p>
                    <p className='transHide '>Hide</p>
                </div>}
                <div className='containerBody' >
                    <div style={{opacity:props.opacity}}><PropertiesEmpty toggleDrawer={props.toggleDrawer} type='trans'/></div>
                </div>
                </div>
                )
    } else {

        if (props.mobile) {
            showTrans = props.transactions.map((item) => {
                return <TransRowMobile key={`${item.trans_id}`} 
                                    item={item} 
                                    toggleDrawer={props.toggleDrawer} 
                            
                                    />
            })
        } else {
            showTrans = props.transactions.map((item) => {
                return (<div className='transRowHover'>
                            <TransRow key={`${item.trans_id}`} 
                                    item={item} 
                                    properties={props.properties} 
                                    deleteTrans={props.deleteTrans} 
                                    transactions={props.transactions} 
                                    toggleDrawer={props.toggleDrawer} 
                                    refreshSetter={props.refreshSetter}
                                    deviceWidth={props.deviceWidth}
                                    />
                        </div>)
            })
        }
        
        }

        let pagesArr = [];
        if (props.pages <= 10) {
            for (let i=1; i<props.pages+1; i++){
                pagesArr.push(i);
            }
        } else if (props.pageNum < 5) {
            for (let i=1; i<11; i++){
                pagesArr.push(i);
            }
        } else if (props.pages - props.pageNum < 5) {
            for (let i=props.pages-9; i<props.pages+1; i++){
                pagesArr.push(i);
            }
        } else {
            for (let i=props.pageNum-4; i<props.pageNum+6; i++){
                pagesArr.push(i);
            }
        }
        

        let showPagination = pagesArr.map((item, i) => {
            let styles = 'paginationItem';
            if (item === props.pageNum) {
                styles += ' paginationSelected';
            } 

            return <div key={i} onClick={() => props.handleSetPage({page:item})} className={styles}><p>{item}</p></div>
        })

    return (
            <div className='infoContainer' >
                {props.mobile ? <div className='transactionHeader secondaryLabelWhite' >
                    <p >Your Transactions</p>
                </div>
                : <div className='transactionHeader secondaryLabelWhite' >
                <p className='transDate '>Date</p>
                <p className='transMerchant '>Merchant</p>
                <p className='transAmount '>Amount</p>
                <div className='transType '>
                    <div className='aiImage'></div>
                    <p>Category</p>
                </div>
                <p className='transProperty '>Property</p>
                {props.deviceWidth && props.deviceWidth < 1050 ? '' : <p className='transAccount '>Account</p>}
                <p className='transHide '>Split</p>
                <p className='transHide '>Hide</p>
            </div>}
            
            <div className='containerBody' >
                <div style={{opacity:props.opacity}} >{showTrans}</div>
                <div className='paginationContainer secondaryLabelGray'>
                        {props.pageNum === 1 ? <div className='paginationItem' style={{visibility:'hidden'}} onClick={() => props.handleSetPage({page:1})}><p>{`<<`}</p></div> : <div className='paginationItem' onClick={() => props.handleSetPage({page:1})}><p>{`<<`}</p></div>}
                        {showPagination}
                        {props.pageNum === props.pages ? <div className='paginationItem' style={{visibility:'hidden'}} onClick={() => props.handleSetPage({page:props.pages})}><p>{`>>`}</p></div> : <div className='paginationItem' onClick={() => props.handleSetPage({page:props.pages})}><p>{`>>`}</p></div>}
                    </div>
            </div>
            </div>
            )
        }
// --------------- END TRANSACTIONS CONTENT -----------------

};