import React, {useState, useEffect} from 'react';
import './AddInterest.css';
import form from './1098.jpeg'

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { addTransaction } from '../../../../api/properties';

import { textfieldColorChanges, formatDate } from "../Shared/Misc";
import { getColor } from '../../../../Utilities/functions';
import { getMortgages } from '../../../../api/properties';

export const AddInterest = (props) => {

const [mortgages, setMortgages] = useState();
const [mortgage, setMortgage] = useState({propertyid:'', mortgageid:'', mortgage_name:''});


useEffect(() => {
    getMortgages().then(res => {
        setMortgages(res);
        }
    )}
    , [])

let handleChange = (e) => {
    let index = mortgages.findIndex((element) => element.mortgageid === e.target.value);
    let newMort = mortgages[index];
    setMortgage(newMort);
}

let placeholderYear = new Date().getFullYear() - 1;

const [transaction, setTransaction] = useState({
    year:'',
    amount:'',
});

let date = transaction.year + '-12-01'
let vendor = 'User added interest for mortgage: ' + mortgage.mortgage_name;

let handleSubmit = async (e) => {
    e.preventDefault();
    await addTransaction({propertyID:mortgage.propertyid, category:'Payments: Mortgage Interest (via Mortgage)', date:date, vendor: vendor, visibility: true, amount:transaction.amount*-1});
    props.refreshSetter({type:'tax'});
    props.toggleDrawer({drawerType:''});
}




let handleChangeTransaction = (e) => {
    let transactionNew = { ...transaction, [e.target.name]: e.target.value };
    setTransaction(transactionNew);
}


if (!mortgages){
    return (<p>Loading...</p>)
} else {

    return (

        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                
                <p className='primaryLabel primaryTitle'>Add Annual Interest Paid</p>
                <div className='content introMargin bodyTextBlue'>
                    <p>To help make your tax estimates more accurate, add annual interest payments for your mortgages here. Keep in mind, this WILL NOT impact your property ROI metrics since interest is already included in your monthly mortgage payments.</p>    
                </div>

                

                <div>
                    <p className='secondaryLabelBlue secondaryTitle'>ADD TOTAL INTEREST</p>

                     <p className='tertiaryLabelLight secondaryTextMargin'>* You can find this information by logging into your mortgage servicer in January after your tax year has ended and reviewing your 1098 that your mortgage servicer provides (recommended - see example below) OR manually summing your total interest payments for the year (do the 1098 route).</p>
                     <img src={form} className='imgSize'/>
                     <p className='tertiaryLabelLight secondaryTextMargin'>** Keep in mind, if your mortgage servicer changed mid year, you will want to do this twice (once for each company).</p>


                    
                    <TextField
                        fullWidth
                        id="outlined-select-state"
                        select
                        required
                        name='mortgage'
                        label="For which mortgage?"
                        value={mortgage.mortgageid}
                        onChange={handleChange}
                        className='boxWidth2'
                        margin='normal'
                        sx={textfieldColorChanges}
                    >
                        {mortgages.map((option) => (
                            <MenuItem key={option.mortgageid} value={option.mortgageid} >
                            { `${option.mortgage_name}`}
                            </MenuItem>
                        ))}
                    </TextField>

                    <div className='MultiOnRow'>
                    <TextField
                            required
                            id="standard-read-only-input"
                            type="number"
                            name='year'
                            label="For which year?"
                            placeholder={placeholderYear}
                            value = {transaction.year}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={handleChangeTransaction}
                            margin='normal'
                            sx={textfieldColorChanges}
                        />

        

                        <TextField
                            required
                            id="standard-read-only-input"
                            type="number"
                            name='amount'
                            value = {transaction.amount}
                            variant="outlined"
                            placeholder="0.00"
                            className='boxWidth3'
                            onChange={handleChangeTransaction}
                            margin='normal'
                            sx={textfieldColorChanges}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }} 
                        />

                        </div>

                    
                        {/* Will add this later...
                    <p className='secondaryLabelGreen addAnotherButton'>+ Add Another</p> */}
                </div>


                
                



            </div>
            <div>
                <CustomButton type='submit'>SUBMIT</CustomButton>
            </div>
        </form>


    
    )
    }
}