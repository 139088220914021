import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/RunningTotalTVGImg.png'


export const ChartTotalValueGainedTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin bodyTextGray'><em>Before reading below, please ensure you have read the previous "tool tip" on Total Value Gained.</em></p>

            <p className='paragraphMargin'>The below chart shows a "running total" chart for your Total Value Gained and each metric that makes it up.</p>
            
            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin'>By "running total," we mean that each month includes the value from the previous month. In other words, the values you see in December are actually your total for the entire year.</p>
                
            <p className='paragraphMargin'>The chart is key because it shows which TVG metric is having the biggest impact on your overall earnings.</p>
            
            <p className='paragraphMargin'>Additionally, it is a great way to see where your major fluctuations are coming from. For example, principal paid is typically steady while appreciation and cash flow can have major fluctuations.</p>
            <hr className = 'horLineSolid'/>
            <p className='paragraphMargin bodyTextGray'>One thing to note, you may notice large decreases in cash flow and increases in principal paid around the time of purchase of a new property. This is because when you purchased your new property you had a large, initial cash expense (your downpayment), but what you got from that expense was a large portion of your initial debt/principal paid (your 5 or 20% downpayment). So, overall in the chart and TVG metric it is a wash: an increase in principal paid and a decrease in cash flow.  </p>


        </div>
    
    )

}