import React, {useState, useEffect} from 'react';
import { PropertyTab } from '../PropertyTab/PropertyTab';
import { CSVLink, CSVDownload } from "react-csv";
import './PropertyMenu.css';

export const PropertyMenu = (props) => {
    let properties = props.properties;
    let property = props.property;

    const handleClick = ({id}) => {
        let newProp;
        if (id === -1){
            newProp = {id:-1};
        } else if (id === -2) {
            newProp = {id:-2};
        } else if (id === -3) {
            newProp = {id:-3};
        }
        else if (id === -4) {
            newProp = {id:-4};
        }
        else {
            let index = properties.findIndex((prop) => prop.id === id);
            newProp = properties[index];
        }
        
        props.updateProperty({property:newProp})
    }

    let selectionStatus=false;
    let allPropsSelected=false;
    let unassignedSelected=false;
    let aiSelected=false;

    let personalSelected = false;
    if (property.id === -1) {
        allPropsSelected=true;
    } else if (property.id === -3){
        unassignedSelected=true;
    } else if (property.id === -2){
        personalSelected=true;
    } else if (property.id === -4){
        aiSelected=true;
    }
    else {
        allPropsSelected=false;
        unassignedSelected=false;
    }

    let showProperties = properties.map((item, index) => {
        if (item.id === property.id) {
             selectionStatus=true;
        } else {
             selectionStatus=false;
        }
        let included = true;
        if (item.include_in_all === false && props.type === 'stats') {
            included = false;
        }
         return <PropertyTab
                    key={index}
                    street={item.street}
                    city={item.city}
                    state={item.state}
                    image={item.street_view}
                    onClick={() => handleClick ({id:item.id})}
                    selectionStatus={selectionStatus}
                    included={included}
                    id = {item.id}
                />
    });

   

    return (
        <div className='propertyNav'>

            <PropertyTab id={'all'} onClick={() => handleClick ({id:-1})} selectionStatus={allPropsSelected} type={props.type} included={true} />
            {props.type === 'transaction' ? <PropertyTab id={'unassigned'} onClick={() => handleClick ({id:-3})} selectionStatus={unassignedSelected}  included={true} /> : ''}
            {props.type === 'transaction' ? <PropertyTab id={'ai'} onClick={() => handleClick ({id:-4})} selectionStatus={aiSelected}  included={true} /> : ''}
            {props.type === 'transaction' ? <PropertyTab id={'personal'} onClick={() => handleClick ({id:-2})} selectionStatus={personalSelected} included={true} /> : ''}
            {showProperties}
            {props.type === 'transaction' ? 
                <>
                    <div className='downloadStyle'>
                        {props.allTransactions ? <CSVLink data={props.allTransactions}><p className='secondaryLabelBlue ' >Export Data</p></CSVLink> : ''}
                        <p className='secondaryLabelBlue link' onClick={() => props.handleRemovePlaceholders()} >Remove Placeholders</p>
                    </div> 

                </>
                : ''}


        </div>
    )
};
