import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/allTimeTVGImg.png'

export const AllTimeRunningTotalChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin bodyTextGray'><em>Before reading below, please ensure you have read the previous "tool tips" on <strong>Total Value Gained</strong> and <strong>Value Gained Running Total</strong>.</em></p>

           <p className='paragraphMargin'>Earlier, you saw the running total for the metrics that make up your Total Value Gained for a specific <em>year</em>.</p>

           <p className='paragraphMargin'>In the below chart, you can see the same metrics for <em>all time</em>, starting with your oldest property you've added to WayBoz.</p>

           <img className='tooltipImage' src={image} />

           <p className='paragraphMargin'>This chart allows you to learn some fascinating things about your properties:</p>
           <ol className='paragraphMargin'>
                <li><strong>Have you broken even on your property?</strong> Have you earned back all the cash you invested to purchase, improve, and maintain your property, or is your overall cash flow still negative?</li>
                <li><strong>Where have you captured the most value?</strong> Is this a high cash-flowing property or is all your value gained through appreciation? If so, what if the market has a dip? Will you still be profitable?</li> 
                <li><strong>How much debt have your renters helped cover?</strong> While cash flow and appreciation is great, isn't it amazing how much bonus value you get from renters simply paying your mortgage for you each month?</li>
           </ol>
           <hr className = 'horLineSolid'/>
           <p className='paragraphMargin bodyTextGray'>One thing to note, you may notice large decreases in cash flow and increases in principal paid around the time of purchase of a new property. This is because when you purchased your new property you had a large, initial cash expense (your downpayment), but what you got from that expense was a large portion of your initial debt/principal paid (your 5 or 20% downpayment). So, overall in the chart and TVG metric it is a wash: an increase in principal paid and a decrease in cash flow.  </p>

        </div>
    
    )

}