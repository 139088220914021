import React, { useState, useEffect }from "react";
import TextField from '@mui/material/TextField';
import { CustomButton } from '../../../CoreComponents/CustomButton/CustomButton';
import { CustomButton2 } from '../../../CoreComponents/CustomButton/CustomButton2';
import InputAdornment from '@mui/material/InputAdornment';
import './EditProperty.css';
import house from '../../../../SharedAssets/house.png';
import tooltip from '../../../../SharedAssets/tooltip.svg';
import trash from '../../../../SharedAssets/trash.svg';
import { PurchaseDetails } from '../Shared/PurchaseDetails';
import { PropertyAddress } from '../Shared/PropertyAddress';
import { MortgageDetails } from "../Shared/MortgageDetails";
import Checkbox from '@mui/material/Checkbox';
import { formatDate, textfieldColorChanges, calculatePayoff, checkboxColorChanges } from '../Shared/Misc';
import { 
    getPropertyDetails,
    updateProperty,
    deletePropertyValue,
    addPropertyValue,
    updatePurchaseDetails,
    updateMortgage,
    deleteMortgage,
    deleteProperty,
    addMortgage,
    addStreetViewImage
} from '../../../../api/properties'

import { FormatNumber } from "../../../CoreComponents/FormatNumber/FormatNumber";


export const EditProperty = (props) => {

    const [property, setProperty] = useState();
    const [propertyChanged, setPropertyChanged] = useState(false);

    const [purchaseDetails, setPurchaseDetails] = useState();
    const [currentPropertyValue, setCurrentPropertyValue] = useState();
    const [propertyValues, setPropertyValues] = useState();
    const [propertyValue, setPropertyValue] = useState({date:null, value:null});
    const [mortgages, setMortgages] = useState();
    const [showMortgage, setShowMortgage] = useState(false);
    const [checkbox, setCheckbox] = useState(false);

   
    console.log(props.property)
    
    let propertyID = props.propertyID;
    let id = props.propertyID;
   
        useEffect(() => {
            setProperty(props.property)
            setCheckbox(props.property.include_in_all)
            getPropertyDetails({propertyID}).then(
                
                (res) => { 
                //setProperty(res.property); <--- no longer needed, passed property in via props
                setPurchaseDetails(res.purchaseDetails);
                setCurrentPropertyValue(res.propertyValue);
                setPropertyValues(res.propertyValues);
                setMortgages(res.mortgages);
            })}, 
                []);
           
  
//for updating property details text fields
let handleChange = (e) => {
    let propInfo = { ...property, [e.target.name]: e.target.value };
    setProperty(propInfo);
    setPropertyChanged(true);
 }
 
 //for updating property value text fields
 let handleChangeValue = (e) => {
     let propInfo = { ...propertyValue, [e.target.name]: e.target.value };
     setPropertyValue(propInfo);
  }
 
  //for updating purchase details text fields
 let handleChangePurchase = (e) => {
     let propInfo = { ...purchaseDetails, [e.target.name]: e.target.value };
     setPurchaseDetails(propInfo);
  }
 
  let handleChangeCheckbox = () => {
    setCheckbox(!checkbox);
}


// for adding new mortgage
let newDate = new Date();
let date=formatDate({date:newDate, format:'ymd'})




const [newMortgage, setNewMortgage] = useState({
    mortgage_name:'',
    loan_amount:'',
    monthly_payment:'',
    rate:'',
    term:'',
    start_date:'',
    payoff_date:'',
    includes_pmi:false,
    includes_taxes:false,
    includes_insurance:false
});


  let handleShow = () => {
    if(showMortgage === false) {
        setShowMortgage(true);
    } else {
        setShowMortgage(false);
    }
}  

  // for updating current mortgage
  let handleChangeMortgage = (e, index) => {
     let newValue;
     if (e.target.type === 'checkbox') {
         newValue = e.target.checked;
         } else {
         newValue = e.target.value;
     }
     if (index<=mortgages.length) {
        const newMortgages = [...mortgages];
        newMortgages[index] = {...newMortgages[index], [e.target.name]: newValue };
        setMortgages(newMortgages);

        if ((e.target.name === 'start_date' || e.target.name === 'term') && newMortgages[index].term && newMortgages[index].start_date) {
            let value = calculatePayoff({start_date:newMortgages[index].start_date, term:newMortgages[index].term});
            newMortgages[index] = {...newMortgages[index], 'payoff_date': value };
            setMortgages(newMortgages);
        }

     } else {
        let newMort = {...newMortgage, [e.target.name]: newValue}
        setNewMortgage(newMort);


        if ((e.target.name === 'start_date' || e.target.name === 'term') && newMort.term && newMort.start_date) {
            let value = calculatePayoff({start_date:newMort.start_date, term:newMort.term});
            newMort = {...newMort, 'payoff_date': value };
            setNewMortgage(newMort);
        }


     }


     
  }
 

//for removing Mortgage
let deleteMort = ({mortgageID}) => {
    deleteMortgage({id:mortgageID});
    props.refreshSetter({type:'newMortgage'});
    let index = mortgages.findIndex((e) => e.id === mortgageID);
        mortgages.splice(index, 1);
        let newArr=[...mortgages];
        setMortgages(newArr);
 }



//for removing property value
    let deleteValue = ({valID}) => {
        deletePropertyValue({id:valID});
        let index = propertyValues.findIndex((e) => e.id === valID);
        propertyValues.splice(index, 1);
        let newArr=[...propertyValues];
        setPropertyValues(newArr);
    }
    
    




//loading state
    if (!property || !propertyValues || !mortgages || !purchaseDetails || !currentPropertyValue) {
        return 'Your information is being gathered!';
    } else {




//loaded state

let formattedPurchaseDate = formatDate({date:purchaseDetails.purchase_date, order:'mdy'});
//for existing property values
    let showValues = propertyValues.map((item, index) => {
        let date = formatDate({date:item.date, order:'mdy'});
        return (
                    <div key={index} className='propValues'>
                        <div className='values'>
                            <FormatNumber value={item.value} type='dollarRounded' colorMe='yes' className='secondaryLabelGreen' />
                        </div>
                        <div className='dates'>
                            <p className='bodyTextBlue'>{date}</p>
                        </div>
                        
                        <div className='trashcan'>
                            {purchaseDetails.purchase_price === item.value && formattedPurchaseDate === date ? 
                            ''
                            :
                            <img onClick= { () => {
                                if (window.confirm('Are you sure you want to delete this property value?')) {
                                    props.toggleDrawer({drawerType:'', id:''})
                                    deleteValue({valID:item.id})
                                    props.refreshSetter({type:'deletePropValue'});

                                }
                                }}
                                src={trash} className='hover' />
                            }  
                        </div>

                        
                    </div>)
        })
        

//for new property value
let submitPropertyValues = () => {
    if (!propertyValue.date && !propertyValue.value) {
        return;
    } else if (!propertyValue.date && propertyValue.value) {
        let newDate = new Date();
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        propertyValue.date = year + '-' + month + '-' + day;
        return addPropertyValue({id:id, date:propertyValue.date, value:propertyValue.value});
    } else if (propertyValue.date && !propertyValue.value) {
        return;
    } else {
        return addPropertyValue({id:id, date:propertyValue.date, value:propertyValue.value});
    }
}


let submitMortgages = () => {
    for (let i=0; i<mortgages.length; i++) {

        updateMortgage({
            mortgageID: mortgages[i].id,
            loan_amount: mortgages[i].loan_amount,
            rate: mortgages[i].rate,
            term: mortgages[i].term,
            start_date: mortgages[i].start_date,
            payoff_date:mortgages[i].payoff_date,
            monthly_payment: mortgages[i].monthly_payment,
            includes_pmi: mortgages[i].includes_pmi,
            includes_taxes: mortgages[i].includes_taxes,
            includes_insurance: mortgages[i].includes_insurance,
            mortgage_name: mortgages[i].mortgage_name
        });
    }  
    if (newMortgage.loan_amount) {
        addMortgage({
            propertyID: propertyID,
            loan_amount: newMortgage.loan_amount,
            rate: newMortgage.rate,
            term: newMortgage.term,
            start_date: newMortgage.start_date,
            payoff_date: newMortgage.payoff_date,
            monthly_payment: newMortgage.monthly_payment,
            includes_pmi: newMortgage.includes_pmi,
            includes_taxes: newMortgage.includes_taxes,
            includes_insurance: newMortgage.includes_insurance,
            mortgage_name: newMortgage.mortgage_name
        })
    }
}

//mortgage section jsx
    let mapMortgages = mortgages.map((i,index) => {
            return (
                <div key={index}>
                    <MortgageDetails mortgage={i} handleChangeMortgage={handleChangeMortgage} deleteMort={deleteMort} index={index} reqType='edit' />
                    {index+1 < mortgages.length ? <hr className='mortgageDivider'></hr> : ''}
                </div>
                )
            }
        )




//display
    return (
        <form className='drawerContainer2' onSubmit={(e) => {
            updateProperty({id:id, street:property.street, city:property.city, state:property.state, zip:property.zip, include_in_all:checkbox});
            if(propertyChanged===true){
                addStreetViewImage({street:property.street, city: property.city, state: property.state, propertyID:id});
            }
            props.updatePropertyData({propertyID:id, property:property});
            submitPropertyValues();
            submitMortgages();
            updatePurchaseDetails({id:id, date:purchaseDetails.purchase_date, price:purchaseDetails.purchase_price, cash_to_close:purchaseDetails.cash_to_close})
            e.preventDefault();
            props.toggleDrawer({drawerType:'', id:''});
            props.refreshSetter({type:'editProperty'});
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Edit Property</p>
                
                
                
                {/*PROPERTY ADDRESS*/}
                <p className='secondaryLabelBlue secondaryTitle'>PROPERTY ADDRESS</p>
                <PropertyAddress property={property} handleChange={handleChange} />
                <div className='includeInAll'>
                    <Checkbox 
                        checked={checkbox}
                        onChange={()=>handleChangeCheckbox()}
                        sx={checkboxColorChanges} 
                        name="includes_pmi" 
                        />
                    
                    <div className='tooltip'>
                        <p className='bodyTextBlue'>Include in "All Properties" data?</p>
                        <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'includeAllPropertiesTip'})} />
                    </div>
                </div>
                
                

                {/*PROPERTY IMAGE*/}
                <div className='groupContainer'>
                    <div className='imageContainer'>
                        {property.street_view ? <img className='propertyImage' src={property.street_view} /> : <img className='propertyImage' src={house} />}
                        
                        <div className='secondaryLabelGreen comingSoon' >Coming Soon - Add Your Own Photo</div>
                    </div>
                </div>
                

                {/*PROPERTY VALUES*/}
                <p className='secondaryLabelBlue secondaryTitle'>PROPERTY VALUE</p>
                <div className='groupContainer'>
                    <div className='MultiOnRow'>
                            <TextField
                                id="standard-read-only-input"
                                label="Add New Estimate"
                                name='value'
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChangeValue}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}   
                                type='number'
                                  />
                            <TextField
                                id="standard-read-only-input"
                                type="date"
                                name='date'
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChangeValue}
                                margin='normal'
                                sx={textfieldColorChanges} 
                            />
                    </div>
                    <div>
                        <div className='propValues'>
                            <FormatNumber value={currentPropertyValue} type='dollarRounded' colorMe='yes' className='secondaryLabelGreen' />
                            <div className='dates'>
                                <p className='bodyTextBlue space' >Current</p>
                            </div>
                            <div className='trashcan'></div>
                        </div>
                       {showValues}
                    </div>
                </div>

                

                {/*PURCHASE DETAILS*/}
                <p className='secondaryLabelBlue secondaryTitle'>PURCHASE DETAILS</p>
                <PurchaseDetails purchaseDetails={purchaseDetails} handleChangePurchase={handleChangePurchase} />
                


                {/*MORTGAGE DETAILS*/}
                <div className="twotitles secondaryTitle">
                    <p className='secondaryLabelBlue'>MORTGAGE DETAILS</p>
                    <p className='secondaryLabelGreen hover' onClick={handleShow} >ADD MORTGAGE</p>
                </div>
                {mapMortgages}
                { showMortgage === true ? <MortgageDetails purchaseDate={purchaseDetails.purchase_date} mortgage={newMortgage} handleChangeMortgage={handleChangeMortgage} index={mortgages.length+1}  /> : ''}
                

                <div className='delete gimmieSomeTopMargin'><CustomButton2 onClick= { async () => {
                    
                    if (window.confirm('Are you sure you want to delete this property?')) {
                        props.toggleDrawer({drawerType:'', id:''})
                        await deleteProperty({id:id})
                        props.refreshSetter({type:'deleteProperty'});
                    } 
                    }}>Delete Property</CustomButton2></div>
                
            </div>
            <div >
                <CustomButton type='submit' >SAVE CHANGES</CustomButton>
            </div>
        </form>
    )
    }
};