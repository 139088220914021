import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { YearCashFlowChart } from './YearCashFlowChart';
import { YearCashFlowMonthChart } from './YearCashFlowMonthChart';




export const YearCashFlow = (props) => {


return (

    <div className='roe section'>
        <p className='primaryMetric sectionTitle'>{props.year} Cash Flow: {props.mobileType && props.mobileType !== 'large' ? <br />:''}<FormatNumber value={props.annualCashFlowForYear} type='dollarRounded' colorMe='yes' /></p>
        <p className='secondaryLabelBlueLight sectionTitle'>For {props.year} {props.currentYear === props.year ? "you've" : 'you'} earned <FormatNumber value={props.annualIncomeForYear} type='dollar' colorMe='green' /> in income and spent <FormatNumber value={props.annualExpenseForYear} type='dollar' colorMe='red' /> leaving you with your cash flow of <span className='cashFlow'><FormatNumber value={props.annualCashFlowForYear} type='dollar' /></span>. Check out below to see how your trending year over year and also how {props.year} {props.currentYear === props.year ? "is breaking" : 'broke'} down by month.</p>
        <div className='charts'>
            
            <div className='chartColHalf secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>{props.year} Data by Month & Monthly Averages</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'yearCashFlowMonthChartTip'})} />
                </div>
                
                <div className='metricsTable bodyTextBlue '>
                    <YearCashFlowMonthChart
                        year={props.year}
                        
                        averageMonthlyIncome={props.averageMonthlyIncome}
                        averageMonthlyExpense={props.averageMonthlyExpense}
                        averageMonthlyCashFlow={props.averageMonthlyCashFlow}
                        averageMonthlyIncomeByMonth={props.averageMonthlyIncomeByMonth}
                        averageMonthlyExpenseByMonth={props.averageMonthlyExpenseByMonth}
                        averageMonthlyCashFlowByMonth={props.averageMonthlyCashFlowByMonth}
                        monthlyIncomeForYear={props.monthlyIncomeForYear}
                        monthlyExpenseForYear={props.monthlyExpenseForYear}
                        monthlyCashFlowForYear={props.monthlyCashFlowForYear}

                        propertyID={props.propertyID}
                        toggleDrawer={props.toggleDrawer}
                        mobileType={props.mobileType}
                         />
                    {/*<div className='legendFlex' >
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendIncome'></div>
                                <p className='positive'>Total Income / Month</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='monthlyAverageLegend monthlyAverageLegendIncome'></div>
                                <p className='positive'>Avg Income for Given Month</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendIncome'></div>
                                <p className='positive'>Avg Income all Time / M</p>
                            </div> 
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendExpense'></div>
                                <p className='negative'>Expenses for Month</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='monthlyAverageLegend monthlyAverageLegendExpense'></div>
                                <p className='negative'>Avg Expenses for Month</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendExpense'></div>
                                <p className='negative'>Avg Monthly Expenses All Time</p>
                            </div>
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='lineChartLegend'></div>
                                <div className='lineChartLegendDot'></div>
                                <div className='lineChartLegend lineChartLegendMargin'></div>
                                <p className='cashFlow'>Month's Cash Flow</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='monthlyAverageLegend monthlyAverageLegendCashFlow'></div>
                                <p className='cashFlow'>Avg Cash Flow for Month</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendCashFlow'></div>
                                <p className='cashFlow'>All Months Avg Cash Flow</p>
                            </div>
                        </div>
                    </div>*/}
                
                </div>
            </div>
            
            <div className='chartColHalf secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>Annual Historical Data & Averages</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'yearCashFlowChartTip'})} />
                </div>
                
                <div className='metricsTable bodyTextBlue '>
                    <YearCashFlowChart
                        year={props.year}
                        averageAnnualIncome={props.averageAnnualIncome}
                        averageAnnualExpense={props.averageAnnualExpense}
                        averageAnnualCashFlow={props.averageAnnualCashFlow}
                        annualIncomeLast10={props.annualIncomeLast10}
                        annualExpenseLast10={props.annualExpenseLast10}
                        annualCashFlowLast10={props.annualCashFlowLast10}

                        propertyID={props.propertyID}
                        toggleDrawer={props.toggleDrawer}
                        mobileType={props.mobileType}
                         />
                    {/*<div className='legendFlex'>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendIncome'></div>
                                <p className='positive'>Year's Income</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendIncome'></div>
                                <p className='positive'>Avg Income</p>
                            </div>
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendExpense'></div>
                                <p className='negative'>Total Expense</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendExpense'></div>
                                <p className='negative'>Avg Expense</p>
                            </div>
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='lineChartLegend'></div>
                                <div className='lineChartLegendDot'></div>
                                <div className='lineChartLegend'></div>
                                <p className='cashFlow'>Total Cash Flow</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendCashFlow'></div>
                                <p className='cashFlow'>Avg Cash Flow</p>
                            </div>
                        </div>
                    </div>*/}
                
                </div>
            </div>

            

            
            
        </div>
        {/*<div className='tip'>
            <Tip />
        </div>*/}
                
    </div>



)

}