import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/windowImg.png'

export const WindowCashFlowChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
             <p className='paragraphMargin'>One powerful part of WayBoz is your ability to see how you are performing over a given period. We call these periods <em>windows</em>.  </p>
             <p className='paragraphMargin'>A typical <em>window</em> is either a month (e.g., March) or a season (e.g., Winter).</p>
             <p className='paragraphMargin'>WayBoz provides a bar chart similar to the one you see below so you can see how your <em>window</em> is performing year after year.</p>
             <img className='tooltipImage' src={image} />
             <p className='paragraphMargin'>In the bar chart, the green and red bars represent your total income and expenses for the <em>window</em> for a specific year, while the 
             solid blue line represents your cash flow (the difference between your income and expenses). You can hover over the bar chart and a pop up or "tool tip" will appear that will show you exact numbers for a specific year.</p>
             <p className='paragraphMargin'>Pay special attention to how this solid blue cash flow line is trending over time.</p>
             <p className='paragraphMargin'>Also on the bar chart you will see horizontal dashed lines (again: green, red, and blue). 
             These represent your <em>average</em> income, expenses, and cash flow for that <em>window</em>. In the tool tip, these exact values are shown as the small, gray italicized font.*</p>
             <p className='paragraphMargin'>This first section of WayBoz is vital as it helps you see how specific periods or <em>windows</em> are trending over time, what is impacting your cash flow the most (incomes or expenses), and whether
             you're currently outperforming or underperforming your historical averages. </p>
             <hr className = 'horLineSolid'/>
             <p className='paragraphMargin bodyTextGray'><em>* In case you're wondering, calculations for your averages are based on the date of your oldest transaction for the window. In other words,
                if you purchased your home in 2008 but have only added data to WayBoz as far back as 2020, your averages will NOT be impacted by a series of $0 income and expenses from 2008 until 2020. </em></p>
            <p className='paragraphMargin bodyTextGray'><em>Also, remember, averages are impacted by whether or not you have elected to "Include Capital Expenditures in Averages."  See checkbox at the top of your screen. </em></p>
           
          
        </div>
    
    )

}