import React, { useState, useEffect } from "react";
import { passwordColorChanges, textfieldColorChanges } from "../Shared/Misc";
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getUser, updateUser, updatePassword } from "../../../../api/properties";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import './Account.css'
import logout from '../../Nav/Assets/logout.svg';
import help from '../../Nav/Assets/help.svg';

import { Logout } from '../../../Login/Logout';
import { getStripeLink } from "../../../../api/properties";
import { RoundWhiteButton } from "../../../CoreComponents/CustomButton/RoundWhiteButton";


export const Account = (props) => {


    const [user, setUser] = useState();
    const [password, setPassword] = useState({
        newPassword: '',
        showPassword: false,
    })

    useEffect(() => { 
        getUser().then(setUser);
    }, 
        []);

    let handleChange = (e) => {
        let userInfo = { ...user, [e.target.name]: e.target.value };
        setUser(userInfo);
    }
    
    const handleChangePassword = (prop) => (event) => {
        setPassword({ ...password, [prop]: event.target.value });
      };

    const handleClickShowPassword = () => {
        setPassword({
          ...password,
          showPassword: !password.showPassword,
        });
      };

let handlePasswordSubmit = () => {
    if (password.newPassword.length > 0) {
        updatePassword({password:password.newPassword})
    }
}
     
let handleBilling = async () => {
    let link = await getStripeLink({})
    // If user has a subscription, it is the update subscription link
    // If user doesnt have a subscription, it is the create subscription link
    window.location.href = link;
    
}

if (!user) {
    return 'Your information is being gathered!';
} else {
    return (
        <form className='drawerContainer2' onSubmit={(e) => {
            updateUser({first_name:user.first_name, last_name:user.last_name, email:user.email});
            handlePasswordSubmit();
            e.preventDefault();
            props.toggleDrawer({drawerType:'', id:''});
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Your Profile</p>
                
                
                
                {/*Account Information*/}
                <p className='secondaryLabelBlue secondaryTitle'>ACCOUNT INFORMATION</p>
                <TextField
                    
                    id="standard-read-only-input"
                    label="First Name"
                    name='first_name'
                    value = {user.first_name ? user.first_name : ''}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    margin='normal'
                    sx={textfieldColorChanges}
                />
                <TextField
                    
                    id="standard-read-only-input"
                    label="Last Name"
                    name='last_name'
                    value = {user.last_name ? user.last_name : ''}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    margin='normal'
                    sx={textfieldColorChanges}
                    
                />
                <TextField
                    required
                    id="standard-read-only-input"
                    label="Email"
                    name='email'
                    value = {user.email}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    margin='normal'
                    sx={textfieldColorChanges}
                    
                />



                {/*Update Password*/}
                <p className='secondaryLabelBlue secondaryTitle'>UPDATE PASSWORD</p>   
                

                <FormControl 
                    sx={passwordColorChanges} 
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    >    
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={password.showPassword ? 'text' : 'password'}
                        value={password.newPassword}
                        onChange={handleChangePassword('newPassword')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {password.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                        sx={textfieldColorChanges}
                    />
                </FormControl>
                {props.mobile ? 
                <div className="logoutRow secondaryLabelBlue">
                    <div className="logoutGroup">
                        <img src={help} />
                        <p>HELP@WAYBOZ.COM</p>
                    </div>
                    <div className="logoutGroup group2" onClick={() => Logout()} >
                        <img src={logout} />
                        <p>LOGOUT</p>
                    </div>
                    
                </div> 
                : ''
                }
                <div className='buttDisplay'>
                    <RoundWhiteButton onClick={handleBilling}>Manage Billing Information</RoundWhiteButton>
                </div>

            </div>
            <div >
                <CustomButton type='submit'>DONE</CustomButton>
            </div>
        </form>
    )
}
};