import React from "react";
import { textfieldColorChanges } from '../CoreStructure/Drawer/Shared/Misc';
import { CustomButton } from "../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import  tooltip from '../../SharedAssets/tooltip.svg';
import './Calculator.css'


export const Calculator = (props) => {
    
    let data = props.data;
    let handleChange = props.handleChange;
    let handleSubmit = props.handleSubmit
  
    let mobileType=props.mobileType;



    return (
        <form className='dataContainer' onSubmit={handleSubmit}>

            <p className='primaryLabel primaryTitle '>Add Best Estimates Below</p>

            <div style={{marginBottom:'30px'}}>
                {/*PURCHASE DETAILS*/}
                <p className='secondaryLabelBlue secondaryTitle'>PURCHASE DETAILS</p>
                <div className='MultiOnRow'>
                            <TextField
                                required
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Purchase Price"
                                name='purchase_price'
                                type='number'
                                value= {data.purchase_price}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />
                            <TextField
                                required
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Down Payment"
                                name='down_payment'
                                type='number'
                                value= {data.down_payment}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />   
                </div>
                
                
                {/*Growth Rate*/}
               
               
                    <div className='tooltip2'>
                        <p className='secondaryLabelBlue secondaryTitle'>PROPERTY VALUE OVER TIME</p>
                        <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'growthRate'})}/>
                    </div>
                    {mobileType === 'small' || mobileType === 'xsmall' ? 
                     <div>
                        <div >
                            <p className='bodyTextGray pTweaks'>What percent do you think the property value will increase each year? <em>Tip: test positive and negative scenarios.</em></p>
                        </div>
                        <TextField
                            required
                            placeholder="0.00"
                            id="standard-read-only-input"
                            label="Growth Rate"
                            name='growth_rate'
                            type='number'
                            value= {data.growth_rate}
                            variant="outlined"
                            className='growthRateMobile'
                            onChange={handleChange}
                            margin='normal'
                            sx={textfieldColorChanges} 
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}  
                        /> 
                    </div>
                    : 
                    <div className='MultiOnRow alignStuff'>
                            <TextField
                                required
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Growth Rate"
                                name='growth_rate'
                                type='number'
                                value= {data.growth_rate}
                                variant="outlined"
                                className='growthRate'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}  
                            />
                            <div className="pDiv">
                                <p className='bodyTextGray pTweaks'>What percent do you think the property value will increase each year?</p>
                                <p className='bodyTextGray pTweaks'><em>Tip: test positive and negative scenarios.</em></p>
                            </div>
                        </div>
                        }




                {/*Mortgage Details*/}
                <p className='secondaryLabelBlue secondaryTitle'>MORTGAGE DETAILS</p>
                    <div className='MultiOnRow'>
                            <TextField
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Loan Amount (Primary)"
                                name='loan_amount1'
                                type='number'
                                value= {data.loans[0].loan_amount}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />
                            <TextField
                                id="standard-read-only-input"
                                placeholder="0.00"
                                label="Rate"
                                name='rate1'
                                type='number'
                                value= {data.loans[0].rate}
                                variant="outlined"
                                className='mortDetails'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />  
                            <TextField
                                id="standard-read-only-input"
                                label="Term (years)"
                                name='term1'
                                type='number'
                                value= {data.loans[0].term}
                                variant="outlined"
                                className='mortDetails'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:0}
                                    }} 
                            />       
                </div>
                    <div className='MultiOnRow'>
                            <TextField
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Loan Amount (Secondary)"
                                name='loan_amount2'
                                type='number'
                                value= {data.loans[1].loan_amount}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />
                            <TextField
                                id="standard-read-only-input"
                                placeholder="0.00"
                                label="Rate"
                                name='rate2'
                                type='number'
                                value= {data.loans[1].rate}
                                variant="outlined"
                                className='mortDetails'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />  
                            <TextField
                                id="standard-read-only-input"
                                label="Term (years)"
                                name='term2'
                                type='number'
                                value= {data.loans[1].term}
                                variant="outlined"
                                className='mortDetails'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:0}
                                    }} 
                            />       
                </div>



            {/*Other Exepenses*/}
                <div className='tooltip2'>
                    <p className='secondaryLabelBlue secondaryTitle'>OTHER MONTHLY EXPENSES</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'monthlyExpenses'})}/>
                </div>
                {mobileType === 'small' || mobileType === 'xsmall' ?
                <div>
                    <div>
                        <p className='bodyTextGray pTweaks'>Estimate the property’s average monthly expenses. DON’T  include P&I mortgage payments or initial major projects.</p>
                    </div>
                    <TextField
                        required
                        placeholder="0.00"
                        id="standard-read-only-input"
                        label="Monthly Average"
                        name='monthly_expense'
                        type='number'
                        value= {data.monthly_expense}
                        variant="outlined"
                        className='growthRateMobile'
                        onChange={handleChange}
                        margin='normal'
                        sx={textfieldColorChanges} 
                        onWheel={() => document.activeElement.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: {min:0, step: 'any'}
                            }} 
                    />
                </div>
                :
                <div className='MultiOnRow alignStuff'>
                            <TextField
                                required
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Monthly Average"
                                name='monthly_expense'
                                type='number'
                                value= {data.monthly_expense}
                                variant="outlined"
                                className='growthRate'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }} 
                            />
                            <div className="pDiv">
                                <p className='bodyTextGray pTweaks'>Estimate the property’s average monthly expenses. DON’T  include P&I mortgage payments or initial major projects.</p>
                            </div>
                    </div>
                }



            {/*Project Expenses*/}
            <div className='tooltip2'>
                    <p className='secondaryLabelBlue secondaryTitle'>MAJOR PROJECT EXPENSES</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'projectExpenses'})}/>
                </div>
            <p className='bodyTextGray pTweaks'>Are you planning any renovations or other large one-time expenses? Add the total amount here.</p>
                <div className='MultiOnRow'>
                            <TextField
                                required
                                placeholder="0.00"
                                id="standard-read-only-input"
                                label="Total Cost"
                                name='project_cost'
                                type='number'
                                value= {data.project_cost}
                                variant="outlined"
                                className='project'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />
                           <TextField
                                required
                                placeholder="1"
                                id="standard-read-only-input"
                                label="Starting Month (since purchase)"
                                name='project_start_month'
                                type='number'
                                value= {data.project_start_month}
                                variant="outlined"
                                className='project'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:1}
                                    }}  
                            />
                             <TextField
                                required
                                placeholder="1"
                                id="standard-read-only-input"
                                label="Time to Complete (months)"
                                name='project_length'
                                type='number'
                                value= {data.project_length}
                                variant="outlined"
                                className='project'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges}
                                onWheel={() => document.activeElement.blur()} 
                                InputProps={{
                                    inputProps: {min:1}
                                    }} 
                            />   
                </div>



            {/*INCOME DETAILS*/}
            <p className='secondaryLabelBlue secondaryTitle'>MONTHLY RENTAL INCOME</p>
                <p className='bodyTextGray pTweaks'>How much will you rent your property for and how soon will you start? Eg, "14" if you will start renting on month 14 of ownership.</p>
                <div className='MultiOnRow'>
                            <TextField
                                required
                                placeholder="0.00"

                                id="standard-read-only-input"
                                label="Average Recurring Income"
                                name='monthly_income'
                                type='number'
                                value= {data.monthly_income}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: {min:0, step: 'any'}
                                    }}  
                            />
                            <TextField
                                required
                                placeholder="14"
                                id="standard-read-only-input"
                                label="Starting Month (eg, 14)"
                                name='income_start_month'
                                type='number'
                                value= {data.income_start_month}
                                variant="outlined"
                                className='boxWidth3'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:1}
                                    }} 
                            />   
                </div>


            {/*Selling Month*/}
            <p className='secondaryLabelBlue secondaryTitle'>FUTURE OUTLOOK</p>
                {mobileType === 'small' || mobileType === 'xsmall' ?
                <div>
                    <div >
                        <p className='bodyTextGray pTweaks'>How many months into the future do you want to look? Pick a number. Pay special attention to your return on equity - it often goes down over time!</p>
                    </div>
                    <TextField
                        required
                        placeholder="72"
                        id="standard-read-only-input"
                        label="Number of Months"
                        name='selling_month'
                        type='number'
                        value= {data.selling_month}
                        variant="outlined"
                        className='growthRateMobile'
                        onChange={handleChange}
                        margin='normal'
                        sx={textfieldColorChanges} 
                        onWheel={() => document.activeElement.blur()}
                        InputProps={{
                            inputProps: {min:1}
                            }} 
                    />    
                </div>
                :
                <div className='MultiOnRow alignStuff'>
                            <TextField
                                required
                                id="standard-read-only-input"
                                label="Number of Months"
                                name='selling_month'
                                type='number'
                                value= {data.selling_month}
                                variant="outlined"
                                className='growthRate'
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:1}
                                    }} 
                            />
                            <div className="pDiv">
                                <p className='bodyTextGray pTweaks'>How many months into the future do you want to look? Pick a number. Pay special attention to your return on equity - it often goes down over time!</p>
                            </div>
                </div>
                }
            </div>



            <div>
                <CustomButton type='submit'>GO!</CustomButton>
            </div>
        </form>
    )

};