import React, { useState } from "react";
import { PropertyAddress } from "../Shared/PropertyAddress";
import { PurchaseDetails } from "../Shared/PurchaseDetails";
import { formatDate, textfieldColorChanges } from "../Shared/Misc";
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { addProperty, getStripeLink, updateTrial } from "../../../../api/properties";
import { FirstPropertyBody } from "./FirstPropertyBody";

export const FirstProperty = (props) => {
    let newDate = new Date();
    let date=formatDate({date:newDate, format:'ymd'})

    const [property, setProperty] = useState({street:'', city:'', state:'', zip:''});
    const [purchaseDetails, setPurchaseDetails] = useState({purchase_price:'', purchase_date:date, cash_to_close:''});
    const [propertyValue, setPropertyValue] = useState({date:newDate, value:''});
   

    const [checkbox, setCheckbox] = useState(false);

   

    let handleChangeCheckbox = () => {
        setCheckbox(!checkbox);
    }

    
    let disabled = false;
    

    //for updating property details text fields
    let handleChange = (e) => {
        let propInfo = { ...property, [e.target.name]: e.target.value };
        setProperty(propInfo);
    }
    
    //for updating purchase details text fields
    let handleChangePurchase = (e) => {
        let propInfo = { ...purchaseDetails, [e.target.name]: e.target.value };
        setPurchaseDetails(propInfo);
    }

    //for updating property value text fields
    let handleChangeValue = (e) => {
        let propInfo = { ...propertyValue, [e.target.name]: e.target.value };
        setPropertyValue(propInfo);
    }

    



if (!purchaseDetails) {
    return 'Your information is being gathered!';
} else {
    return (
        <form className='drawerContainer2' onSubmit={ async (e) => {   
            e.preventDefault();
            let newProperty = await addProperty({
                    street:property.street,
                    city:property.city,
                    state:property.state,
                    zip:property.zip,
                    purchase_price:purchaseDetails.purchase_price,
                    purchase_date:purchaseDetails.purchase_date,
                    cash_to_close:purchaseDetails.cash_to_close,
                    value:propertyValue.value,
                    date:propertyValue.date
                });
            props.refreshSetter({type:'newProperty2'});
            props.toggleDrawer({drawerType:'propertyTransactions', newProperty: newProperty});
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Add First Property</p>
                
                
                
                <FirstPropertyBody accountStatus={props.accountStatus} hadTrial={props.hadTrial} handleChangeCheckbox={handleChangeCheckbox} property={property} purchaseDetails={purchaseDetails} propertyValue={propertyValue} handleChange={handleChange} handleChangePurchase={handleChangePurchase} handleChangeValue={handleChangeValue}  />

            </div>
            <div>
                <CustomButton type='submit' disabled={disabled} >ADD PROPERTY</CustomButton>
            </div>
        </form>
    )
}
};