import React from 'react';
import './ComingSoon.css';
import key from '../PropertiesMain/PropertiesEmpty/Key.png';
import { useState, useEffect } from 'react';

import { textfieldColorChanges } from '../CoreStructure/Drawer/Shared/Misc';
import { CustomButton } from "../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { updatePhone, updatePropnum } from '../../api/properties';


export const ComingSoon = (props) => {
    const [propnum, setPropnum] = useState()
    const [view, setView] = useState(1);
    const [phone, setPhone] = useState();
    
    let handleChange = (e) => {
        if (e.target.name==='property_number'){
            let newPropNum = e.target.value;
            setPropnum(newPropNum);
        } else {
            let newPhone = e.target.value;
            setPhone(newPhone);
        }
        
    }
    
    let handleSubmit = (e) => {
        if (e.target.title === 'properties'){
            updatePropnum({propnum});
            setView(2);
        } else {
            updatePhone({phone});
            setView(3);
        }
         e.preventDefault();
    }












    
    return (
    <div >
        <p className='primaryMetric margin' >Welcome to WayBoz!</p>
        <img className='margin shrink' src={key} />

        {
            view === 1 ?
            <form onSubmit={handleSubmit} title='properties'>
                <div className='margin'>
                    <p className='secondaryLabelBlue margin2'>To get started, how many properties do you own?</p> 
                    <p className='bodyTextGray'>Include your personal and investment properties.</p> 
                </div>
                <TextField
                                required
                                className='textfield margin'
                                id="standard-read-only-input"
                                label="How many properties do you own?"
                                name='property_number'
                                type='number'
                                value= {propnum}
                                variant="outlined"
                                onChange={handleChange}
                                margin='normal'
                                sx={textfieldColorChanges} 
                                onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                    inputProps: {min:1}
                                    }}  
                            />
                            <div className='margin length'> <CustomButton type='submit' >Submit</CustomButton></div>
               
            </form> 


            : view === 2 ?
            <form onSubmit={handleSubmit} title='phone'>
                <div >
                    <p className='secondaryLabelGray margin'>Got it - thanks!</p>
                    <p className='secondaryLabelBlue margin'>Great news! WayBoz is almost ready to be released!</p>
                    <p className='primaryLabel margin'>Can't wait? Want early access? Willing to provide product feedback?</p>
                    <p className='secondaryLabelGray margin'>Then join our Beta group below.</p>

                    <TextField
                                    required
                                    className='textfield'
                                    id="standard-read-only-input"
                                    label="What's your best contact number?"
                                    name='phone_number'
                                    type='string'
                                    value= {phone}
                                    variant="outlined"
                                    onChange={handleChange}
                                    margin='normal'
                                    sx={textfieldColorChanges} 
                                    onWheel={() => document.activeElement.blur()}
                                    InputProps={{
                                        inputProps: {min:1}
                                        }}  
                                />
                    <div className='margin length'> <CustomButton type='submit'>Click to Join Beta Group</CustomButton></div>
                </div>
            </form>


            :
            <div>
                <p className='secondaryLabelBlue margin'>Great! We'll be calling or emailing you soon.</p>
                <p className='secondaryLabelGray margin'>Bye... for now.</p>

            </div>
        }
        
        
        <div className='secret' onClick={() => props.pageSetter({response:'properties'})} > </div>
            
       
        

    </div>
    ) 
}