import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { ChartROE } from './ChartROE';



export const ROE = (props) => {
    
return (

    <div className='roe section'>
       <p className='primaryMetric sectionTitle'>{props.year} Return: {props.mobileType && props.mobileType !== 'large' ? <br />:''}<FormatNumber value={props.eoyTVGROE} type='percent' colorMe='yes' /></p>
        <p className='secondaryLabelBlueLight sectionTitle'>Since the start of {props.year}, you had roughly <FormatNumber value={props.yearStartingEquity} type='dollarRounded' colorMe='yes' /> in equity in your {props.propertyID<0 ? 'properties' : 'property'}. That equity {props.year < props.currentYear ? 'made you' : 'is trending to make you' }  <FormatNumber value={props.eoyTVG} type='dollarRounded' colorMe='yes' /> in total value gained for the year. That's a <FormatNumber value={props.eoyTVGROE} type='percent' colorMe='yes' /> Return on Equity (ROE). See how you're doing year over year and if you beat other investment opportunities for {props.year}.</p>
        <div className='charts'>
            
            
            <div className='chartCol secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>{props.year === props.currentYear ? 'Trending' : ''} End of Year Earnings </p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'roe'})}/>
                </div>

                {props.mobileType && props.mobileType !== 'large' ?
                <div className='bodyTextBlue tableMarg' >

                    <div className='mobileTable cashBorder'>        
                        <div className='mobileTableFlex tableLabel'>
                            <p className='cashFlow'>Cash Flow</p>
                            <p className='semiBold'><FormatNumber value={props.eoyCashFlow} type='dollar' /></p>

                        </div>
                        <div className='mobileTableFlex tableValues '>
                            <p><em>Equity Return:</em></p>
                            <p className='semiBold'><FormatNumber value={props.eoyCashFlowROE} type='percent' /></p>
                        </div>
                    </div>

                    <div className='mobileTable appreciationBorder'>        
                        <div className='mobileTableFlex tableLabel'>
                            <p className='appreciation'>Appreciation</p>
                            <p className='semiBold'><FormatNumber value={props.eoyAppreciation} type='dollar' /></p>

                        </div>
                        <div className='mobileTableFlex tableValues '>
                            <p><em>Equity Return:</em></p>
                            <p className='semiBold'><FormatNumber value={props.eoyAppreciationROE} type='percent' /></p>
                        </div>
                    </div>

                    <div className='mobileTable principleBorder'>        
                        <div className='mobileTableFlex tableLabel'>
                            <p className='principle'>Principal</p>
                            <p className='semiBold'><FormatNumber value={props.eoyPrinciple} type='dollar' /></p>

                        </div>
                        <div className='mobileTableFlex tableValues '>
                            <p><em>Equity Return:</em></p>
                            <p className='semiBold'><FormatNumber value={props.eoyPrincipleROE} type='percent' /></p>
                        </div>
                    </div>

                    <div className='mobileTable tvgBorder'>        
                        <div className='mobileTableFlex tableLabel'>
                            <p className='positive'>Total</p>
                            <p className='headerMetric'><FormatNumber value={props.eoyTVG} type='dollar' colorMe='yes' /></p>
                        </div>
                        <div className='mobileTableFlex tableValues '>
                            <p><em>Equity Return:</em></p>
                            <p className='headerMetric'><FormatNumber value={props.eoyTVGROE} type='percent' colorMe='yes' /></p>
                        </div>
                    </div>

                </div>
                :
                <div className='metricsTable bodyTextBlue roeTable'>
                        <div className='roeColumn specialRoeColumn'>
                            <p className='colLabel'><br></br></p>
                            <p className='cashFlow'>Cash Flow</p>
                            <p className='appreciation'>Appreciation</p>
                            <p className='principle bottomBorder'>Principal Paid</p>
                            <p className='headerMetric positive'>Total</p>
                        </div>
                        <div className='roeColumn'>
                            <p className='colLabel'><em>EOY Value Gained</em></p>
                            <p className='semiBold'><FormatNumber value={props.eoyCashFlow} type='dollar' /></p>
                            <p className='semiBold'><FormatNumber value={props.eoyAppreciation} type='dollar' /></p>
                            <p className='semiBold bottomBorder '><FormatNumber value={props.eoyPrinciple} type='dollar' /></p>
                            <p className='headerMetric'><FormatNumber value={props.eoyTVG} type='dollar' colorMe='yes' /></p>
                        </div>
                        <div className='roeColumn'>
                            <p className='colLabel'><em>Equity Return</em></p>
                            <p className='semiBold'><FormatNumber value={props.eoyCashFlowROE} type='percent' /></p>
                            <p className='semiBold'><FormatNumber value={props.eoyAppreciationROE} type='percent' /></p>
                            <p className='semiBold bottomBorder'><FormatNumber value={props.eoyPrincipleROE} type='percent' /></p>
                            <p className='headerMetric'><FormatNumber value={props.eoyTVGROE} type='percent' colorMe='yes' /></p>
                        </div>
                    
                </div>
                }
            </div>

            <div className='chartCol secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>Annual ROE vs {props.year} Alternative Asset Classes</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'chartROETip'})} />
                </div>
                <div className='metricsTable bodyTextBlue'>
                    <ChartROE 
                        year={props.year}
                        assetReturns={props.assetReturns}
                        eoyCashFlowROELast10={props.eoyCashFlowROELast10}
                        eoyPrincipleROELast10={props.eoyPrincipleROELast10}
                        eoyAppreciationROELast10={props.eoyAppreciationROELast10}
                        eoyTVGROELast10={props.eoyTVGROELast10}
                        mobileType={props.mobileType}


                        />
                </div>
            </div>
            
        </div>
        {/*<div className='tip'>
            <Tip />
        </div>*/}
                
    </div>



)

}