import React, { Component } from "react";
import {PlaidLink} from "react-plaid-link";
import {POST, GET, PUT} from "../../../Utilities/networkRequests"
import './Link.css';


export const PlaidModal = (props) => {
    let newProperty= props.newProperty
    let toggleDrawer = props.toggleDrawer;

    const handleOnSuccess = async (public_token, metadata) => {
        // temp taken is used to get institution info
        
        // opens drawer to tell user plaid is thinking
        if (props.type === 'transaction' || props.type === 'onboarding') {
            toggleDrawer({drawerType:'loadingPlaid'});
            }
        
        
        // gets id of institution/item just grabbed
        let institution_id = metadata.institution.institution_id;
        //uses id to see if this user already has this institution added
        let match = await POST(`/plaid/existing`, {
            institution_id
        });

        //if institution added, asks user if they want to edit it (or jumps opens drawer with insitution info)
        if (match.data === true) {
            toggleDrawer({drawerType:'manageInstitution', institution: institution_id});
                
        } else {
            //if not a match to an existing institution, adds it like nowmal
            // takes instutition temp token and sends to BE to get perm token, add institution, add accounts, and return accounts needing routing rules
            let res = await POST(`/plaid/exchange_public_token`, {
                public_token,
                metadata
            });

            if (props.type === 'transaction'){
                props.refreshSetter({type:'newAccount'});
                toggleDrawer({drawerType:'accountRouting', accounts: res.data, misc: {onboarding:false}, newProperty:newProperty} );
            } else {
                toggleDrawer({drawerType:'accountRouting', accounts: res.data, misc: {onboarding:true}, newProperty:newProperty});
            }

           
        }
 
    }

    const handleOnExit = async() => {
        console.log('modal exited');
        
    }
    
    return (
        <div>
            <PlaidLink
                clientName="React Plaid Setup"
                env={process.env.NODE_ENV === 'development' ? 'sandbox' :  process.env.NODE_ENV}
                product={["transactions"]}
                token={props.publicToken}
                onSuccess={handleOnSuccess}
                onExit={handleOnExit}
                className="plaid-link-modal"
             >
                LINK NEW ACCOUNT
            </PlaidLink>
            
        </div>
    );
}

export const PlaidUpdateModal = (props) => {
    let toggleDrawer = props.toggleDrawer;
    
    const handleOnSuccess = async (public_token, metadata) => {
        await PUT(`/plaid/updateAccounts`, {
            metadata
        });

        props.refreshSetter({type:'editAccount'});

        toggleDrawer({drawerType:''});
    }

    const handleOnExit = async() => {
        console.log('modal exited');
    }

if (props.update) {
    return (
        <div>
            <PlaidLink
                clientName="Wayboz"
                env={process.env.NODE_ENV === 'development' ? 'sandbox' :  process.env.NODE_ENV}
                product={["transactions"]}
                token={props.publicToken}
                onSuccess={handleOnSuccess}
                onExit={handleOnExit}
                className="plaid-link-modal-update"
            >
                MANAGE AT PLAID
            </PlaidLink>
            
        </div>
    );
} else {
    return (
        <div>
            <PlaidLink
                clientName="Wayboz"
                env={process.env.NODE_ENV === 'development' ? 'sandbox' :  process.env.NODE_ENV}
                product={["transactions"]}
                token={props.publicToken}
                onSuccess={handleOnSuccess}
                onExit={handleOnExit}
                className="plaid-link-modal"
            >
                MANAGE INSTITUTION
            </PlaidLink>
            
        </div>
    );
    }   
}

export const PlaidReconnectModal = (props) => {

    const handleOnSuccess = async (public_token, metadata) => {
        window.location.reload()
    }

    const handleOnExit = async() => {
        console.log('modal exited');
    }

    return (
        <>
            <PlaidLink
                clientName="Wayboz"
                env={process.env.NODE_ENV === 'development' ? 'sandbox' :  process.env.NODE_ENV}
                product={["transactions"]}
                token={props.publicToken}
                onSuccess={handleOnSuccess}
                onExit={handleOnExit}
                className="plaid-reconnect-modal"
            >
                Click here to reconnect
            </PlaidLink>
            
        </>
    );
}