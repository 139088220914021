
import React from "react";
import plaid from './plaid.png';
import venmo from './venmo.png';
import chime from './chime.png';
import betterment from './betterment.png';
import acorns from './acorns.png';
import { PlaidModal } from "../../../Plaid/Link/Link";

import './Plaid.css';


export const PlaidExplainer = (props) => {
    let newProperty;
    if (props.newProperty) {
        newProperty = props.newProperty
    } else {
        newProperty = false;
    }
    return (
        <div >
            
                <div className='title'>
                    <p className='plaidExplain titleSpacing'>What is</p>
                    <img src={plaid} className='plaidLogo' />
                    <p className='plaidExplain titleSpacing'>?</p>
                </div>
                
                <div className='content bodyTextBlue'>
                <p>Plaid is how top financial companies securely put your bank data in your hands without ever needing to personally touch your bank credentials.</p>
                </div>

                <div className='logoSection'>
                    <p className='bodyTextGray'><em>Used by:</em></p>
                    <div className='logos'>
                        <img src={venmo} className='logo1' />
                        <img src={acorns} className='logo1' />
                        <img src={betterment} className='logo1' />
                        <img src={chime} className='logo1' />

                    </div>
                </div>
                
                
            
            <div className='newSection content bodyTextBlue'>
                <p className='secondaryLabelBlue'>WHY CONNECT YOUR ACCOUNTS?</p>
                <br /><p >The powerful data and insights WayBoz gives you is based on your property transaction history, like all your rental income and property expenses.
                Instead of manually adding transactions one by one, Plaid automatically adds them for you, saving you hours.</p>
            </div>

            <div className='spacing center'>
                    {props.plaidToken ? <PlaidModal publicToken={props.plaidToken} toggleDrawer={props.toggleDrawer} type={props.type} newProperty={newProperty} /> : ""}
                </div>
            
        </div>
    )
};