import React from 'react';
import './PropertiesEmpty.css';
import Key from './Key.png';

import { RoundGreenButton } from '../../CoreComponents/CustomButton/RoundGreenButton'
import { PropertyStats } from '../Properties/PropertyStats/PropertyStats';

export const PropertiesEmpty = (props) => {
    return (
    <div className='emptyContainer'>
        <img src={Key} />
        {props.type === 'properties' ? <p className='primaryLabel'>Wow, it's lonley in here...</p> : <p className='primaryLabel'>There's nothing here...</p> }
        {props.type === 'properties' ? <p className='secondaryLabelGray'>Add your first property to see how your portfolio is performing.</p> : <p className='secondaryLabelGray'>Time to connect an account or manually add a transaction.</p>}
        
        {props.type === 'properties' ? 
            <div className='button'>
                <RoundGreenButton 
                    className='comp'
                    onClick={() => props.toggleDrawer({drawerType:'firstProperty'})}
                    >ADD PROPERTY</RoundGreenButton>
            </div>
        : 
            <div className='button'>
                <RoundGreenButton 
                    className='comp'
                    onClick={() => props.toggleDrawer({drawerType:'add'})}
                    >ADD TRANSACTION</RoundGreenButton>
            </div>
        }
    </div>
    ) 
}