import React, {useState, useEffect} from 'react';

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './Onboarding.css';

import noWhite from './Assets/noWhite.svg'
import noGreen from './Assets/noGreen.svg'

import yesWhite from './Assets/yesWhite.svg'
import yesGreen from './Assets/yesGreen.svg'

import { formatDate, calculatePayoff } from "../Shared/Misc";

import { MortgageDetails } from '../Shared/MortgageDetails';
import { addMortgage, getStripeLink } from '../../../../api/properties';

export const Mortgage = (props) => {
    let newProperty = props.newProperty

// --------- Selections ------------------------ 
const [selected, setSelected] = useState('')

let handleClick = ({clicked}) => {
    setSelected(clicked)
}



// ----------- COPY & PASTE MORTGAGE STUFF ------------
let newDate = new Date();
let date = formatDate({date:newDate, format:'ymd'})  

const [mortgage, setMortgage] = useState({
    mortgage_name:'',
    loan_amount:'',
    monthly_payment:'',
    rate:'',
    term:'',
    start_date:date,
    includes_pmi:false,
    includes_taxes:false,
    includes_insurance:false,
    payoff_date:date
});

let handleChangeMortgage = (e, index) => {
    let newValue;
    if (e.target.type === 'checkbox') {
        newValue = e.target.checked;
        } else {
        newValue = e.target.value;
    }
    let newMortgage = {...mortgage}; 
    newMortgage = {...newMortgage, [e.target.name]: newValue };
    setMortgage(newMortgage);

    if ((e.target.name === 'start_date' || e.target.name === 'term') && newMortgage.term && newMortgage.start_date) {
        let value = calculatePayoff({start_date:newMortgage.start_date, term:newMortgage.term});
        newMortgage = {...newMortgage, 'payoff_date': value };
        setMortgage(newMortgage);
    }
 }




let handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selected === 'yes') {
        await addMortgage({
            propertyID: newProperty.id,
            loan_amount: mortgage.loan_amount,
            rate: mortgage.rate,
            term: mortgage.term,
            start_date: mortgage.start_date,
            monthly_payment: mortgage.monthly_payment,
            includes_pmi: mortgage.includes_pmi,
            includes_taxes: mortgage.includes_taxes,
            includes_insurance: mortgage.includes_insurance,
            mortgage_name: mortgage.mortgage_name,
            payoff_date: mortgage.payoff_date
        }) 
        
    }
    props.refreshSetter({type:'newTrans'});
    props.refreshSetter({type:'newMortgage2'});
    
    // if they have already had a trial but somehow don't have a stripe account, we force them to sign up immediately by giving them a stripe subscription link without a trial
    if(props.hadTrial === true && props.accountStatus === 'pretrial') {
        let link = await getStripeLink({})
        window.location.href = link; 
    } 
    // if they havent had a trial and don't have a stripe account, we send them to the trial screen
    else if (props.accountStatus === 'pretrial') {
        props.toggleDrawer({drawerType:'startTrial'});          
    } 
    // otherwise they are in trial or post trial and have a stripe account, just add property like normal (and stripe account issues will be taken care of upstream to this)
    else {
        props.toggleDrawer({drawerType:''});
    }
}





    return (

        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                
                <p className='primaryLabel primaryTitle'>Add a Mortgage for this Property?</p> 
                

                <div className='buttonFlex'>
                    <div className={selected === 'no' ? 'buttonSelected secondaryLabelWhite drawerButton2Width' : 'drawerButton secondaryLabelGreen drawerButton2Width'} onClick={() => handleClick({clicked:'no'})}>
                        {selected === 'no' ? <img src={noWhite} /> : <img src={noGreen} />}
                        <p>No thank you...</p>
                    </div>
                    <div className={selected === 'yes' ? 'buttonSelected secondaryLabelWhite drawerButton2Width' : 'drawerButton secondaryLabelGreen drawerButton2Width'} onClick={() => handleClick({clicked:'yes'})}>
                        {selected === 'yes' ? <img src={yesWhite} /> : <img src={yesGreen} />}
                        <p>Yes please!</p>
                    </div>
                </div>
                
                {
                selected === 'no' ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>No problem, but if you have a mortgage on this property, you will be missing out on some important ROI & tax info. We recommend you add your mortgage now, even if you have to guess some of the details (you can easily update it later). </p>
                </div> 
                : selected === 'yes' ? 
                <div className='content bodyTextBlue'>
                    <p className='placeholderParagraphMargin escrowInfoContainer content'>Great choice! Don't worry if you have to guess some of the details for now. You can easily update them later. </p>
                    <MortgageDetails purchaseDate={newProperty.purchase_date} mortgage={mortgage} handleChangeMortgage={handleChangeMortgage} index={0} reqType='na' /> 
                </div>
                : ''}
                
            


            
            </div>
            
            
            <div>
                <CustomButton type='submit'>NEXT</CustomButton>
            </div>
            
            
        </form>


    
    )

}