import React, {useState, useEffect} from 'react';
import { getAccounts } from '../../api/properties';
import './InstitutionMenu.css';
import {GET} from '../../Utilities/networkRequests'
import Edit from '../CoreStructure/InfoContainer/Assets/Edit.svg';
import EditGray from '../CoreStructure/InfoContainer/Assets/EditGray.svg';


export const InstitutionMenu = (props) => {

    const [accounts, setAccounts] = useState();
    const [availableAccounts, setAvailableAccounts] = useState();


    let account=props.account;
    let handleSetAccount=props.handleSetAccount;

    useEffect(() => {
        getAccounts().then((res) => {
            let newArr = [];
            res.map((item) => {
                if (item.properties_id === null) {
                    newArr.push(item);
                }
            })
            setAvailableAccounts(newArr);
            setAccounts(res);
    })}, [props.institutionsRefresher]);

        


    let handleAddAccount = async () => {
        try {
            props.toggleDrawer({drawerType:'accountRouting', accounts: availableAccounts, addAccount:true});

        } catch (err) {
            console.log(err)
        }
    }

    let handleEditAccount = ({accountID}) => {
        try {
            props.toggleDrawer({drawerType:'editRouting', accountID: accountID});


        } catch (err) {
            console.log(err)
        }
    }



    let selectedBackground = 'backgroundSelected instBox';
    let selectedText1 = 'secondaryLabelWhite';
    let selectedText2 = 'secondaryLabelWhite instFlex';
    let unselectedBackground = 'backgroundUnselected instBox';
    let unselectedText1 = 'secondaryLabelBlue';
    let unselectedText2 = 'secondaryLabelGray instFlex';


    let backgroundStyleAll = unselectedBackground;
    let textStyleAll = unselectedText1;

    if (account === -1) {
        backgroundStyleAll = selectedBackground;
        textStyleAll = selectedText1;
    }


    if (accounts) {
        let showInstitutions = accounts.map((item, index) => {

            let backgroundStyle = unselectedBackground;
            let textStyle1 = unselectedText1;
            let textStyle2 = unselectedText2;
            let icon = EditGray;

            if (item.account_db_id === account) {
                backgroundStyle = selectedBackground;
                textStyle1 = selectedText1;
                textStyle2 = selectedText2;
                icon = Edit;
            }
            
            return (
                <div key={index} className={backgroundStyle} onClick={() => handleSetAccount({accountID:item.account_db_id})}>
                        <img className='editAcct' onClick={() => handleEditAccount({accountID:item.account_db_id})} src={icon} />
                        <div className={textStyle1}><p>{item.institution_name}</p></div>
                        <div className={textStyle2}><p>{item.name}</p><p>-{item.account_num}</p></div>
                    
                </div>
            )
        })
            

        return (
            <div className='instMenu propertyNav'>
                <div className={backgroundStyleAll} onClick={() => handleSetAccount({accountID:-1})}>
                    <div className={textStyleAll}>
                        <p>All Accounts</p>
                        
                    </div>
                </div>
                {showInstitutions}
                <div onClick={() => handleAddAccount() } className='addAccount'><p className='secondaryLabelBlue '>Add Accounts</p></div>
                
                {/*delete this... only for testing 
                <div onClick={() => {
                    props.toggleDrawer({drawerType:'loadingPlaid'});
                    props.toggleDrawer({drawerType:'manageInstitution', institution: 'ins_113973'});
                }}><p>Click to Trigger Match</p></div>
                */}
                
            </div>
        )
    } else {
        return (<div></div>)
    } 
}
