import React, { useState, useEffect }from "react";
import './Shared.css';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';

import { formatDate, textfieldColorChanges, checkboxColorChanges, calculatePayoff } from "./Misc";

import trash from '../../../../SharedAssets/trash.svg';


export const MortgageDetails = (props) => {
    let mortgage = props.mortgage;
    let handleChangeMortgage=props.handleChangeMortgage
    let deleteMort='';
    let showDelete='';
    let index = props.index;





    let reqType = props.reqType;
    if (reqType === 'edit') {
        deleteMort = props.deleteMort;
        showDelete = <img className='hover trashMargin' onClick= { () => {
            if (window.confirm('Are you sure want to delete this mortgage?')) {
                deleteMort({mortgageID:mortgage.id})
             }}   
            } 
             src={trash} />
    } else {
        showDelete = <div></div>
    }

if (mortgage.start_date === '') {
    let purchaseDate = new Date(props.purchaseDate);
    let mortStart = new Date(purchaseDate.setMonth(purchaseDate.getMonth()+1));
    let year = mortStart.getFullYear();
    let month = mortStart.getMonth();
    //mortStart = new Date(year, month, '02');
    if (month < 9) {
        mortStart = `${year}-0${month+1}-01`;

    }else {
        mortStart = `${year}-${month+1}-01`;
    }
    let e = {target : {type:'', name:'start_date', value:mortStart} }
    handleChangeMortgage(e, index);
}

    return (
        <div className='groupContainer'>
                    
                    <div className='MultiOnRowAlways'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Mortgage Nickname"
                            name='mortgage_name'
                            value= {mortgage.mortgage_name}
                            variant="outlined"
                            className='boxWidth3'                    
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges} 
                        />
                        {showDelete}
                    </div>
    
                    <div className='MultiOnRow'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Starting Loan Amount"
                            name='loan_amount'
                            type='number'
                            value= {mortgage.loan_amount}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges} 
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}  
                        />
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Monthly Payment"
                            name='monthly_payment'
                            type='number'
                            value={mortgage.monthly_payment}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges}
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }} 
                        />
                    </div>
                    <div className='MultiOnRow'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Rate"
                            name='rate'
                            type='number'
                            value= {mortgage.rate}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges} 
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}  
                        />
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Term"
                            name='term'
                            type='number'
                            value={mortgage.term}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges}
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">years</InputAdornment>,
                                }} 
                        />
                    </div>
                    <div className='MultiOnRow'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Start Date (usually month after closing)"
                            name='start_date'
                            type='date'
                            value= {formatDate({date:mortgage.start_date, format:'ymd'})}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges} 
                            
                        />
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Payoff Date"
                            name='payoff_date'
                            type='date'
                            value= {formatDate({date:mortgage.payoff_date, format:'ymd'})}
                            variant="outlined"
                            className='boxWidth3'
                            onChange={(e)=>handleChangeMortgage(e, index)}
                            margin='normal'
                            sx={textfieldColorChanges} 
                            
                        />
                        
                    </div>
                    <div className='pmiStuffFlex'>
                        
                        <div className='pmiStuffFlex2'>
                            <p className="tertiaryLabel pmiStuffRow pmiLabelMarg">INCLUDES</p>
                            <div className='pmiStuffRow'>
                                <div className='pmiStuffRow'>
                                    <Checkbox 
                                        checked={mortgage.includes_pmi}
                                        onChange={(e)=>handleChangeMortgage(e, index)}
                                        sx={checkboxColorChanges} 
                                        name="includes_pmi" 
                                        />
                                    <p className='bodyTextBlue' >PMI</p>
                                </div>
                                <div className='pmiStuffRow'>
                                    <Checkbox 
                                        checked={mortgage.includes_taxes} 
                                        onChange={(e)=>handleChangeMortgage(e, index)}
                                        sx={checkboxColorChanges} 
                                        name="includes_taxes" 
                                        />
                                    <p className='bodyTextBlue'>Taxes</p>
                                </div>
                                <div className='pmiStuffRow'>
                                    <Checkbox 
                                        checked={mortgage.includes_insurance}
                                        onChange={(e)=>handleChangeMortgage(e, index)}
                                        sx={checkboxColorChanges} 
                                        name="includes_insurance" 
                                        />
                                    <p className='bodyTextBlue'>Insurance</p>
                                </div>
                            </div>
                            
                           

                        </div>
                        
                    </div>
                </div>
    )
                                    
};