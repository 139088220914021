import React from 'react';
import { BarChart, Line, ComposedChart, ReferenceLine, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Bar, ResponsiveContainer } from 'recharts';
import { 
    getColor
    } from '../../../../../Utilities/functions';
import { yAxisFormatterPercent } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { tooltipMonthLabel } from '../../../../CoreStructure/Drawer/Shared/Misc';
   

export const ChartROE = (props) => {
    let expense = getColor('--negative');
    let income = getColor('--positive');
    let lineColor = getColor('--lineGray');
    let cashflow = getColor('--cashFlow');
    let appreciation = getColor('--appreciation');
    let principle = getColor('--principle');
    let reit = getColor('--reit');
    let largeCap = getColor('--largeCap');
    let bitcoin = getColor('--bitcoin');
    let sp500 = getColor('--sp500');

    let getStyle = (item) => {
        if (item === 'Total' ){
            return 'row positive'
        } else if (item === "Principal") {
            return 'row principle'
        } else if (item === "Appreciation") {
            return 'row appreciation'
        } else {
            return 'row cashFlow'
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    let formatNum = (num) => {
        return formatter.format(num);
    }

    let getStyle2 = (item) => {
        if (item.name === 'Total') {
            return 'flexDisplay borderTop'
        } else {
            return 'flexDisplay'
        }
    }

    


    function CustomTooltip({ payload, label, active }) {

        if (active) {
          return (
            <div className="custom-tooltip-wide">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{label} Return on Equity</p> 
                </div>
    
                <div className='payload'>
                    {payload.map((item, i) => {
                        return (
                        <div key={i} className={getStyle2(item)}>
                            <p className={getStyle(item.name)} >{item.name}: </p>
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'percent',
                                    minimumFractionDigits: 2
                                }).format((item.value))}`}</p>
                        </div>
                        )
                    })}
                </div>

                <div>
                    <div className='label secondaryLabelBlue sumPadTop'>
                        <p className="label">{props.year} Asset Class Returns</p> 
                    </div>
                    <div className='payload'>
                        <div className='flexDisplay'>
                            <div className='flexDisplay row'>
                                <p className='reit veryBold'>-- </p>
                                <p>{props.assetReturns[0].name}: </p>
                            </div>
                            
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'percent',
                                    minimumFractionDigits: 2
                                }).format(props.assetReturns[0].value)}`}</p>
                        </div>
                        <div className='flexDisplay'>
                            <div className='flexDisplay row'>
                                <p className='largeCap veryBold'>-- </p>
                                <p>{props.assetReturns[1].name}: </p>
                            </div>                            
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'percent',
                                    minimumFractionDigits: 2
                                }).format(props.assetReturns[1].value)}`}</p>
                        </div>
                        <div className='flexDisplay'>
                            <div className='flexDisplay row'>
                                <p className='bitcoin veryBold'>-- </p>
                                <p>{props.assetReturns[5].name}: </p>
                            </div>
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'percent',
                                    minimumFractionDigits: 2
                                }).format(props.assetReturns[5].value)}`}</p>
                        </div>
                        <div className='flexDisplay'>
                            <div className='flexDisplay row'>
                                <p className='sp500 veryBold'>-- </p>
                                <p>{props.assetReturns[6].name}: </p>
                            </div>                            
                            <p className={"row value"}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'percent',
                                    minimumFractionDigits: 2
                                }).format(props.assetReturns[6].value)}`}</p>
                        </div>
                    </div>
                </div>
             
            </div>
          );
        }
        return null;
      }

    
    let combinedArray = props.eoyCashFlowROELast10.map((item, index) => {
        let total1 = props.eoyCashFlowROELast10[index] ? props.eoyCashFlowROELast10[index].roe/100 : 0;
        let total2 = props.eoyPrincipleROELast10[index] ? props.eoyPrincipleROELast10[index].roe/100 : 0;
        let total3 = props.eoyAppreciationROELast10[index] ? props.eoyAppreciationROELast10[index].roe/100 : 0;
        let total4 = props.eoyTVGROELast10[index] ? props.eoyTVGROELast10[index].roe/100 : 0;

    
        return {
            year: item.year,
            cashflow: total1,
            principle: total2,
            appreciation: total3,
            tvg: total4
        };
    });

    let mobileArray = combinedArray.slice(-5);
    
    let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;

    return (
        <div>
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
                <ComposedChart data={mobile ? mobileArray : combinedArray} barGap={2} margin={{bottom:30}}>
                    <CartesianGrid vertical={false} horizontal={false} stroke={lineColor} />

                    <XAxis dataKey="year" angle={-45} tickMargin='20' interval='preserveStartEnd' fontSize={mobile ? 14 : 16}/>
                    <YAxis tickFormatter={yAxisFormatterPercent} domain={['auto', 'auto']} allowDataOverflow={true} fontSize={mobile ? 14 : 16} width={mobile ? 50 : 60}/>
                    <ReferenceLine y='0'  stroke={lineColor} strokeWidth={1} />

                    <Tooltip content={<CustomTooltip />} />

                    <Bar name='Cash Flow' dataKey="cashflow" fill={cashflow} barSize={10} />
                    <Bar name='Appreciation' dataKey="appreciation" fill={appreciation} barSize={10} />
                    <Bar name='Principal' dataKey="principle" fill={principle} barSize={10} />
                    <Line name='Total' type="monotone" dataKey="tvg" stroke={income} strokeWidth={1.5} />


                    <ReferenceLine y={props.assetReturns[0].value} stroke={reit} strokeDasharray="7 7" strokeWidth={1.5} />
                    <ReferenceLine y={props.assetReturns[1].value} stroke={largeCap} strokeDasharray="7 7" strokeWidth={1.5} />
                    <ReferenceLine y={props.assetReturns[5].value} stroke={bitcoin} strokeDasharray="7 7" strokeWidth={1.5} />
                    <ReferenceLine y={props.assetReturns[6].value} stroke={sp500} strokeDasharray="7 7" strokeWidth={1.5} />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}