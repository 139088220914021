import React, {useState, useEffect} from 'react';
import './EscrowAdd.css';

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { addTransaction } from '../../../../api/properties';

import { textfieldColorChanges, formatDate } from "../Shared/Misc";
import { getColor } from '../../../../Utilities/functions';

export const EscrowAdd = (props) => {
let type = props.type;
let properties=props.properties;

let newDate = new Date();
let date = formatDate({date:newDate, format:'ymd'})

const [value, setValue] = useState('');
const [property, setProperty] = useState({id:'', street:'', city:'', state:'', zip:''});
const [transaction, setTransaction] = useState({
    vendor:'',
    date:date,
    amount:'',
    category:''
});


let category = 'Insurances: Homeowners';
if (type === 'insurance' && value === 'a'){
    category = 'Insurances: Homeowners (via Escrow)';
} else if (type === 'taxes' && value === 'a') {
    category = 'Payments: Property Taxes (via Escrow)';
} else if (type === 'taxes' && value === 'b') {
    category = 'Payments: Property Taxes';
} else {
    category = 'Insurances: Homeowners';
}

let handleSubmit = async (e) => {
    e.preventDefault();
    await addTransaction({propertyID:property.id, category:category, date:transaction.date, vendor: transaction.vendor, visibility: true, amount:transaction.amount*-1});
    props.refreshSetter({type:'tax'});
    props.toggleDrawer({drawerType:''});
}



const handleChangeRadio = (e) => {
    setValue(e.target.value);
};

let handleChange = (e) => {
    let index = properties.findIndex((element) => element.id === e.target.value);
    let newProp = properties[index];
    setProperty(newProp);
}

let handleChangeTransaction = (e) => {
    let transactionNew = { ...transaction, [e.target.name]: e.target.value };
    setTransaction(transactionNew);
}

let waybozGreen = getColor('--positive');
let firstLabel = type === 'insurance' ? 'I pay Homeowners Insurance through escrow (i.e., with my mortgage)' : 'I pay Property Taxes through escrow (i.e., with my mortgage)';
let secondLabel = transaction.vendor ? `I pay directly to ${transaction.vendor}` : type === 'insurance' ? 'I pay directly to my insurance company' : 'I pay directly to the county/locality';


    return (

        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                
                {type === 'insurance' ? <p className='primaryLabel primaryTitle'>Add Homeowners Insurance Payments</p> : <p className='primaryLabel primaryTitle'>Add Property Tax Payments</p>}
                <div className='content introMargin bodyTextBlue'>
                    <p>Do you pay your {type === 'insurance' ? 'Homeowners Insurance' : 'Property Taxes'} with your mortgage? Let’s quickly update your data so it’s accurate: </p>    
                </div>

                <div>
                    <p className='secondaryLabelBlue secondaryTitle paymentMethodMargin'>CONFIRM PAYMENT METHOD </p>

                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChangeRadio}
                        className='bodyTextBlue'
                        sx={{
                            "& .MuiRadio-root": { color: waybozGreen },
                            "& .MuiRadio-root.Mui-checked": { color: waybozGreen },
                          }}
                    >
                        <FormControlLabel value="a" control={<Radio required={true} />} label={firstLabel} />
                        {value === 'a' ? 
                            <div className = 'escrowInfoContainer content'>
                                <p>Sounds good. Since it is already included in your mortgage payments, we will make sure your ROI data doesn’t include this information twice.</p>
                            </div> : ''}
                        <FormControlLabel value="b" control={<Radio required={true} />} label={secondLabel} />
                        {value === 'b' ?
                            <div className = 'escrowInfoContainer content '>
                                <p>Perfect. We will add a new Property Tax transaction for you which will properly update all your metrics and data for you.</p>
                            </div> : ''}
                    </RadioGroup>

                </div>

                <div>
                    <p className='secondaryLabelBlue secondaryTitle'>ADD PAYMENTS</p>

                    {type === 'insurance' && value === 'a' ? 
                            <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment your mortgage servicer made directly to your Homeowners Insurance provider on your behalf. You can find your exact charges by reviewing your escrow statements or your Homeowners Insurance bill.</p> 
                            : type === 'insurance' && value === 'b' ?
                            <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment you made directly to your Homeowners Insurance provider. You can find your exact charges by reviewing your bank statements or your Homeowners Insurance bill.</p> 
                            : type === 'taxes' && value === 'a' ?
                            <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment your mortgage servicer made directly to your county or locality on your half. You can find your exact charges by reviewing your escrow statements, calling your county/locality, or reviewing a statement they likely sent to the property.</p>
                            : type === 'taxes' && value === 'b' ?
                            <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment you made directly to your county or locality. You can find your exact charges by reviewing your bank statements, calling your county/locality, or reviewing a statement they likely sent to the property.</p>
                            : type === 'insurance' ?
                            <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment made directly to your Homeowners Insurance provider. You can find your exact charges by reviewing your Homeowners Insurance bill.</p> 
                            : <p className='tertiaryLabelLight secondaryTextMargin'>* This is for the payment made directly to your county or locality. You can find your exact charges by calling your county/locality or reviewing a statement they likely sent to the property.</p>
                        }

                    
                    <TextField
                        fullWidth
                        id="outlined-select-state"
                        select
                        required
                        name='property'
                        label="For which property?"
                        value={property.id}
                        onChange={handleChange}
                        className='boxWidth2'
                        margin='normal'
                        sx={textfieldColorChanges}
                    >
                        {properties.map((option) => (
                            <MenuItem key={option.id} value={option.id} >
                            {option.id === -2 ? `${option.street}` : `${option.street}, ${option.city}, ${option.state}`}
                            </MenuItem>
                        ))}
                    </TextField>

                    <div className='MultiOnRow'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Date"
                            name='date'
                            type='date'
                            value = {formatDate({date:transaction.date, format:'ymd'})}
                            variant="outlined"
                            className='project'

                            onChange={handleChangeTransaction}
                            margin='normal'
                            sx={textfieldColorChanges}
                        />

                        <TextField
                            required
                            id="standard-read-only-input"
                            label={type === 'insurance' ? 'Insurance Company' : 'County/Locality Name'}
                            name='vendor'
                            value = {transaction.vendor}
                            variant="outlined"
                            className='project'

                            onChange={handleChangeTransaction}
                            margin='normal'
                            sx={textfieldColorChanges}
                        />

                        <TextField
                            required
                            id="standard-read-only-input"
                            type="number"
                            name='amount'
                            value = {transaction.amount}
                            variant="outlined"
                            placeholder="0.00"
                            className='project'

                            onChange={handleChangeTransaction}
                            margin='normal'
                            sx={textfieldColorChanges}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }} 
                        />

                    </div>

                    
                        {/* Will add this later...
                    <p className='secondaryLabelGreen addAnotherButton'>+ Add Another</p> */}
                </div>


                
                



            </div>
            <div>
                <CustomButton type='submit'>SUBMIT</CustomButton>
            </div>
        </form>


    
    )

}