import React from "react";
import './tooltip.css';

export const GrowthRate = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p>As a reminder, you gain value on your property in 3 ways:</p>
            <br />
            <ol>
                <li>Cash Flow</li>
                <li>Principal Paid</li>
                <li>Appreciation</li>
            </ol>
            <br />
            <p>To calculate the appreciation portion of your gained value, we need to know how much your property is increasing in value each year. We do this by using an annual growth rate.</p>
            <br />
            <p>For example, if your property value currently is $500,000 and you predict the annual growth rate will be 5%, then you're saying you think your property will be worth $525,000 by the end of the year. This means your apprecation value gained for the year is $25,000. </p>
            <br />
            <p className='secondaryLabelBlue'>But what should your growth rate be?</p>
            <br />
            <p>Well, here are some real ones. Keep in mind these are national averages so certain cities gained/lost much more:</p>
            <br />
            <ul className='tooltipLI'>
                <li>US Best (September 2021): 19%</li>
                <li>US Worst (March 2009): -11%</li>
                <li>US Average (since 1992): 5%</li>
                <li>Average US Inflation (since 1914): 3% </li>
            </ul>
            <br />
            <p>We recommend running the calculator a few times trying different growth rates, both positive and negative growth rates. This way you can see how strong your investment really is.</p>            
            <br />
            <p className='secondaryLabelBlue'>Example</p>
            <br />
            <p>Let's say you think that $500,000 property is actually going to go DOWN 2% this year. So by the end of the year you will have lost $10,000 in value. However, if we look at the other two ways you are gaining value and, let's say, your cash flow is $3,000/m and your principal paid is $1,000 each month, by the end of the year your overall value gained will be $38,000 ((($3,000 + $1,000) * 12) - $10,000). </p>
            <br />
            <p>Let's say your debt on the property is $400,000 (80%), then you have $100,000 in equity ($500,000 - $400,000). When you compare your $38,000 value gained against your $100,000 in equity you get a 38% return on equit which is awesome!</p>
            <br />
            <p>So even though your property value might be losing value each year, it still possibly could be a great investment. You just have to run the numbers.</p>
        
            
        </div>
    
    )

}