import './App.css';
import Router from "../Router/Router";


function App() {

  return (
    <div className="app-container">
        <Router />
    </div>
  );
}

export default App;
