import React, {useState, useEffect } from "react";
import './MonthlyTransactions.css'
import { getMonthTransactions } from "../../../../api/properties";
import { FormatNumber } from "../../../CoreComponents/FormatNumber/FormatNumber";
import { formatDate } from "../Shared/Misc";


export const MonthlyTransactions = (props) => {
    
    let inc = props.payload.Income;
    let exp = props.payload.Expense;
    let propertyID=props.propertyID;
    let year = props.payload.year;

    let windowName;
    let windowNum;

    switch(props.payload.month) {
        case ('January'):
        case ('Jan'):
            windowName = 'January';
            windowNum = 1;
            break;
        case ('February'):
        case ('Feb'):
            windowName = 'February';
            windowNum = 2;
            break;
        case ('March'):
        case ('Mar'):
            windowName = 'March';
            windowNum = 3;
            break;
        case ('April'):
        case ('Apr'):
            windowName = 'April';
            windowNum = 4;
            break;
        case ('May'):
            windowName = 'May';
            windowNum = 5;
            break;
        case ('June'):
        case ('Jun'):
            windowName = 'June';
            windowNum = 6;
            break;
        case ('July'):
        case ('Jul'):
            windowName = 'July';
            windowNum = 7;
            break;
        case ('August'):
        case ('Aug'):
            windowName = 'August';
            windowNum = 8;
            break;
        case ('September'):
        case ('Sep'):
            windowName = 'September';
            windowNum = 9;
            break;
        case ('October'):
        case ('Oct'):
            windowName = 'October';
            windowNum = 10;
            break;
        case ('November'):
        case ('Nov'):
            windowName = 'November';
            windowNum = 11;
            break;
        case ('December'):
        case ('Dec'):
            windowName = 'December';
            windowNum = 12;
            break;
        case ('Spring (Mar - May)'):
        case ('Spring'):
            windowName = 'Spring';
            windowNum = 20;
            break;
        case ('Summer (Jun - Aug)'):
        case ('Summer'):
            windowName = 'Summer';
            windowNum = 30;
            break;
        case ('Fall (Sep - Nov)'):
        case ('Fall'):
            windowName = 'Fall';
            windowNum = 40;
            break;
        case ('Winter (Dec - Feb)'):
        case ('Winter'):
            windowName = 'Winter';
            windowNum = 50;
            break;
        default:
            windowName = 'Oops';
            break;    
    }

const [incomes, setIncomes] = useState();
const [expenses, setExpenses] = useState();

useEffect(() => {
    getMonthTransactions({propertyID:propertyID, type:'income', window: windowNum, year}).then(setIncomes);
    getMonthTransactions({propertyID:propertyID, type:'expense', window: windowNum, year}).then(setExpenses);
  }, []);

if (!incomes || !expenses) {
    return 'Your information is being gathered!'
} else {





    let incomeDisplay = incomes.map((row, i) => {
        return (
            <div key={i}>
                <div className='transRow'>
                    <p className='dateWid'>{formatDate({date:row.date, order:'mdy'})}</p>
                    <p className='vendorWid'>{row.vendor}</p>
                    {row.category === 'Income: Deposit' ? <p className='amountWid'><FormatNumber value={row.amount} type='dollar'/>*</p> : <p className='amountWid'><FormatNumber value={row.amount} type='dollar'/></p>}
                </div>
                <hr className='transDivider'></hr>
            </div>
        )
    })

    let expenseDisplay = expenses.map((row, i) => {
        return (
            <div key={i} >
                <div className='transRow'>
                    <p className='dateWid'>{formatDate({date:row.date, order:'mdy'})}</p>
                    <p className='vendorWid'>{row.vendor}</p>
                    {row.category === 'Income: Deposit' ? <p className='amountWid'><FormatNumber value={row.amount} type='dollar'/>*</p> : <p className='amountWid'><FormatNumber value={row.amount} type='dollar'/></p>}
                </div>
                <hr className='transDivider'></hr>
            </div>
        )
    })

    return (
    <div className='tooltipInfo'>
        <p className='primaryLabel primaryTitle'>{`Income & Expenses: ${windowName} ${year}`}</p>
        <div className='totalHeader'>
            <p className='secondaryLabelBlue headerLabelTrans '>Total Income: </p>
            <div className='secondaryLabelGreen'><FormatNumber value={inc} type='dollar'/></div>
        </div>
        {incomeDisplay}
        <div className='totalHeader'>
            <p className='secondaryLabelBlue headerLabelTrans headerLabel'>Total Expenses: </p>
            <div className='secondaryLabelRed'><FormatNumber value={-exp} type='dollar'/></div>
        </div>
        {expenseDisplay}
        <p className='tertiaryLabelLight totalHeader'><em>* Deposits not included in totals</em></p>

    </div>
    )
    }
}