import React from 'react';
import loading from './assets/loading.svg';
import './Loading.css';


export const LoadingSmall = () => {
    
    
    return (
        <div>
            <img src={loading} /> 
        </div>
       
    )
};