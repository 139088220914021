import React, {useState, useEffect} from 'react';
import { InfoContainer } from '../CoreStructure/InfoContainer/InfoContainer';


export const CalculatorContainer = (props) => {
    

        return (
            <div className='properties'>
                <InfoContainer type='calculator' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} />
            </div>
        )

  
};