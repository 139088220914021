import React from "react";
import './tooltip.css';

export const MonthlyExpenses = () => {

    return (
   
        <div className='content bodyTextBlue'>
            <p>Whether you have a mortgage or not, every property has a number of expenses that you, as the owner, will have to pay. For this section, you need to calcuate what your total expenses will be for each month for your property.</p>
            <br />
            <p className='secondaryLabelBlue'>However, do NOT include the principal and interest portion of your mortgage payment. We've calculated that for you already.</p>
            <br />
            <p>Here are some items you should be considering:</p>
            <br/>

            <p className='tertiaryLabel'>Other Mortgage Payment Items</p>
            <ul className='tooltipLI'>
                <li>Property Taxes (guestimate your annual cost and divide by 12)</li>
                <li>Homeowners Insurance</li>
                <li>Private Mortgage Insurance (PMI)</li>
            </ul>
            <br/>
            
            <p className='tertiaryLabel'>Utilities</p>
            <ul className='tooltipLI'>
                <li>Electric</li>
                <li>Gas</li>
                <li>Internet</li>
                <li>Water, Sewer, Misc Utilities</li>
                <li>Cable/TV</li>
            </ul>
            <br/>

            <p className='tertiaryLabel'>Property Services & Maintenance</p>
            <ul className='tooltipLI'>
                <li>HOA</li>
                <li>Property Management Fees</li>
                <li>Cleaning Services</li>
                <li>Seasonal Yard Care</li>
                <li>Pest Control</li>
                <li>Security</li>
                <li>Misc Budget for Repairs</li>                
                <li>Appliances & Furnishings Maintenance</li>
            </ul>
            <br/>

            <p className='tertiaryLabel'>Administrative</p>
            <ul className='tooltipLI'>
                <li>Legal</li>
                <li>Taxes</li>
                <li>Other Insurances (eg, Umbrella)</li>
                <li>General Misc</li>
            </ul>
            <br/>
                






        </div>
    
    )

}