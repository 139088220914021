import React, {useState, useEffect} from 'react';

import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './Onboarding.css';

import transactionsGreen from './Assets/transactionsGreen.svg';
import calculatorGreen from './Assets/calculatorGreen.svg';
import propertiesGreen from './Assets/propertiesGreen.svg';

import transactionsWhite from './Assets/transactionsWhite.svg';
import calculatorWhite from './Assets/calculatorWhite.svg';
import propertiesWhite from './Assets/propertiesWhite.svg';


export const WhereStart = (props) => {
let properties = props.properties;
let page = props.page;
let pageSetter = props.pageSetter;
let toggleDrawer = props.toggleDrawer;
// --------- Selections ------------------------ 
const [selected, setSelected] = useState('')

let handleClick = ({clicked}) => {
    setSelected(clicked)
}




let handleSubmit = async (e) => {
    e.preventDefault();
    if ((selected === 'metrics' || selected === 'transactions') && (!properties || properties.length < 1)) {
        toggleDrawer({drawerType:'firstProperty'})
    } else {

        if (selected === 'metrics' && page !== 'properties') {
            pageSetter({response:'properties'});
        } else if (selected === 'transactions' && page !== 'transactions') {
            pageSetter({response:'transactions'});
        } else if (selected === 'calculator' && page !== 'calculator'){
            pageSetter({response:'calculator'});
        }
        toggleDrawer({drawerType:''});
    }
    
}

let disabled = true;
if (selected) {
    disabled = false;
}




    return (

        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                
                <p className='primaryLabel primaryTitle'>Where would you like to start today?</p> 
                <div className='content introMargin tertiaryLabelLight'>
                    <p>Choose an option to continue:</p>
                </div>

                <div className='buttonFlex'>
                    <div className={selected === 'metrics' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'metrics'})}>
                        {selected === 'metrics' ? <img src={propertiesWhite} /> : <img src={propertiesGreen} />}
                        <p>See how my properties are performing</p>
                    </div>
                    <div className={selected === 'transactions' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'transactions'})}>
                        {selected === 'transactions' ? <img src={transactionsWhite} /> : <img src={transactionsGreen} />}
                        <p>Manage my property transactions</p>
                    </div>
                    <div className={selected === 'calculator' ? 'buttonSelected secondaryLabelWhite drawerButtonWidth' : 'drawerButton secondaryLabelGreen drawerButtonWidth'} onClick={() => handleClick({clicked:'calculator'})}>
                        {selected === 'calculator' ? <img src={calculatorWhite} /> : <img src={calculatorGreen} />}
                        <p>Run some numbers for a new purchase</p>
                    </div>
                </div>
                <div className='skip tertiaryLabelLight'>
                    <p className={selected === 'skip' ? 'tertiaryLabel hover' : 'hover'} onClick={() => handleClick({clicked:'skip'})}>Something Else</p>
                </div>
                

                {
                (selected === 'metrics' || selected === 'transactions') && (!properties || properties.length < 1) ? 
                <div className = 'escrowInfoContainer content'>
                    <p>Sounds good, but first you'll need to add a property!</p>
                </div>  
                
                : ''}
                     
            </div>
            
            
            <div>
                <CustomButton type='submit' disabled={disabled} >LET'S GO!</CustomButton>
            </div>
            
            
        </form>


    
    )

}