import {GET, PUT, POST, DELETE} from "../Utilities/networkRequests"

export const getMarketRate = async ({city}) => {
  const res = await GET(`/market/${city}`);
  return res.data;
};

  export const getCurrentEquity = async ({propertyID}) => {
    const res = await GET(`/equity/current/${propertyID}`);
    return res.data;
  };

  export const getCurrentMonthlyCashFlow = async ({propertyID}) => {
    const res = await GET(`/cashflow/current/${propertyID}`);
    return res.data;
  };

  export const getProperties = async () => {
    let res= await GET(`/properties`)
    return res.data;
};

  export const getPropertyStats2 = async ({properties, year, window, includeCapEx}) => {
    const body = {
      properties,
      year,
      window,
      includeCapEx
    }
    let res =  await POST(`/propertystats2`, body);
    return res.data;
  };

  export const getTaxData = async ({properties, year}) => {
    const body = {
      properties,
      year
    }
    let res =  await POST(`/taxes`, body);
    return res.data;
  };

  export const getMonthTransactions = async ({propertyID, type, window, year}) => {
    const res = await GET(`/property/transactions/${propertyID}/${window}/${year}/${type}`);
    return res.data;
  };

  export const getTransactions = async ({propertyID, offSet, perPage, accountID}) => {
    const res = await GET(`/transactions/${propertyID}/${offSet}/${perPage}/${accountID}`);
    return res.data;
  };

  export const getAllTransactions = async () => {
    const res = await GET(`/transactions`);
    return res.data;
  };

  export const getTransactionCount = async ({propertyID, accountID}) => {
    const res = await GET(`/transactions/count/${propertyID}/${accountID}`);
    return res.data;
  };

  export const updateTransaction = async ({value, transID, column}) => {     
    const body = {
      value,
      column,
    }
    const response = await PUT(`/transactions/${transID}`, body);
    return response.data;
  };

  export const updateFullTransaction = async (trans) => {     
    const body = {
      trans
    }
    const response = await PUT(`/transactions/`, body);
    return response.data;
  };

  export const deleteTransaction = async ({transID}) => {
    const res = await DELETE(`/transactions/${transID}`);
      return res.data;
  };

  export const deleteTransactions = async ({accountID, date}) => {
    const res = await DELETE(`/transactions/${accountID}/${date}`);
      return res.data;
  };

  export const deletePlaceholders = async () => {
    const res = await DELETE(`/placeholders`);
      return res.data;
  };

  export const getCalculation = async ({data}) => {
    const body = {
     data
    }
    const response = await POST(`/calculator`, body);
    return response.data;
  };

  export const getUser = async () => {
    const res = await GET(`/user`);
    return res.data;
  };

  export const updateUser = async ({first_name, last_name, email}) => {
    const body = {
      first_name,
      last_name,
      email
     }
     const response = await PUT(`/user`, body);
     return response.data;
  };

  export const updatePassword = async ({password}) => {
      const body = {
        password
       }
       const response = await PUT(`/user/password`, body);
       return response.data;
  };

  export const updatePhone = async ({phone}) => {
    const body = {
      phone
     }
     const response = await PUT(`/user/phone`, body);
     return response.data;
};

export const updatePropnum = async ({propnum}) => {
  const body = {
    propnum
   }
   const response = await PUT(`/user/propnum`, body);
   return response.data;
};

  export const addProperty = async ({street, city, state, zip, purchase_price, purchase_date, cash_to_close, value, date /*income, expense*/ }) => {
    const body = {
      street,
        city,
        state,
        zip,
        purchase_price,
        purchase_date,
        cash_to_close,
        value,
        date
        //income,
        //expense
     }
     const response = await POST(`/property`, body);
     return response.data;
  };

  export const addMortgage = async ({propertyID, loan_amount, rate, term, start_date, payoff_date, monthly_payment, includes_pmi, includes_taxes, includes_insurance, mortgage_name}) => {
    const body = {
      propertyID,
      loan_amount,
      rate,
      term,
      start_date,
      payoff_date,
      monthly_payment,
      includes_pmi,
      includes_taxes,
      includes_insurance,
      mortgage_name
     }
     const response = await POST(`/property/mortgages/${propertyID}`, body);
     return response.data;
  };

  export const addBulkTransaction = async ({arr}) => {
    const body = {
      arr
    }
    const response = await POST(`/property/bulktransaction`, body);
    return response.data;
};

  export const addTransaction = async ({propertyID, category, date, vendor, visibility, amount}) => {
    const body = {
      propertyID,
      category,
      date,
      vendor,
      visibility,
      amount
    }
    const response = await POST(`/property/transaction`, body);
    return response.data;
};

  export const getPropertyDetails = async ({propertyID}) => {
    let res =  await GET(`/propertydetails/${propertyID}`);
    return res.data;
  };

  export const addPropertyValue = async ({id, date, value}) => {
      const body = {
        date,
        value
       }
       const response = await POST(`/property/value/${id}`, body);
       return response.data;

  };

  export const updateProperty = async ({id, street, city, state, zip, include_in_all}) => {
    const body = {
      street,
      city,
      state,
      zip,
      include_in_all
    }
    const response = await PUT(`/property/${id}`, body);
    return response.data;
  };

  export const updatePurchaseDetails = async ({id, date, price, cash_to_close}) => {
    const body = {
      date,
      price,
      cash_to_close
    }
    const response = await PUT(`/property/purchase/${id}`, body);
    return response.data;
  };

  export const updateMortgage = async ({mortgageID, loan_amount, rate, term, start_date, payoff_date, monthly_payment, includes_pmi, includes_taxes, includes_insurance, mortgage_name}) => {
    const body = {
      mortgageID,
        loan_amount,
        rate,
        term,
        start_date,
        payoff_date,
        monthly_payment,
        includes_pmi,
        includes_taxes,
        includes_insurance,
        mortgage_name
    }
    const response = await PUT(`/property/mortgages/${mortgageID}`, body);
    return response.data;
  };

  export const deleteMortgage = async ({id}) => {
    const res = await DELETE(`/property/mortgages/${id}`);
    return res.data;
  };

  export const deleteProperty = async ({id}) => {
    const res = await DELETE(`/property/${id}`);
    return res.data;
};

  export const deletePropertyValue = async ({id}) => {
      const res = await DELETE(`/property/value/${id}`);
      return res.data;
  };

  export const getAccounts = async () => {
    const res = await GET(`/accounts`);
    return res.data;
  };

  export const getAccount = async ({accountID}) => {
    const res = await GET(`/accounts/${accountID}`);
    return res.data;
  };

  export const getNewPlaidTransactions = async () => {
    return await GET(`/plaid/transactions`);
  };

  export const startupItems = async () => {
    const res = await PUT(`/user/startup`);
    return res.data;
  };

  export const getStripeLink = async () => {
    const res = await GET(`/stripelink`);
    return res.data;
  };

  export const createShortTrial = async ({}) => {
    const body = {
    }
    const response = await POST(`/stripeShortTrial`, body);
    return response.data;
};

export const updateTrial = async () => {
  const res = await PUT(`/user/trial`);
  return res.data;
};

export const addStreetViewImage = async ({street, city, state, propertyID}) => {
  const body = {
    street,
    city,
    state,
    propertyID
  }
  const response = await PUT(`/streetview`, body);
  return response.data;
};

export const getMortgages = async () => {
  const res = await GET(`/mortgages`);
  return res.data;
};

export const addPlaceholders = async ({propertyID, income, expense }) => {
  const body = {
      propertyID,
      income,
      expense
   }
   const response = await POST(`/placeholders`, body);
   return response.data;
};