import React from "react";
import { CustomButton3 } from "../../../CoreComponents/CustomButton/CustomButton3";
import { getStripeLink } from "../../../../api/properties";

import image from '../../../../SharedAssets/StartTrial.svg'
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";

export const PaymentError = (props) => {
    

    let handleBilling = async () => {
        let link = await getStripeLink({})
        // If user has a subscription, it is the update subscription link
        // If user doesnt have a subscription, it is the create subscription link
        window.location.href = link;
        
    }

if (props.body === 'body'){
    return (
        <div>
            <div>
                <img src={image} className="someButtMargin" />
                <p className='headerMetric blue someButtMargin'>Oops! Your Billing Needs Updating.</p>
            </div>
            <div className="someButtMargin">
                    <CustomButton3 onClick={handleBilling}>Keep Using WayBoz</CustomButton3>
            </div>
            
        </div>

    )
} else {
    return (
        <div className="drawerContainer2">
            <div className="scrollControl">
                <img src={image} className="someButtMargin" />
                <p className='headerMetric blue someButtMargin'>Oops! Your Billing Needs Updating.</p>
                {props.body==='body' ? '': 
                    <div className='content bodyTextBlue'>
                        <br />
                        <p>Maybe your trial just ended? Or your credit card has an issue? Click the button below to keep making sure you know how well your properties are performing.</p>
                        <br />
                        <p>Don’t worry - we intentionally keep WayBoz very inexpensive so anyone can use it! In fact, it's probably the <b>least costly</b> and <b>most valuable</b> expense you have at only $6 per month for each property.</p>
                    </div>
                }
            </div>
            <div>
                    <CustomButton onClick={handleBilling}>Keep Using WayBoz</CustomButton>
            </div>
            
        </div>

    )
    }
}
