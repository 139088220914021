import axios from "axios";
import jwt_decode from "jwt-decode";

export function deleteToken(){
	localStorage.removeItem('jwtToken');
}

export function isTokenLive() {
	let tokenLive = false;

    if (localStorage.jwtToken) {
        const token = localStorage.jwtToken;
		const decoded = jwt_decode(token);
		const currentTime = Date.now() / 1000;
		if (decoded && decoded.exp > currentTime) {
			tokenLive = true
		}
    }
	return tokenLive
}

export function setToken(response){
	const { token } = response.data;
	localStorage.setItem("jwtToken", token);
}