import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { PersonalExpensesMonthlyChart } from './PersonalExpensesMonthlyChart';
import { PersonalExpensesYearlyChart } from './PersonalExpensesYearlyChart';




export const PersonalExpenses = (props) => {


return (

    <div className='roe section'>
        <p className='primaryMetric sectionTitle'>Personal Expenses Covered? {props.mobileType && props.mobileType !== 'large' ? <br />:''}{props.annualCashFlowForYear >= props.annualPersonalExpenseForYear ? <span className='positive'>Yes</span> : <span className='negative'>No</span> }</p>
        <p className='secondaryLabelBlueLight sectionTitle'>For {props.year} {props.propertyID === -1 ? "your properties'" : "this property's"} total cash flow of <span className='cashFlow'><FormatNumber value={props.annualCashFlowForYear} type='dollar' /></span> {props.annualCashFlowForYear >= props.annualPersonalExpenseForYear  ? "covers" : 'does not cover'} your personal expense total of <FormatNumber value={props.annualPersonalExpenseForYear} type='dollar' colorMe='red' />. </p>
            
        
        <div className='charts'>
            
            
            <div className='chartColHalf secondaryLabelBlue'>
                 <div className='tooltip'>
                    <p>{props.year} Property Cash Flow vs Personal Expenses</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'personalExpensesMonthlyChartTip'})} />
                </div>
                
                <div className='metricsTable bodyTextBlue '>
                    
                    <PersonalExpensesMonthlyChart
                        year={props.year}
                        
                        averageMonthlyPersonalExpenseForYear={props.averageMonthlyPersonalExpenseForYear}
                        monthlyPersonalExpenseForYear={props.monthlyPersonalExpenseForYear}
                        averageMonthlyCashFlowForYear={props.averageMonthlyCashFlowForYear}
                        monthlyCashFlowForYear={props.monthlyCashFlowForYear}

                        propertyID={props.propertyID}
                        toggleDrawer={props.toggleDrawer}
                        mobileType={props.mobileType}
                    />

                    {/*
                        <div className='legendFlex'>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendCashFlow'></div>
                                <p className='cashFlow'>{props.year} Average Property Cash Flow / M: </p>
                            </div>
                            <div>
                                <p><FormatNumber value={props.averageMonthlyCashFlowForYear} type='dollar' /></p>
                            </div>
                        </div>
                        <div className='legendFlex'>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendExpense'></div>
                                <p className='negative'>{props.year} Average Personal Expenses / M: </p>
                            </div>
                            <div>
                                <p><FormatNumber value={props.averageMonthlyPersonalExpenseForYear} type='dollar' /></p>
                            </div>
                        </div>
                    */}
                    
                
                </div>
            </div>

            <div className='chartColHalf secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>Annual Property Cash Flow vs Personal Expenses</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'personalExpensesYearlyChartTip'})} />
                </div>
                <div className='metricsTable bodyTextBlue '>
                    <PersonalExpensesYearlyChart
                        year={props.year}
                        
                        annualPersonalExpenseLast10={props.annualPersonalExpenseLast10}
                        averageAnnualPersonalExpense={props.averageAnnualPersonalExpense}
                        annualCashFlowLast10={props.annualCashFlowLast10}
                        averageAnnualCashFlow={props.averageAnnualCashFlow}

                        propertyID={props.propertyID}
                        toggleDrawer={props.toggleDrawer}
                        mobileType={props.mobileType}
                    />
                    {/*
                    <div className='legendFlex'>
                        <div className='legendFlex2'>
                            <div className='averageLineLegend averageLineLegendCashFlow'></div>
                            <p className='cashFlow'>All Time Average Property Cash Flow / Y: </p>
                        </div>
                        <div>
                            <p><FormatNumber value={props.averageAnnualCashFlow} type='dollar' /></p>
                        </div>
                    </div>
                    <div className='legendFlex'>
                        <div className='legendFlex2'>
                            <div className='averageLineLegend averageLineLegendExpense'></div>
                            <p className='negative'>All Time Average Personal Expenses / Y: </p>
                        </div>
                        <div>
                            <p><FormatNumber value={props.averageAnnualPersonalExpense} type='dollar' /></p>
                        </div>
                    </div>*/}
                
                </div>
            </div>

            
            
        </div>
        {/*<div className='tip'>
            <Tip />
        </div>*/}
                
    </div>



)

}