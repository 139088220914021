import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { WindowCashFlowChart } from './WindowCashFlowChart';



export const WindowCashFlow = (props) => {


return (

    <div className='roe section'>
      
        <p className='primaryMetric sectionTitle'>{props.window} {props.year} Cash Flow: {props.mobileType && props.mobileType !== 'large' ? <br />:''}<FormatNumber value={props.windowCashFlowForYear} type='dollarRounded' colorMe='yes' /></p>
        <p className='secondaryLabelBlueLight sectionTitle'>We're <FormatNumber value={props.timeInWindow.percent} type='percentRounded' /> through {props.window} {props.year} and {props.timeInWindow.percent>=100 ? 'you' : "you've"} earned <FormatNumber value={props.windowIncomeForYear} type='dollar' colorMe='green' /> in income and spent <FormatNumber value={props.windowExpenseForYear} type='dollar' colorMe='red' /> leaving you with your cash flow of <span className='cashFlow'><FormatNumber value={props.windowCashFlowForYear} type='dollar' /></span>. See how that compares to other years below.</p>
        <div className='charts'>
            
            
            <div className='chartColBig secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>{props.window} Historical Data & Averages</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'windowCashFlowChartTip'})} />
                </div>
                
                <div className='metricsTable bodyTextBlue '>
                    <WindowCashFlowChart
                        window={props.window}
                        year={props.year}
                        averageWindowIncome={props.averageWindowIncome}
                        averageWindowExpense={props.averageWindowExpense}
                        averageWindowCashFlow={props.averageWindowCashFlow}
                        windowIncomeLast10={props.windowIncomeLast10}
                        windowExpenseLast10={props.windowExpenseLast10}
                        windowCashFlowLast10={props.windowCashFlowLast10}
                        propertyID={props.propertyID}
                        toggleDrawer={props.toggleDrawer}
                        mobileType={props.mobileType}
                         />
                    {/*<div className='legendFlex'>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendIncome'></div>
                                <p className='positive'>Total Income</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendIncome'></div>
                                <p className='positive'>Avg Income</p>
                            </div>
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='barChartLegend barChartLegendExpense'></div>
                                <p className='negative'>Total Expense</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendExpense'></div>
                                <p className='negative'>Avg Expense</p>
                            </div>
                        </div>
                        <div className='legendWrap'>
                            <div className='legendFlex2'>
                                <div className='lineChartLegend'></div>
                                <div className='lineChartLegendDot'></div>
                                <div className='lineChartLegend'></div>
                                <p className='cashFlow'>Total Cash Flow</p>
                            </div>
                            <div className='legendFlex2'>
                                <div className='averageLineLegend averageLineLegendCashFlow'></div>
                                <p className='cashFlow'>Avg Cash Flow</p>
                            </div>
                        </div>
                    </div>*/}
                
                </div>
            </div>

            <div className='chartColSmall secondaryLabelBlue'>

                <div className='dataTable'>
                    <div className='tooltip'>
                        <p>{props.window} Averages</p>
                    </div>
                    <div className='bodyTextBlue tableMarg' >
                        <div className='mobileTable incomeBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='positive'> Avg {props.window} Income:</p>
                                <p className='semiBold'><FormatNumber value={props.averageWindowIncome} type='dollar'  /> </p>
                            </div>
                        </div>

                        <div className='mobileTable expenseBorder'>                        
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='negative'>Avg {props.window} Expense:</p>
                                <p className='semiBold'><FormatNumber value={props.averageWindowExpense} type='dollar' /></p>
                            </div>
                        </div>

                        <div className='mobileTable cashBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='cashFlow'>Avg {props.window} Cash Flow:</p>
                                <p className='semiBold'><FormatNumber value={props.averageWindowCashFlow} type='dollar' colorMe='yes' /></p>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div className='dataTable'>
                    <div className='tooltip '>
                        {props.window === 'Winter' && props.currentMonth <= 2 ?
                        <p>Current Year ({props.currentYear-1}-{props.currentYear}) </p>
                        :
                        props.window === 'Winter' && props.currentMonth > 2
                        ?
                        <p>Current Year ({props.currentYear}-{props.currentYear+1}) </p>
                        :
                        <p>Current Year ({props.currentYear}) </p>
                        }
                    </div>
                    <div className='bodyTextBlue tableMarg' >
                        <div className='mobileTable incomeBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='positive'>Current {props.window} Income:</p>
                                {props.window === 'Winter' && props.currentMonth <= 2 ? 
                                <p className='semiBold'><FormatNumber value={props.windowIncomeLast10[8].total} type='dollar'  /> </p>
                                : 
                                <p className='semiBold'><FormatNumber value={props.windowIncomeLast10[9].total} type='dollar'  /> </p>
                                }
                            </div>
                        </div>

                        <div className='mobileTable expenseBorder'>                        
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='negative'>Current {props.window} Expense:</p>
                                {props.window === 'Winter' && props.currentMonth <= 2 ? 
                                <p className='semiBold'><FormatNumber value={props.windowExpenseLast10[8].total} type='dollar' /></p>
                                : 
                                <p className='semiBold'><FormatNumber value={props.windowExpenseLast10[9].total} type='dollar' /></p>
                                }
                            </div>
                        </div>

                        <div className='mobileTable cashBorder'>
                            <div className='mobileTableFlex singleRowPadding'>
                                <p className='cashFlow'>Current {props.window} Cash Flow:</p>
                                {props.window === 'Winter' && props.currentMonth <= 2 ? 
                                <p className='semiBold'><FormatNumber value={props.windowCashFlowLast10[8].total} type='dollar' colorMe='yes' /></p>
                                : 
                                <p className='semiBold'><FormatNumber value={props.windowCashFlowLast10[9].total} type='dollar' colorMe='yes' /></p>
                                }
                            </div>
                        </div>

                    </div>
                </div>     
                   
                    
            </div>
            
        </div>
        {/*<div className='tip'>
            <Tip />
        </div>*/}
                
    </div>



)

}