import React from "react";
import { Properties } from './Properties/Properties';
import { PropertiesEmpty } from './PropertiesEmpty/PropertiesEmpty';
import { Loading } from "../CoreComponents/Loading/Loading";
import { PaymentError } from "../CoreStructure/Drawer/PaymentError/PaymentError";


export const PropertiesMain = (props) => {

    if (!props.properties || !props.property || !props.accountStatus) {
        return <Loading />;
    }  else if (props.properties.length<1) {
        return <PropertiesEmpty toggleDrawer={props.toggleDrawer} type='properties'/>;
    } else if (props.accountStatus === 'payment-bad' || (props.accountStatus === 'pretrial' && props.hadTrial === true)) {
        return <PaymentError body='body'/>
    } else {
        return (
                <div >
                  <Properties propertyData={props.propertyData} 
                                toggleDrawer={props.toggleDrawer} 
                                properties={props.properties} 
                                property={props.property} 
                                updateProperty={props.updateProperty} 
                                mobileType={props.mobileType}
                                windowsArr={props.windowsArr}
                                yearsArr={props.yearsArr} 
                                handleChangeYear={props.handleChangeYear}
                                year={props.year}
                                window={props.window}
                                loading={props.loading}
                                handleChangeCheckbox={props.handleChangeCheckbox}
                                includeCapEx={props.includeCapEx}
                                />
                </div>
                )
    }
};