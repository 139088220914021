import React, { useState, useEffect } from "react";
import { Calculator } from "./Calculator";
import { Results } from "./Results";
import { getCalculation, getMarketRate } from "../../api/properties";
import './Calculator.css'
import drawerCalc from '../../SharedAssets/drawerCalc.svg'


export const CalculatorData = (props) => {
     
    
    const [data, setData] = useState({
        purchase_price: '',
        down_payment: '',
        growth_rate: '',
        loans: [
            { 
                loan_amount: 0,
                rate: '',
                term: 30
            },
            { 
                loan_amount: '',
                rate: '',
                term: ''
            }],
        monthly_expense: '',
        project_cost: 0,
        project_start_month: 1,
        project_length: 1,
        monthly_income: '',
        income_start_month: '',
        selling_month: ''
        });


        useEffect(() => {
            getMarketRate({city:'USA'}).then((res) => {
                let mortLoans = data.loans;
                let newLoan =  { ...mortLoans[0], ['rate']: res[0].interest};
                mortLoans.splice(0,1,newLoan); 
                let newData = { ...data, loans: mortLoans, ['growth_rate']: res[0].rate }; 
                setData(newData);
            });
          }, []);



    const [results, setResults] = useState();
    const [visibility, setVisibility] = useState(false);

    let handleChange = (e) => {
        let newData={}
        let newLoan={};
        let loan;
        let mortLoans = data.loans;
        if (e.target.name === 'loan_amount1' || e.target.name === 'rate1' || e.target.name === 'term1') {
            loan = data.loans[0];
            let target = e.target.name.slice(0, -1);
            newLoan =  { ...loan, [target]: e.target.value };
            mortLoans.splice(0,1,newLoan); 
            newData = { ...data, loans: mortLoans };
        } else if (e.target.name === 'loan_amount2' || e.target.name === 'rate2' || e.target.name === 'term2') {
            loan = data.loans[1];
            let target = e.target.name.slice(0, -1);
            newLoan =  { ...loan, [target]: e.target.value };
            mortLoans.splice(1,1,newLoan); 
            newData = { ...data, loans: mortLoans };
        } else {  
            if ((e.target.name === 'purchase_price' && data.down_payment) || (e.target.name === 'down_payment' && data.purchase_price) ) {
                let nLoanAmt;
                if (e.target.name === 'purchase_price') {
                    nLoanAmt = e.target.value - data.down_payment;
                } else {
                    nLoanAmt = data.purchase_price - e.target.value;
                }
                let mortLoans = data.loans;
                let nLoan =  { ...mortLoans[0], ['loan_amount']: nLoanAmt};
                mortLoans.splice(0,1,nLoan); 
                newData = { ...data, [e.target.name]: e.target.value, loans: mortLoans };
            } else {
                newData = { ...data, [e.target.name]: e.target.value };
            }

         }
        setData(newData);
    }
    
  
    let handleSubmit = (e) => {
            getCalculation({data}).then((res) => {
            setResults(res);
            setVisibility(true);
        })
        e.preventDefault();
    }

    return (
        <div >
            <p className='calcMargin primaryLabel primaryTitle'>Quickly find out how profitable your new purchase could be</p>
            <img src={drawerCalc} />

            <div className='inputResults'>
                <div className='inputResultsCol'>
                    <Calculator toggleDrawer={props.toggleDrawer} data={data} handleChange={handleChange} handleSubmit={handleSubmit} mobileType={props.mobileType} />
                </div>
                <div className='inputResultsCol'>
                    {visibility ? <Results results={results} income_start_month={data.income_start_month} /> : ''} 
                </div>
            </div>
        </div>
    )

};