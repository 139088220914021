import React, {useState,useEffect} from 'react';
import './Transaction.css';
import { formatDate, altFormatNumber } from '../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';
import { getColor } from '../../Utilities/functions';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

import trash from '../../SharedAssets/trash.svg';
import show from '../../SharedAssets/show.svg';
import hide from '../../SharedAssets/hide.svg';
import split from '../../SharedAssets/split.svg';
import ai from '../../SharedAssets/ai.svg';


import { updateTransaction } from '../../api/properties';

import { categories } from '../../Utilities/categories';

export const TransRow = (props) => {
    //likely need to move a lot of the state management stuff to app.js so metrics update on changes to transations

    let newArr = [{id:-2, street:'Non Property (Personal) Transaction', city:'', state:''}]
    let properties=props.properties;
    for (let i=0; i<properties.length; i++)
    {
    newArr.push(properties[i])
    }

    
    const [trans, setTrans] = useState(props.item);
  //if (trans.property_id === -2) {
  //      setTrans({...trans, property_id:0});
  //  }
    let handleChangeTrans = (e) => {
        let newVal;

        // if user enters a bad date, creates a date based on today's date
        if(e.target.name === 'date' && !e.target.value){
            let newDate = new Date;
            let year = newDate.getFullYear();
            let month = newDate.getMonth() + 1;
            let day = newDate.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            newVal = year + '-' + month + '-' + day;
        } else {
            newVal = e.target.value;
        }
        let transInfo = { ...trans, [e.target.name]: newVal };
        setTrans(transInfo);
        }

let displayDate = formatDate({date:trans.date, order:'ymd'})


// dealing with displaying negative signs elsewhere....
const [displayValue, setDisplayValue] = useState(trans.amount);

let identifyDisplayValue = () => {
    if (trans.amount < 0 ) {
        return trans.amount * -1;
    } else {
        return trans.amount;
    }
    
}

useEffect(() => {
    let dv = identifyDisplayValue();
    setDisplayValue(altFormatNumber({value:dv, type:'numberWithDecimals'}))
    //setDisplayValue(parseFloat(dv).toFixed(2));
    }, []);


    let handleChangeAmount = (e) => {
        let newVal;
        newVal = e.target.value;
        //let transInfo = { ...trans, [e.target.name]: newVal };
        setDisplayValue(newVal);
        }
    
    let updateChangeAmount = () => {
        let newVal;
        if (trans.amount < 0 && displayValue >= 0){
            newVal = displayValue*-1;
        } else if (trans.amount < 0 && displayValue < 0) {
            newVal = displayValue*-1;
        } else if (trans.amount >= 0 && displayValue >= 0){
            newVal = displayValue;
        } else if (trans.amount >= 0 && displayValue < 0) {
            newVal = displayValue;
        } else {
            newVal = displayValue;
        }
        let transInfo = { ...trans, ['amount']: newVal };
        if (newVal < 0){
            setDisplayValue(altFormatNumber({value:newVal*-1, type:'numberWithDecimals'}))
        } else {
            setDisplayValue(altFormatNumber({value:newVal, type:'numberWithDecimals'}))
        }
        setTrans(transInfo);
        return newVal;
    }








// property selection
    const [property, setProperty] = useState({id:''});
    useEffect(() => {
        if (trans.property_id) {
            setProperty({ id:trans.property_id })
        }
        
      }, [trans.property_id])

    let handleChange = async (e) => {
        let property = newArr.findIndex((element) => element.id === e.target.value);
        let newProp = newArr[property];
        trans.street = newProp.street;
        setProperty(newProp);
        await updateTransaction({value:newProp.id, transID:trans.trans_id, column:'property'});
        setAiIconVisibility(false);
        props.refreshSetter({type:'editTransactions'});

}


    // trashing or changing visibility of transaction
    const [visibility, setVisibility] = useState(trans.visibility);
    const [image, setImage] = useState();
    const [rowStyle, setRowStyle] = useState('transactionRow');

    useEffect(() => {
        let sty;
        if (visibility === false) {
            sty = 'transactionRow hidden'
        } else if (!trans.street) {
            sty = 'transactionRow unassignedRow'
        } else {
            sty = 'transactionRow'
        }
        setRowStyle(sty)
      }, [visibility, property])
   
    let red = getColor('--negative');
    let green = getColor('--positive');
    let blue = getColor('--waybozBlue')
    let color = blue;
    let amountColor = trans.amount > 0  ? green : red;

    useEffect(() => {
        let imgSrc;
        if (trans.institution_id === null) {
            trans.institution_name = 'Manually Added';
            imgSrc = trash;
        } else if (visibility === true) {
            imgSrc = show;
        } else {
            imgSrc = hide;
        }
        setImage(imgSrc)
      }, [visibility, trans.property_id])
    
    
    let handleClick = async () => {
    if (image === trash) {
            if (window.confirm('Are you sure you want to delete this transaction?')) {
                props.deleteTrans({transID: trans.trans_id})
            }
    } else if (image === show) {
        setVisibility(false);
        await updateTransaction({value:false, transID:trans.trans_id, column:'visibility'});
        props.refreshSetter({type:'editTransactions'});
    } else {
        setVisibility(true);
        await updateTransaction({value:true, transID:trans.trans_id, column:'visibility'});
        props.refreshSetter({type:'editTransactions'});
    }
}


const [aiIconVisibility, setAiIconVisibility] = useState(trans.ai);

let handleClickAI = async () => {
    if (window.confirm('You are about to confirm this AI tagged transaction was properly assigned?')) {
        try {
            await updateTransaction({value: false, transID: trans.trans_id, column: 'ai'});
            setAiIconVisibility(false);
        } catch (error) {
            console.error('Failed to update transaction:', error);
        }
    }
}

   //put this in the category MUI component if i want to column that column
   let typeColor = trans.category === 'Income: Deposit' || trans.category === 'Income: Paycheck' || trans.category === 'Income: Rent' || trans.category === 'Income: Misc' ? green : red;


    return (
       <div className='outer'><div className={rowStyle}>
            
            <div className='transDate '>
                <TextField
                    id="standard-read-only-input"
                    type="date"
                    name='date'
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    value={displayDate}
                    onChange={handleChangeTrans}
                    onBlur={async () => {
                        await updateTransaction({value:trans.date, transID:trans.trans_id, column:'date'})
                        props.refreshSetter({type:'editTransactions'});
                        props.refreshSetter({type:'deleteTransactions'});

                    }}
                    fullWidth
                    sx={{ input: { color: color },
                    '& .MuiInputBase-input': {
                        marginTop: '2px', // Adjust top padding to align text properly
                        marginBottom: '-2px'
                    }  }}
                />
            </div>
            <div className='transMerchant'>
                    <TextField
                    required
                    id="standard-read-only-input"
                    name='vendor'
                    value = {trans.vendor}
                    variant="standard"
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeTrans}
                    onBlur={() => updateTransaction({value:trans.vendor, transID:trans.trans_id, column:'vendor'})}
                    sx={{ input: { color: color },
                    '& .MuiInputBase-input': {
                        marginTop: '2px', // Adjust top padding to align text properly
                        marginBottom: '-2px'
                    } }}
                />
            </div>

            
            <div className='transAmount'>
                <div className='amountDiv'>
                    {trans.amount > 0 ? <p style={{color:green}}>+$</p> : <p style={{color:red}}>-$</p>}
                </div>
                <TextField
                    required
                    id="standard-read-only-input"
                    name='amount'
                    type='number'
                    value= {displayValue}
                    variant="standard"
                    onChange={handleChangeAmount}
                    onBlur={async () => {
                        let res = updateChangeAmount();
                        await updateTransaction({value:res, transID:trans.trans_id, column:'amount'});
                        props.refreshSetter({type:'editTransactions'});
                        }}
                    fullWidth
                    onWheel={() => document.activeElement.blur()}
                    inputProps={{style: {textAlign: 'right'}}}
                    InputProps={{
                        disableUnderline: true,
                        }}
                    sx={{ input: { color: amountColor },
                    '& .MuiInputBase-input': {
                        marginTop: '2px', // Adjust top padding to align text properly
                        marginBottom: '-2px'
                    } }}
                />
            </div>

            <div className='transType'>
                {aiIconVisibility ? 
                
                    <Box style={{ margin: '0px' }}>
                        <Tooltip
                            title="This transaction has been assigned a category and property based on your past, manual assignments. Click the image to confirm assignment or update the category and property as needed."
                            arrow
                            enterDelay={100} // Delay before tooltip appears
                            leaveDelay={200} // Delay before tooltip disappears
                            placement="bottom" // Tooltip position
                            PopperProps={{
                                sx: {
                                  '& .MuiTooltip-tooltip': {
                                    backgroundColor: '#FEF5E9',
                                    border: '1px solid #F09E23',
                                    color: '#202B49', 
                                    fontSize: '14px', 
                                    borderRadius: '8px',
                                    fontFamily: 'inter, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
                                  },
                                  '& .MuiTooltip-arrow': {
                                    color: '#F09E23',
                                    
                                  },
                                },
                              }}
                        >
                            <img className='aiImage' onClick={handleClickAI} src={ai}/>
                        </Tooltip>
                        </Box>

                : <div className='aiImage'></div>}
                
                
                <TextField
                    fullWidth
                    id="standard-basic"
                    variant="standard"
                    select
                    required
                    name='category'
                    value={trans.category}
                    onChange={handleChangeTrans}
                    onBlur={() => {
                        updateTransaction({value:trans.category, transID:trans.trans_id, column:'category'})
                        setAiIconVisibility(false)
                    }}
                    size='small'
                    InputProps={{ disableUnderline: true}}
                    sx={{"& .MuiSelect-select": {color: typeColor},
                        '& .MuiInputBase-input': {
                            marginTop: '2px', // Adjust top padding to align text properly
                            marginBottom: '-2px'
                        }}} 

                    >
                    {categories.map((option) => (
                        <MenuItem key={option} value={option} >
                        {option}
                        </MenuItem>
                    ))}
                    </TextField>
            </div>
            
            <div className='transProperty '>
                
                
            <TextField
                fullWidth
                id="standard-basic"
                variant="standard"
                select
                required
                name='property'
                value={property.id}
                onChange={handleChange}
                size='small'
                InputProps={{ disableUnderline: true }}
                sx={{
                        '& .MuiInputBase-input': {
                            marginTop: '2px', // Adjust top padding to align text properly
                            marginBottom: '-2px'
                        }}} 
                
            >
                {newArr.map((option) => (
                    <MenuItem key={option.id} value={option.id} >
                    {option.id === -2 ? `${option.street}` : `${option.street}, ${option.city}, ${option.state}`}
                    </MenuItem>
                ))}
            </TextField>
                
                
                </div>
            {props.deviceWidth < 1050 ? '' : <p className='transAccount '>{trans.institution_name} {trans.account_num}</p>}
            <p className='transHide '><img src={split} onClick={() => props.toggleDrawer({drawerType:'splitTransaction', transInfo:trans})} /></p>
            <p className='transHide '><img src={image} onClick={handleClick} /></p>
        </div>
        </div>
    )
}