import React from "react";
import './tooltip.css';

export const ProjectExpenses = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p>You have what you think is a wonderful new investment property you want to buy. Buyer beware - there might be a number of serious repairs or renovations you'll have to do before you can get tenants in there.</p>
            <br />
            <p>Before you buy, take a second to price out all the renovation costs you'll need to do. Here are some common ones:</p>
            <br />
                <ul className='tooltipLI'>
                    <li>Replace the roof</li>
                    <li>Bathroom remodel</li>
                    <li>New flooring</li>
                    <li>New paint</li>
                    <li>Tree removal</li>
                    <li>Utility closet upgrade</li>
                    <li>Complete gut</li>
                </ul>
          
        </div>
    
    )

}