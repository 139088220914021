import React from "react";
import './Drawer.css';
import { Backdrop } from "../Backdrop/Backdrop";
import { EditProperty } from "./EditProperty/EditProperty";
import { Add } from "./Add/Add";
import { FirstProperty } from "./FirstProperty/FirstProperty";
import { Account } from "./Account/Account";
import { MonthlyTransactions } from "./MonthlyTransactions/MonthlyTransactions";
import Close from './Assets/Close.svg';
import { PlaidExplainer } from "./Plaid/PlaidExplainer";
import { BulkAdd } from "./BulkAdd/BulkAdd";
import { AccountRouting } from "./AccountRouting/AccountRouting";
import { EditRouting } from "./AccountRouting/EditRoutting";
import { LoadingPlaid } from "./AccountRouting/LoadingPlaid";
import { ManageInstitution } from "./AccountRouting/ManageInstitution";
import {SplitTransaction} from "./SplitTransaction/SplitTransaction"
import { EditTransaction } from "./EditTransaction/EditTransaction";
import { CustomButton } from "../../CoreComponents/CustomButton/CustomButton";
import { StartTrial } from "./StartTrial/StartTrial";
import { PaymentError } from "./PaymentError/PaymentError";
import { Summary } from "./Tooltip/Summary";
import { EscrowAdd } from "./EscrowAdd/EscrowAdd";
import { AddInterest } from "./AddInterest/AddInterest";
import { Transactions } from "./Onboarding/Transactions";
import { Mortgage } from "./Onboarding/Mortgage";
import { WhereStart } from "./Onboarding/WhereStart";
import { Tooltip } from "./Tooltip/Tooltip"

export const Drawer = (props) => {
    //mobile should come from props once i have this set up    
    let mobile = props.mobile;



    let classes = 'sideDrawer';
    if (mobile){
        classes = 'sideDrawerMobile'
    }

    if (props.status===true) {
        classes += ' sideDrawerOpen';
    }

    return (
        <div>
            {props.status ? <Backdrop toggleDrawer={props.toggleDrawer} type='right' drawerType={props.type} misc={props.misc} /> : ''}
            <div className={classes}>
                <div className='drawerContainer' >
                    {props.type === 'startTrial' || props.type === 'propertyTransactions' || props.type === 'propertyMortgage' || (props.type === 'accountRouting' && props.misc && props.misc.onboarding) ? '': <img src={Close} onClick={props.toggleDrawer} className='close'/>}
                    {props.type === 'firstProperty' ? <FirstProperty toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} accountStatus={props.accountStatus} hadTrial={props.hadTrial} /> : ''}
                    {props.type === 'account' ? <Account toggleDrawer={props.toggleDrawer} mobile={mobile} /> : ''}
                    {props.type === 'add' ? <Add toggleDrawer={props.toggleDrawer} properties={props.properties} refreshSetter={props.refreshSetter} accountStatus={props.accountStatus} hadTrial={props.hadTrial} /> : ''}
                    {props.type === 'editProperty' ? <EditProperty propertyID={props.propertyID} properties={props.properties} property={props.property} toggleDrawer={props.toggleDrawer} updatePropertyData={props.updatePropertyData} refreshSetter={props.refreshSetter} /> : ''}
                    
                    {props.type === 'plaidexplainer' ? <PlaidExplainer toggleDrawer={props.toggleDrawer} plaidToken={props.plaidToken} refreshSetter={props.refreshSetter} type='transaction'/> : ''}

                    {props.type === 'bulkadd' ? <BulkAdd properties={props.properties} toggleDrawer={props.toggleDrawer} misc={props.misc} /> : ''}

                    {props.type === 'accountRouting' ? <AccountRouting accounts={props.accounts} toggleDrawer={props.toggleDrawer} properties={props.properties} addAccount={props.addAccount} location='drawer' refreshSetter={props.refreshSetter} misc={props.misc} newProperty={props.newProperty}/>  : ''}
                    {props.type === 'editRouting' ? <EditRouting accountID={props.accountID} toggleDrawer={props.toggleDrawer} properties={props.properties} refreshSetter={props.refreshSetter} /> : ''}

                    {props.type === 'manageInstitution' ? <ManageInstitution institution={props.institution} toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ''}

                    {props.type === 'trans' ? <MonthlyTransactions month={props.month} propertyID={props.propertyID} window={props.window} payload={props.payload} /> : ''}
                    {props.type === 'splitTransaction' ? <SplitTransaction transInfo={props.transInfo} toggleDrawer={props.toggleDrawer} properties={props.properties} refreshSetter={props.refreshSetter}/> : ''}
                    {props.type === 'editTransaction' ? <EditTransaction transInfo={props.transInfo} toggleDrawer={props.toggleDrawer} properties={props.properties} refreshSetter={props.refreshSetter} mobileType={props.mobileType}/> : ''}

                    {props.type === 'startTrial' ? <StartTrial newProperty={props.newProperty} refreshSetter={props.refreshSetter} toggleDrawer={props.toggleDrawer} /> : ''}
                    {props.type === 'escrowTaxes' ? <EscrowAdd properties={props.properties} type='taxes' toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ''}
                    {props.type === 'escrowInsurance' ? <EscrowAdd properties={props.properties} type='insurance' toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ''}
                    {props.type === 'addInterest' ? <AddInterest toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ''}
                    {props.type === 'propertyTransactions' ? <Transactions toggleDrawer={props.toggleDrawer} newProperty={props.newProperty} refreshSetter={props.refreshSetter} mobile={mobile} /> : ''}
                    {props.type === 'propertyMortgage' ? <Mortgage toggleDrawer={props.toggleDrawer} newProperty={props.newProperty} refreshSetter={props.refreshSetter} accountStatus={props.accountStatus} hadTrial={props.hadTrial} /> : ''}
                    {props.type === 'whereStart' ? <WhereStart toggleDrawer={props.toggleDrawer} properties={props.properties} pageSetter={props.pageSetter} page={props.page} /> : ''}
                    
                    {props.type === 'paymentError' ? <PaymentError /> : ''}
                    {props.type === 'loadingPlaid' ? <LoadingPlaid /> : ''}



                    {props.type === 'tooltip' ? <Tooltip tip={props.payload}/> : ''}





                </div>
            </div>
            
            
        </div>
    )

};