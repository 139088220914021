import React from "react";
import './BulkAdd.css';
import {parse} from 'papaparse';
import { addBulkTransaction } from "../../../../api/properties";
import { CSVLink, CSVDownload } from "react-csv";


export const BulkAdd = (props) => {
    let properties = props.properties;
    function isValidDate(dateString) {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date);
      }

    let handleDrag = (e) => {
        e.preventDefault();
        e.target.style.backgroundColor = '#F0FAF9'
    }
    let handleDragLeave = (e) => {
        e.preventDefault();
        e.target.style.backgroundColor = 'white';
    }
    let handleDrop = (e) => {
        e.preventDefault();
        Array.from(e.dataTransfer.files).map (async file => {
            let text = await file.text();
            let result = parse(text, {header:true})
            let arr = [];
            for (let i=0; i<result.data.length; i++) {
                let currency = result.data[i].amount;
                let amount = Number(currency.replace(/[^0-9.-]+/g,''));
                let category = result.data[i].category;
                let vendor = result.data[i].vendor;
                let date = result.data[i].date;
                let index = properties.findIndex((e) => e.street === result.data[i].street);

                //accounts for unrecognized addresses
                let propertyID=-3;
                if (index >=0){
                    // accounts recognized addresses
                propertyID = properties[index].id; 
                } else if (index === -1) {
                    if (result.data[i].street === ''){
                        //accounts for blanks which mean non-property expenses
                        propertyID = -2; 
                    } 
                }

                if(!isValidDate(date)) {
                    date = new Date();
                }

                let obj = {
                    propertyID: propertyID,
                    category: category,
                    date: date,
                    vendor: vendor,
                    visibility: true,
                    amount: amount,
                }
                arr.push(obj);
            }


            let i = 0;
            do {
            let startingValue = i;
            let endingValue = arr.length;
            if (i + 50 <= arr.length) {
                endingValue = i + 50;
            } 
            let newarr = arr.slice(startingValue, endingValue);
            await addBulkTransaction({arr: newarr});
            i = i + 50;
            } while (i < arr.length);

            if (props.misc === 'onboarding') {
                props.toggleDrawer({drawerType:'propertyMortgage', newProperty:properties[0]});
            } else {
                props.refreshSetter({type:'newTrans'});
                props.toggleDrawer({drawerType:''});
            }
            


        })
    }
    
    
    
    
    
    

    
    let bulkSample2=[
        ['category', 'date', 'vendor', 'amount', 'street'],
        ['The above column headers must be included exactly as written, but delete everything below when ready (you will want to save the below categories somewhere)'],
        [
        'Category must match one of the options provided below',
        'Date should be DATE formatted, ideally yyyy-mm-dd', 
        'Vendor is who the transactions was with (EG, Amazon, AirBnb, Tenant Bob, etc)', 
        'Amount must be a number or currency',
        'Street is the street (including house number) of the property that had the income/expense and should match EXACTLY as you’ve entered it in WayBoz (eg, "Dr", "Dr." and "Drive" are not the same...). If this is for a personal / non-property expense, leave this blank.'
        ],
        ['Expense: Misc', '2024-03-23', 'AirBnb Payment', '1123.43', '123 Maple Street'],
        ['Expense: Misc'],
        ['Income: Misc'],

        ['Income: Deposit'],
        ['Income: Paycheck'],
        ['Income: Rent'],
    
        ['Insurances: Homeowners'],
        ['Insurances: Homeowners (via Escrow)'],
        ['Insurances: PMI'],
        ['Insurances: Umbrella'],
    
        ['Payments: Downpayment & Closing Costs'],
        ['Payments: Mortgage'],
        ['Payments: Mortgage Interest (via Mortgage)'],
        ['Payments: Property Taxes'],
        ['Payments: Property Taxes (via Escrow)'],
    
        ['Purchases: Appliances'],
        ['Purchases: Furnishings'],
        ['Purchases: Misc Supplies'],
    
        ['Repairs: Appliance/Furnishing'],
        ['Repairs: Home - Basic'],
        ['Repairs: Home - Major Improvement (per IRS)'],

        ['Services: Accounting'],
        ['Services: Cleaning'],
        ['Services: HOA'],
        ['Services: Legal'],
        ['Services: Misc'],
        ['Services: Pest Control'],
        ['Services: Property Management'],
        ['Services: Security'],
        ['Services: Yard Care'],
    
        ['Utilities: Electric'],
        ['Utilities: Gas'],
        ['Utilities: Internet'],
        ['Utilities: Sewer & Misc'],
       ['Utilities: TV'],
       ['Utilities: Water'],
    
        ['Business: Federal/State Taxes'],
        ['Business: Misc Expense'],
        ['Business: Phone'],
        ['Business: Software'],
        ['Business: Travel & Fuel']
    ]
    
    
    
    return (
        
        <div className='content bodyTextBlue'>
            <div className="uploadBox"  >
                <p className='secondaryLabelGray' onDragOver={e => handleDrag(e)} onDragLeave={e => handleDragLeave(e)} onDrop = {e => handleDrop(e)}><em>Drop CSV here when ready!</em></p>
            </div>

            <p className='secondaryLabelBlue '>IMPORTANT FORMATTING INSTRUCTIONS</p>
            <ol className='limarg content bodyTextBlue'>
                <li>Follow the example <CSVLink data={bulkSample2}>found here</CSVLink></li>  
                <li>Ensure you have already added all your properties to WayBoz</li>
                <li>Include the following five (5) column headers spelled exactly as shown: date, vendor, amount, category, street</li>
                <li>Date should be "date" formatted, ideally yyyy-mm-dd</li>
                <li>Vendor is who the transactions were with (EG, Amazon, AirBnb, Tenant Bob, etc)</li>
                <li>Amount must be a number or currency</li>
                <li>Make sure your expenses are NEGATIVE numbers (otherwise we won't know what is an expense versus a refund)</li>
                <li>Street is the street (including house number) of the property that had the income/expense and should match EXACTLY as you’ve entered it in WayBoz (eg, "Dr", "Dr." and "Drive" are not the same...)</li>
                <li>If you want to include a personal expense, leave the Street cell completely blank</li>
                <li>Category must match the options provided in the sample</li>
                <li>Save your spreadsheet as a CSV</li>
                <li>Finally, if you get stuck, just send your spreadsheet to <span className='emailClick semiBold' onClick={() => {
                                        window.location.href = 'mailto:help@wayboz.com';
                                        }}>help@wayboz.com</span> and we'll prep it for you!</li>

            </ol>
            

        </div>
  
    )
};