import React from "react";
import './Shared.css';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { States } from '../../../../Utilities/states';
import { formatDate, textfieldColorChanges } from "./Misc";

export const PropertyAddress = (props) => {
    let property = props.property;
    let handleChange=props.handleChange
    const states = States;

    return (
        <div className='groupContainer'>
        <TextField
            required
            id="standard-read-only-input"
            label="Street"
            name='street'
            value = {property.street}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            margin='normal'
            sx={textfieldColorChanges}
        />
        <div className='MultiOnRow'>
            <TextField
                required
                id="standard-read-only-input"
                label="City"
                name='city'
                value = {property.city}
                variant="outlined"
                className='boxWidth1'
                onChange={handleChange}
                margin='normal'
                sx={textfieldColorChanges} 
            />
            <TextField
                id="outlined-select-state"
                select
                required
                name='state'
                label="State"
                value={property.state}
                onChange={handleChange}
                className='boxWidth2'
                margin='normal'
                sx={textfieldColorChanges}
            >
                {states.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                required
                id="standard-read-only-input"
                label="Zip"
                name='zip'
                value = {property.zip}
                variant="outlined"
                className='boxWidth2'
                onChange={handleChange}
                margin='normal'
                sx={textfieldColorChanges}
                onWheel={() => document.activeElement.blur()}
                type='number'
        
            />
        </div>
    </div>
    )
                                    
};