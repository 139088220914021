import React from "react";
import './tooltip.css';

export const IncludeAllPropertiesTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            
            <p className='paragraphMargin'>On most pages in WayBoz, your data is viewable both individual per property AND collectively.  </p>

            <p className='paragraphMargin'>This collective view is under the "All Properties" option on each page which simple sums or aggregates the data points of all your individual properties.</p>
            
            <p className='paragraphMargin'>However, many WayBoz users have properties that they don't necessarily want to include in this "All Properties" summary view. 
            Perhaps it's a personal property that carries different weight than other investment properties. Perhaps it's a property that is now sold.
            Perhaps it is a long term play that produces a terrible current return that you simply don't want included in your overall metrics (at least for now). </p>

            <p className='paragraphMargin'>By unchecking "Include in All Properties data", data from the specific property you are editing will be removed from your summary data. <em>Note: it ONLY removes data from this property from the property performance page, NOT from the tax page.</em> </p>

            <p className='paragraphMargin'>Properties that are not included in the "All Properties" data are slightly grayed out and appear at the end of the properties menu.</p>
          
        </div>
    
    )

}