
import React, { useState, useEffect } from "react";
import { passwordColorChanges, textfieldColorChanges } from "../Shared/Misc";
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { getColor } from '../../../../Utilities/functions';

import { formatDate, altFormatNumber } from "../Shared/Misc";

import { deleteTransaction, addTransaction, updateFullTransaction } from "../../../../api/properties";

import EditGray from '../../../CoreStructure/InfoContainer/Assets/EditGray.svg';
import split from '../../../../SharedAssets/split.svg';
import trash from '../../../../SharedAssets/trash.svg';
import show from '../../../../SharedAssets/show.svg';
import hide from '../../../../SharedAssets/hide.svg';
import ai from '../../../../SharedAssets/ai.svg';



import './EditTransaction.css';

import { categories } from '../../../../Utilities/categories';


export const EditTransaction = (props) => {
    const [trans, setTrans] = useState(props.transInfo);
    const [displayValue, setDisplayValue] = useState(props.transInfo.amount);
    //const [amountWidth, setAmountWidth] = useState()

    let red = getColor('--negative');
    let green = getColor('--positive');
    let amountColor = trans.amount > 0  ? green : red;
    let blue = getColor('--waybozBlue')
    let color = blue;


    // Stuff for Amount ---------------
    let identifyDisplayValue = () => {
        if (trans.amount < 0 ) {
            return trans.amount * -1;
        } else {
            return trans.amount;
        }
    }
    
    useEffect(() => {
        let dv = identifyDisplayValue();
        setDisplayValue(altFormatNumber({value:dv, type:'numberWithDecimals'}))
        }, []);

    let handleChangeAmount = (e) => {
        let newVal;
        newVal = e.target.value;
        //let transInfo = { ...trans, [e.target.name]: newVal };
        setDisplayValue(newVal);
    }
    
    let updateChangeAmount = () => {
        let newVal;
        if (trans.amount < 0 && displayValue >= 0){
            newVal = displayValue*-1;
        } else if (trans.amount < 0 && displayValue < 0) {
            newVal = displayValue*-1;
        } else if (trans.amount >= 0 && displayValue >= 0){
            newVal = displayValue;
        } else if (trans.amount >= 0 && displayValue < 0) {
            newVal = displayValue;
        } else {
            newVal = displayValue;
        }
        let transInfo = { ...trans, ['amount']: newVal };
        if (newVal < 0){
            setDisplayValue(altFormatNumber({value:newVal*-1, type:'numberWithDecimals'}))
        } else {
            setDisplayValue(altFormatNumber({value:newVal, type:'numberWithDecimals'}))
        }
        setTrans(transInfo);
        return newVal;
    }
    
 
    // ------- end stuff for Amount -----------
    


    // ------- MIsc ------
    let handleChangeTrans = (e) => {
        let newVal;

        // if user enters a bad date, creates a date based on today's date
        if(e.target.name === 'date' && !e.target.value){
            let newDate = new Date;
            let year = newDate.getFullYear();
            let month = newDate.getMonth() + 1;
            let day = newDate.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            newVal = year + '-' + month + '-' + day;
        } else {
            newVal = e.target.value;
        }
        let transInfo = { ...trans, [e.target.name]: newVal };
        setTrans(transInfo);
        }

    

//----- Properties ------
const [property, setProperty] = useState({id:''});
    useEffect(() => {
        if (trans.property_id) {
            setProperty({ id:trans.property_id })
        }
        
      }, [trans.property_id])

    let handleChange = async (e) => {
        let property = newArr.findIndex((element) => element.id === e.target.value);
        let newProp = newArr[property];
        trans.street = newProp.street;
        let transInfo = { ...trans, ['property_id']: newProp.id };
        setTrans(transInfo);
        setProperty(newProp);
    }

    let newArr = [{id:-2, street:'Non Property (Personal) Transaction', city:'', state:''}]
    let properties=props.properties;
    for (let i=0; i<properties.length; i++)
    {
    newArr.push(properties[i])
    }

// ------- end properties -----


const [image, setImage] = useState();
useEffect(() => {
    let imgSrc;
    if (trans.institution_id === null) {
        trans.institution_name = 'Manually Added';
        imgSrc = trash;
    } else if (trans.visibility === true) {
        imgSrc = show;
    } else {
        imgSrc = hide;
    }
    setImage(imgSrc)
  }, [trans.visibility, trans.property_id])

  let handleClick = async () => {
    if (image === trash) {
        if (window.confirm('Are you sure you want to delete this transaction? This cannot be undone.')) {
            await deleteTransaction({transID:trans.trans_id});
            props.toggleDrawer({drawerType:'' })
            props.refreshSetter({type:'deleteTransactions'});
            props.refreshSetter({type:'editTransactions'});
        }
    } else if (image === show) {
        let transInfo = { ...trans, ['visibility']: false };
        setTrans(transInfo);
    } else {
        let transInfo = { ...trans, ['visibility']: true };
        setTrans(transInfo);
    }
}

let submit = async () => {
    await updateFullTransaction(trans);
    props.refreshSetter({type:'deleteTransactions'});
    props.refreshSetter({type:'editTransactions'});
}



// ------- mobile scaling stuff --------
let amountWidth= '250px';
let amountTextSize = 72;
let mobileType = props.mobileType
if (trans.amount) {
    if (mobileType === 'large') {
        amountWidth = `${displayValue.length*42}px`;
        amountTextSize = 72
    } else if (mobileType === 'medium') {
        amountWidth = `${displayValue.length*37}px`;
        amountTextSize = 64
    } else if (mobileType === 'small') {
        amountWidth = `${displayValue.length*32}px`;
        amountTextSize = 58
    } else {
        amountWidth = `${displayValue.length*28}px`;
        amountTextSize = 48
    }
}


    return (
        <form className='drawerContainer2' onSubmit={(e) => {
            {submit()}

            //window.location.reload(false)
            props.toggleDrawer({drawerType:''});
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Edit Transaction</p>
                <div className='center'>
                    <img className='editImg' src={EditGray} />
                </div>
                

                {/*amount section*/}
                <div className='amountContainer'>
                   <div className="amount" >
                        <div style={{fontSize:amountTextSize}}>
                            {trans.amount > 0 ? <p style={{color:green}}>+$</p> : <p style={{color:red}}>-$</p>}
                        </div> 
                            <TextField
                            required
                            size="small"
                            id="standard-read-only-input"
                            name='amount'
                            type='number'
                            value= {displayValue}
                            variant="standard"
                            onChange={handleChangeAmount}
                            onBlur={async () => {
                                updateChangeAmount();
                                }}
                            onWheel={() => document.activeElement.blur()}
                            InputProps={{
                                disableUnderline: true,
                                }}
                            sx={{ input: { color: amountColor, fontSize: amountTextSize, width: amountWidth} }}
                        />
                        
                        
                    </div>
                    <div className='accountLine tertiaryLabel'>
                        <p>{trans.institution_name}</p>
                        <p>{trans.account_num}</p>
                    </div>
                    {trans.ai ? 
                        <div className = 'ai tertiaryLabelLight'>
                            <img className='aiImageEditTrans'  src={ai}/>
                            <p>Auto tagged with AI. Confirm details below.</p>
                        </div> 
                        : 
                        <div className = 'ai tertiaryLabelLight'></div>}
                </div>
                   
                


                  {/*Merchant */} 
                <div>
                    <TextField
                    required
                    label="Source"
                    id="standard-read-only-input"
                    name='vendor'
                    value = {trans.vendor}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeTrans}
                    sx={textfieldColorChanges}
                    />
                </div>


                <TextField
                fullWidth
                id="outlined-select-state"
                select
                name='property'
                label="Assign to a property"
                value={property.id}
                onChange={handleChange}
                className='boxWidth2'
                margin='normal'
                sx={textfieldColorChanges}
            >  
                {newArr.map((option) => (
                    <MenuItem key={option.id} value={option.id} >
                    {option.id === -2 ? `${option.street}` : `${option.street}, ${option.city}, ${option.state}`}
                    </MenuItem>
                ))}

            </TextField>



            <TextField
            fullWidth
            id="outlined-select-state"
            select
            required
            name='category'
            label="Category"
            value={trans.category}
            onChange={handleChangeTrans}
            className='boxWidth2'
            margin='normal'
            sx={textfieldColorChanges}
            >
                {categories.map((option) => (
                    <MenuItem key={option} value={option} >
                    {option}
                    </MenuItem>
                ))}
            </TextField>



            <TextField
            required
            id="standard-read-only-input"
            label="Date"
            name='date'
            type='date'
            value = {formatDate({date:trans.date, format:'ymd'})}
            variant="outlined"
            fullWidth
            onChange={handleChangeTrans}
            margin='normal'
            sx={textfieldColorChanges}
            />

                <div className='splitter' onClick={() => handleClick()}>
                    {image === trash ? <p className='secondaryLabelBlue'>DELETE TRANSACTION?</p> 
                    : image === hide ? <p className='secondaryLabelBlue'>SHOW TRANSACTION?</p>
                    : <p className='secondaryLabelBlue'>HIDE TRANSACTION?</p>
                    }
                    <img src={image} />
                </div>

                <div className='splitter' onClick={() => props.toggleDrawer({drawerType:'splitTransaction', transInfo:trans})}>
                    <p className='secondaryLabelBlue'>SPLIT TRANSACTION?</p>
                    <img src={split} />
                </div>
                

               
            </div>











            <div>
                <CustomButton type='submit'>DONE</CustomButton>
            </div>
        </form>
    )
};