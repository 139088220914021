import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/roeChartImg.png'


export const ChartROETip = () => {

    return (
    
        <div className='content bodyTextBlue'>
             <p className='paragraphMargin bodyTextGray'><em>Before reading below, please ensure you have read the previous "tool tip" on Return on Equity.</em></p>
            
            <p className='paragraphMargin '>The below chart is one of the most powerful in WayBoz as it shows two key things.</p>

            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin '><strong>First, you can see how your ROE is trending over time. </strong></p>
            <p className='paragraphMargin '> What many don't realize is your ROE often goes down year after year. 
                This is because you typically have more and more equity in your property each year which means it is <em>costing</em> you more money to make money. 
                Another way to think about it is you are dedicating more money each year to make roughly the same amount of money, so your overall return is less.</p>

            <p className='paragraphMargin '><strong>Second, you can see how well your property investments are performing compared to other investment options.</strong></p>

            <p className='paragraphMargin '>The dashed horizontal lines represent the returns of the S&P500, Bitcoin, REITs, and Large Cap stocks. 
                The question you should be asking yourself is whether or not the cash you have committed to your property -- your equity -- is giving you a better or worse return than your other investment options.
                This chart lets you see exactly that.
            </p>



          
        </div>
    
    )

}