import React from 'react';
import './PropertyTab.css';
import house from '../../../../SharedAssets/house.png';
import unassigned from '../../../../SharedAssets/unassigned.svg';
import personal from '../../../../SharedAssets/personal.png';
import ai from '../../../../SharedAssets/ai.svg';



export const PropertyTab = (props) => {

let classes = 'propertyTab';
    if (props.selectionStatus === true) {
        classes += ' selected secondaryLabelWhite';
    } else {
        classes += ' unselected secondaryLabelBlue';
    }

    if (props.included === false) {
        classes += ' notIncluded'
    }

    return (
        <div className={classes} onClick={props.onClick} >
            {
                props.id === 'unassigned' ?
                    <div className='unassigned'>
                        <img src={unassigned} />
                    </div>

                : props.id === 'ai' ?
                <div className='aiTab'>
                    <img src={ai} />
                </div>

                : props.id === 'personal' ?
                <div className='personal'>
                    <img src={personal} />
                </div>

                : props.image ?
                    <div className='propertyTabImgContainer'>
                        <img src={props.image} />
                    </div>

                :   <div className='propertyTabImgContainer'>
                        <img src={house} />
                    </div>
            }

            <div className='propertyTabInfo'>
                {props.id === 'all' && props.type ==='transaction' ? (<p>All Properties</p>) : props.id === 'all' && props.type !=='transaction' ? (<p>All Properties</p>) : props.id === 'unassigned' ? (<p>Unassigned</p>) : props.id === 'ai' ? (<p>AI</p>) : props.id === 'personal' ? (<p>Personal /</p>) : (<p>{props.street}</p>)}
                {props.id === 'all' ? '' : props.id === 'unassigned' ? (<p>Transactions</p>) : props.id === 'ai' ? (<p>Auto Assigned</p>) : props.id === 'personal' ? (<p>Non-Property</p>) :(<p>{props.city}, {props.state}</p>)}
            </div>
        </div>
    )
}
