import React from 'react';
import { BarChart, Line, ComposedChart, ReferenceLine, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Bar, ResponsiveContainer } from 'recharts';
import { 
    getColor
    } from '../../../../../Utilities/functions';
import { yAxisFormatter } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { tooltipMonthLabel } from '../../../../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
   

export const WindowCashFlowChart = (props) => {
    let expense = getColor('--negative');
    let income = getColor('--positive');
    let lineColor = getColor('--lineGray');
    let cashflow = getColor('--cashFlow');
    let data=props.data;

    let window = props.window;
    let year = props.year;

    let getStyle = (item) => {
        if (item === 'Income' ){
            return 'positive'
        } else if (item === "Expense") {
            return 'negative'
        } else {
            return 'cashFlow'
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    let formatNum = (num) => {
        return formatter.format(num);
    }

    let getStyle2 = (item) => {
        if (item.name === 'Cashflow') {
            return 'flexDisplay borderTop sumPadTop'
        } else {
            return 'flexDisplay'
        }
    }


    function CustomTooltip({ payload, label, active }) {

        if (active) {
          return (
            <div className="custom-tooltip">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{props.window} {label}</p> 
                </div>
    
              <div className='payload'>
                
                    {payload.map((item, i) => {
                        return (
                        <div>
                            <div key={i} className={getStyle2(item)}>
                                <p className={getStyle(item.name)} >{item.name === 'Cashflow' ? "Cash Flow" : item.name}:</p>
                                <p className={"value "}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2
                                }).format((item.value.toFixed(2)))}`}</p>
                            </div>
                            <div className='quadLabelLight avgMarBottom'>
                                <p><em><FormatNumber 
                                    value={item.name === 'Cashflow' ? props.averageWindowCashFlow : item.name === 'Income' ? props.averageWindowIncome : props.averageWindowExpense} type='dollar'  />
                                    </em></p>
                            </div>
                        </div>
                        )
                    })}
            
    
              </div>
              <div className='quadLabelLight avgDisclaimer ' >
                <p><em>* {props.window} averages </em></p>
              </div>
             
            </div>
          );
        }
        return null;
      }

    
    let combinedArray = props.windowIncomeLast10.map((item, index) => {
        let total1 = props.windowIncomeLast10[index] ? props.windowIncomeLast10[index].total : 0;
        let total2 = props.windowExpenseLast10[index] ? props.windowExpenseLast10[index].total : 0;
        let total3 = props.windowCashFlowLast10[index] ? props.windowCashFlowLast10[index].total : 0;
    
        return {
            month: props.window,
            year: item.year,
            Income: total1,
            Expense: total2,
            Cashflow: total3
        };
    });


    let mobileArray = combinedArray.slice(-5);
        
    let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;

    return (
        <div>
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
                <ComposedChart data={mobile ? mobileArray : combinedArray} barGap={2}  margin={{bottom:30}}>
                    <CartesianGrid vertical={false} horizontal={false} stroke={lineColor} />
                    <XAxis dataKey="year" angle={-45} tickMargin='20' interval='preserveStartEnd' fontSize={mobile ? 14 : 16} />
                    <YAxis tickFormatter={yAxisFormatter} domain={['auto', 'auto']} allowDataOverflow={true} width={mobile ? 70 : 75} fontSize={mobile ? 14 : 16}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar className='hoverPointer' dataKey="Income" fill={income} barSize={mobile ? 10 : 15} onClick={(payload) => {
                    props.toggleDrawer({drawerType:'trans', window, payload, id:props.propertyID})
                    }}/>
                    <Bar className='hoverPointer' dataKey="Expense" fill={expense} barSize={mobile ? 10 : 15} onClick={(payload) => {
                    props.toggleDrawer({drawerType:'trans', window, payload, id:props.propertyID})
                    }}/>
                    <Line type="monotone" dataKey="Cashflow" stroke={cashflow} strokeWidth={2}  />
                    
                    <ReferenceLine y='0'  stroke={lineColor} strokeWidth={1} />
                    <ReferenceLine y={props.averageWindowIncome} stroke={income} strokeDasharray="7 7" strokeWidth={1} />
                    <ReferenceLine y={props.averageWindowExpense} stroke={expense} strokeDasharray="7 7" strokeWidth={1} />
                    <ReferenceLine y={props.averageWindowCashFlow} stroke={cashflow} strokeDasharray="7 7" strokeWidth={1} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>  
        

        
    )
}