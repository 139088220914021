
import React, { useState, useEffect } from "react";
import { passwordColorChanges, textfieldColorChanges } from "../Shared/Misc";
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { formatDate, altFormatNumber } from "../Shared/Misc";

import { deleteTransaction, addTransaction } from "../../../../api/properties";

import split from '../../../../SharedAssets/split.svg';
import add from '../../Nav/Assets/add.svg';

import './SplitTransaction.css';

import { categories } from '../../../../Utilities/categories';


export const SplitTransaction = (props) => {
    let trans=props.transInfo;
    

    let newArr = [{id:-2, street:'Non Property (Personal) Transaction', city:'', state:''}]
    let properties=props.properties;
    for (let i=0; i<properties.length; i++) {
        newArr.push(properties[i])
    }

    const [remainder, setRemainder] = useState(0);
    const [newTrans, setNewTrans] = useState([])
    const [newVal, setNewVal] = useState(trans.amount);
    

    useEffect(() => {
        let newState;
        if(trans.amount < 0) {
            let newAmt = altFormatNumber({value: trans.amount*-1, type:'numberWithDecimals'});
            setNewVal(newAmt);
            newState = [ ...newTrans, {property: trans.property_id, category: trans.category, amount: newAmt }];
        } else {
            let newAmt = altFormatNumber({value: trans.amount, type:'numberWithDecimals'});
            newState = [ ...newTrans, {property: trans.property_id, category: trans.category, amount: newAmt}];
        }
        setNewTrans(newState);
      }, []);






    
    
    let handleChange = ({ind, name, value}) => {    
        let newRemainder = newVal;
        let numberIndex = Number(ind); 
        let newState = newTrans.map((obj,index) => {
            
            if(name==='amount'){
                if (numberIndex===index){
                    newRemainder = newRemainder - value;
                } else {
                    newRemainder = newRemainder -  obj.amount;
                }
                newRemainder = Number(newRemainder.toFixed(2));
                setRemainder(newRemainder);
            }
            if (numberIndex===index){
                return {...obj, [name]: value }
            }
            return obj;
        })
        setNewTrans(newState);
    }

    //this is used to format the number onBlur (clicks out of text field)
        let updateChangeAmount = () => {
                let newState = newTrans.map((obj) => {   
                    let value = altFormatNumber({value: obj.amount, type:'numberWithDecimals'})
                        return {...obj, 'amount': value }
                })
                setNewTrans(newState);
        }
    


    let handleChangeProperty = (e) => {
        handleChange({ind:e.target.name, name:'property', value:e.target.value})
    }

    let handleChangeCategory = (e) => {
        handleChange({ind:e.target.name, name:'category', value:e.target.value})
    }

    let handleChangeAmount = (e) => {
        handleChange({ind:e.target.name, name:'amount', value:e.target.value})
    }

    let handleClick = () => {
        let newState = [ ...newTrans, {property: '', category:trans.category, amount:'' }];
        setNewTrans(newState);
    }


    let handleSubmit = async (e) => {
        if (remainder !== 0) {
            if (window.confirm(`Your "remainder" should be $0.00 to ensure you've used all of the original transaction amount, but it's not... Are you sure you want to continue?`)) {
                props.toggleDrawer({drawerType:'', id:''});
                await deleteTransaction({transID:trans.trans_id});
                newTrans.map(newTran => {
                    let amt
                    if(trans.amount < 0) {
                        amt = newTran.amount*-1;
                    } else {
                        amt = newTran.amount;
                    }
                    //might need to add async awaits
                    addTransaction({propertyID:newTran.property, category:newTran.category, date:trans.date, vendor: trans.vendor, visibility: trans.visibility, amount:amt});
                })
                e.preventDefault();
                props.refreshSetter({type:'deleteTransactions'});
                props.refreshSetter({type:'editTransactions'});
            } else {
                e.preventDefault();
            }      
        } else {
            props.toggleDrawer({drawerType:'', id:''});
            await deleteTransaction({transID:trans.trans_id});
            newTrans.map(newTran => {
                let amt;
                if(trans.amount < 0) {
                    amt = newTran.amount*-1;
                } else {
                    amt = newTran.amount;
                }
                //might need to add async awaits
                addTransaction({propertyID:newTran.property, category:newTran.category, date:trans.date, vendor: trans.vendor, visibility: trans.visibility, amount:amt});
            })
            e.preventDefault();
            props.refreshSetter({type:'deleteTransactions'});
            props.refreshSetter({type:'editTransactions'});

        }   
         
    }

    let showTrans = newTrans.map((item, index) => {
        
        return <div key={index}>
                    <p className='secondaryLabelBlue secondaryTitle'>TRANSACTION {index+1}</p>
                    
                    <TextField
                        id="outlined-select-state"
                        select
                        required
                        name={index.toString()}
                        label="For which property?"
                        value={item.property}
                        onChange={handleChangeProperty}
                        className='col1Width'
                        margin='normal'
                        sx={textfieldColorChanges}
                    >
            
                        {newArr.map((option) => (
                            <MenuItem key={option.id} value={option.id} >
                            {option.id === -2 ? `${option.street}` : `${option.street}, ${option.city}, ${option.state}`}
                            </MenuItem>
                        ))}

                    </TextField>
                    <div className='MultiOnRow'>
                        <TextField
                            id="outlined-select-state"
                            select
                            required
                            name={index.toString()}
                            label="What transaction category?"
                            value={item.category}
                            onChange={handleChangeCategory}
                            className='col1Width'
                            margin='normal'
                            sx={textfieldColorChanges}
                        >
                
                            {categories.map((option) => (
                                <MenuItem key={option} value={option} >
                                {option}
                                </MenuItem>
                            ))}

                        </TextField>

                        <TextField
                            
                            id="standard-read-only-input"
                            label="Amount"
                            required
                            name={index.toString()}
                            value = {item.amount}
                            variant="outlined"
                            onChange={handleChangeAmount}
                            onBlur={updateChangeAmount}
                            className='col2Width'
                            margin='normal'
                            sx={textfieldColorChanges}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }} 
                            
                        />
                    </div>

                    


                </div>
    })


    
    
    
    return (
        <form className='drawerContainer2' onSubmit={(e) => {
            handleSubmit(e);
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Split Transaction</p>
                <div className='center'>
                    <img className='editImg' src={split} />
                </div>
                
                <div className='spaceBelow'>
                    <div className='flexed'>
                            <p className='secondaryLabelBlue secondaryTitle rightMarg'>MERCHANT:</p>
                            <p className='secondaryTitle multiLineEllipsis'>{trans.vendor}</p>
                    </div>
                    
                    <div className='topFlexed'>
                        
                        
                        <div className='flexed'>
                            <p className='secondaryLabelBlue secondaryTitle rightMarg'>DATE:</p>
                            <p className='secondaryTitle'>{formatDate({date: trans.date, order: 'mdy'})}</p>
                        </div>

                        <div className='flexed'>
                            <p className='secondaryLabelBlue secondaryTitle rightMarg'>AMOUNT TO SPLIT:</p>
                            {
                                trans.amount < 0 ? <p className='secondaryTitle '>{altFormatNumber({value: trans.amount*-1, type:'dollar'})}</p> : <p className='secondaryTitle '>{altFormatNumber({value: trans.amount, type:'dollar'})}</p>
                            }
                        </div>
                    </div>
                    
                    
                </div>


                {showTrans}

                <div className='flexed remainder'>
                    <p className='secondaryLabelBlue secondaryTitle rightMarg'>REMAINDER:</p>
                    

                    {
                        
                        remainder === 0 ? <p className='secondaryTitle positive'>{altFormatNumber({value: remainder, type:'dollar'})}</p>
                        : <p className='secondaryTitle negative'>{altFormatNumber({value: remainder, type:'dollar'})}</p>
                    }
                    
                </div>

                <div className='center topMargin'>
                    <img src={add} className='image' onClick={handleClick}/>
                </div>

            </div>











            <div>
                <CustomButton type='submit'>DONE</CustomButton>
            </div>
        </form>
    )
};