import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/ltvImg.png'


export const PropertyValueChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>

            <p className='paragraphMargin'>The below chart details 4 key metrics:</p>

            <ol className='paragraphMargin'>
                <li>Property Value</li>
                <li>Property Debt</li>
                <li>Property Equity</li>
                <li>Loan to Value Ratio (LTV)</li>
            </ol>
           
            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin '>In the chart, the total vertical area represents your property value with the green denoting your equity and the red denoting your debt.
                As you hover on the chart you will see a "tool tip" with a breakdown of the calculations for each month.</p>

            <p className='paragraphMargin '>In the "tool tip" you will also find your Loan to Value Ratio, or LTV. While this number is important in many ways, there are two key instances when you should be paying close attention to this metric:</p>
          
            <ol className='paragraphMargin'>
                <li className='paragraphMargin'><strong>PMI</strong>: Typically when you purchase a property and put less than 20% down (I.E., an LTV greater than 80%), you have to pay PMI or private mortgage insurance to protect the lender in case you default. In theory, your PMI charges should cease when your principal payments take your LTV below 80%. However, this does NOT take into account any accelerated decrease in LTV due to property appreciation or increases in value from major home improvements. In other words, you might still be paying PMI when you no londer need to. Therefore, if you notice your LTV is less than 80% and you are still paying PMI, it is often wise to reach out to your loan servicer to see how you can get that fee dropped.*</li>
                <li><strong>HELOCs</strong>: Home Equity Lines of Credit, or HELOCs, are ways to leverage your home's equity for access to cash. It is much like a credit card, but your available balance is whatever you qualified for with your HELOC. To qualify for a HELOC, typically you need to have an LTV less than 80%**. The amount that you would qualify for then is however much equity, that if used, would <em>increase</em> your LTV back to 80%. For example, let's say your property is worth $1,000,000 and you have a debt of $600,000. Therefore, your LTV is 60%. For an 80% LTV, you would have a debt of $800,000. Your HELOC, therefore, gives you access to the difference between $800,000 and $600,000, which is $200,000. Simply put, you now have a $200,000 line of credit you can access and do whatever you want with.  </li>
            </ol>
            <hr className = 'horLineSolid'/>
            <p className='paragraphMargin bodyTextGray'>*Typically if your LTV drops below 80% ahead of time solely due to appreciation, your servicer will not drop your PMI. However, if your decrease is due to a major home improvement, an appraiser will be sent out and you typically will qualify to have your PMI removed if your Loan to Value is now under 80%.</p>
            <p className='paragraphMargin bodyTextGray'>**Some HELOCs allow for LTVs up to 90%.</p>

        </div>
    
    )

}