import React, {useState, useEffect} from 'react';
import { Loading } from '../CoreComponents/Loading/Loading';
import { InfoContainer } from '../CoreStructure/InfoContainer/InfoContainer';
import { PropertyMenu } from '../PropertiesMain/Properties/PropertyMenu/PropertyMenu';
import { PropertiesEmpty } from '../PropertiesMain/PropertiesEmpty/PropertiesEmpty';
import { InstitutionMenu } from './InstitutionMenu';
import { 
    getTransactions,
    deleteTransaction,
    getTransactionCount,
    deletePlaceholders,
    getAllTransactions
} from '../../api/properties';
import { PaymentError } from '../CoreStructure/Drawer/PaymentError/PaymentError';

export const 
Transactions = (props) => {
    let properties = props.properties;
    const [transactions, setTransactions] = useState();
    const [allTransactions, setAllTransactions] = useState();

    const [property, setProperty] = useState({id:-1});
    const [pageNum, setPageNum] = useState(1);
    const [count, setCount] = useState();
    const [perPage, setPerPage] = useState(50);
    const [noRender, setNoRender] = useState(true);

    let account = props.plaidAccount;

    let updateProperty = ({property}) => {
        setProperty(property);
    }

    useEffect(async () => {
        if (noRender === false){
            let offSet = ((pageNum - 1) * perPage);
            await getTransactions({propertyID:property.id, offSet:offSet, perPage:perPage, accountID:account}).then(setTransactions);
        }
        window.scrollTo({
            top: 340,
            left: 0,
            behavior: 'smooth'
          });
      }, [pageNum]);


    useEffect( async () => {
        await getTransactionCount({propertyID:property.id, accountID:account}).then((res) => {
            setCount(res);
            setPageNum(1);
        });

        let offSet = ((pageNum - 1) * perPage);
        await getTransactions({propertyID:property.id, offSet:offSet, perPage:perPage, accountID:account}).then(setTransactions);
        setNoRender(false);
    }, [property, account, props.transactionsRefresher]);

    useEffect(() => {
        getAllTransactions().then(setAllTransactions);
      }, []);


    let handleSetAccount=props.updatePlaidAccount;


    let handleSetPage = ({page}) => {
        setPageNum(page);
    }

      
    let deleteTrans = async ({transID}) => {
        await deleteTransaction({transID:transID});
        setCount(count-1);
        let offSet = ((pageNum - 1) * perPage);
        await getTransactions({propertyID:property.id, offSet:offSet, perPage:perPage, accountID:account}).then(setTransactions);
        props.refreshSetter({type:'editTransactions'});
    }

    let handleRemovePlaceholders = async () => {
        if (window.confirm('Are you sure you want to delete your placeholder data? This cannot be undone.')) {
            await deletePlaceholders();

            await getTransactionCount({propertyID:property.id, accountID:account}).then((res) => {
                setCount(res);
                setPageNum(1);
            });
            let offSet = ((pageNum - 1) * perPage);
            await getTransactions({propertyID:property.id, offSet:offSet, perPage:perPage, accountID:account}).then(setTransactions);
            
            props.refreshSetter({type:'editTransactions'});
        }
       }

    if (!properties || !transactions || !props.accountStatus) {
        return <Loading />;
    }  else if (properties.length < 1) {
            return <PropertiesEmpty toggleDrawer={props.toggleDrawer} type='properties'/>
    } else if (props.accountStatus === 'payment-bad' || (props.accountStatus === 'pretrial' && props.hadTrial === true)) {
        return <PaymentError body='body'/>
    } else {

    let pages = Math.ceil(count/perPage);
    

        return (
            <div className='properties'>
                <InstitutionMenu toggleDrawer={props.toggleDrawer} account={account} handleSetAccount={handleSetAccount} institutionsRefresher={props.institutionsRefresher}/>
                <PropertyMenu toggleDrawer={props.toggleDrawer} properties={properties} property={property} updateProperty={updateProperty} type={'transaction'} allTransactions={allTransactions} refreshSetter={props.refreshSetter} handleRemovePlaceholders={handleRemovePlaceholders}/>
                <InfoContainer transactions={transactions} 
                    properties={properties} 
                    property={property} 
                    deleteTrans={deleteTrans} 
                    toggleDrawer={props.toggleDrawer}
                    pageNum={pageNum}
                    pages={pages}
                    handleSetPage={handleSetPage}
                    refreshSetter={props.refreshSetter}
                    mobile={props.mobile}
                    deviceWidth={props.deviceWidth}

                />
            </div>
        )

    }
  
};