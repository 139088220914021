import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../Login/Login";
import {ResetPassword} from "../Login/Reset"
import { Logout } from "../Login/Logout";
import {isTokenLive} from '../../Utilities/auth/auth'
import { Main } from '../App/Main'
import { PropaneSharp } from "@mui/icons-material";
import { useState, useEffect } from 'react';



function Router() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth)
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    let breakpoint=800;

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={isTokenLive() ? <Main width={width}/> : <Navigate to={{ pathname: "/login"}}/>} />
                <Route exact path="/login" element={ isTokenLive() ? <Navigate to={{ pathname: "/"}}/> : <Login breakpoint={breakpoint} width={width}/>} />
                <Route exact path="/logout" element={<Logout/>} />
                <Route exact path="/reset/:id-:token" element={<ResetPassword breakpoint={breakpoint} width={width}/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
