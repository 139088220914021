import React from "react";
import './tooltip.css';
import image from '../../../../SharedAssets/PersonalExpVsPropCashFlowByYearImg.png'


export const PersonalExpensesYearlyChartTip = () => {

    return (
    
        <div className='content bodyTextBlue'>
            <p className='paragraphMargin'>One of the main reasons our users love WayBoz is the ability to see if they can retire!</p>

            <p className='paragraphMargin'>Obviously, a key step to retirement is making sure your investments - wherever they may be - cover your personal expenses.
            From a real estate perspective, WayBoz can give you complete transparency into this.</p>
            
            <p className='paragraphMargin'>Although managing your personal expenses is NOT the purpose of WayBoz, we've set it up so you can easily add your personal incomes and expenses as desired and then compare them to your property cash flow. Then, voila, you can see if you are earning enough from your properties to cover any and all personal expenses. </p>

            <img className='tooltipImage' src={image} />

            <p className='paragraphMargin'>In the above chart, the red solid line shows how your personal expenses are trending year over year, 
                while the blue solid line shows your property cash flow for each year. The horizontal dashed lines represent your <em>average annual</em> personal expenses and property cash flow for all time*.</p>
                
            <p className='paragraphMargin'>As with other charts, you can hover on the chart to see the specific values for each year.</p>
            
            <p className='paragraphMargin'>What's the goal? Get the blue line above the red line. This means that your property cash flow can sufficiently cover all your personal expenses and you can look forward to sunburn on the beach for the rest of your life.</p>
          
            <hr className = 'horLineSolid'/>
             <p className='paragraphMargin bodyTextGray'><em>* In case you're wondering, calculations for your averages are based on the date of your oldest transaction. In other words,
                if you purchased your home in 2008 but have only added data to WayBoz as far back as 2020, your averages will NOT be impacted by a series of $0 income and expenses from 2008 until 2020. </em></p>
            <p className='paragraphMargin bodyTextGray'><em>Also, remember, averages are impacted by whether or not you have elected to "Include Capital Expenditures in Averages."  See checkbox at the top of your screen. </em></p>
           

        </div>
    
    )

}