import React from 'react';
import { Tip } from '../Tip/Tip';
import  tooltip from './../../../../../SharedAssets/tooltip.svg';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import { AllTimeRunningTotalChart } from './AllTimeRunningTotalChart';
import { PropertyValueChart } from './PropertyValueChart';




export const AllTime = (props) => {
    

return (

    <div className='roe section'>
       <p className='primaryMetric sectionTitle'>All Time Earnings: {props.mobileType && props.mobileType !== 'large' ? <br />:''}<FormatNumber value={props.allTimeTVG} type='dollarRounded' colorMe='yes' /></p>
        <p className='secondaryLabelBlueLight sectionTitle'>Considering all your cash flow, principal paid, and appreciation, you've {props.allTimeTVG >= 0 ? 'earned' : 'lost'} <FormatNumber value={props.allTimeTVG} type='dollarRounded' colorMe='yes' /> in value since purchase. What did it take to earn that? Your initial downpayment total of <FormatNumber value={props.downpayment} type='dollarRounded' />. See below how it went year over year. We also gave you a quick view of your property value & debt for the selected year ({props.year}).</p>
        <div className='charts'>
            
            
            

            <div className='chartColHalf secondaryLabelBlue'>
                <div className='tooltip'>
                    <p>All Time Value Gained Running Total (overall and per category)</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'allTimeRunningTotalChartTip'})} />
                </div>
                <div className='metricsTable bodyTextBlue'>
                    <AllTimeRunningTotalChart 

                        yearlyRunningTotalCashFlowForAllTime={props.yearlyRunningTotalCashFlowForAllTime}
                        yearlyRunningTotalAppreciationForAllTime={props.yearlyRunningTotalAppreciationForAllTime}
                        yearlyRunningTotalPrincipleForAllTime={props.yearlyRunningTotalPrincipleForAllTime}
                        yearlyRunningTotalTVGForAllTime={props.yearlyRunningTotalTVGForAllTime}
                        mobileType={props.mobileType}


                        />
                </div>
            </div>


            <div className='chartColHalf secondaryLabelBlue'>       
                <div className='tooltip'>
                    <p>{props.year} Property Value & Equity</p>
                    <img src={tooltip} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'propertyValueChartTip'})} />
                </div>
                <div className='metricsTable bodyTextBlue'>
                    <PropertyValueChart 
                        monthlyLTVForYear={props.monthlyLTVForYear} 
                        toggleDrawer={props.toggleDrawer} id={props.id}
                        mobileType={props.mobileType}

                        />

                </div>
            </div>
            
        </div>
        {/*<div className='tip'>
            <Tip />
        </div>*/}
                
    </div>



)

}