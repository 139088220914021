import React from 'react'
import './Backdrop.css'

export const Backdrop = (props) => {
    let classes = 'backdrop'
    if (props.type==='nav') {
        classes += ' navDrawer';
        return <div className={classes} onMouseEnter={props.toggleDrawer} />
    } else if (props.drawerType==='startTrial' || props.drawerType === 'propertyTransactions' || props.drawerType === 'propertyMortgage' || (props.drawerType === 'accountRouting' && props.misc && props.misc.onboarding) ) {
        classes += ' rightDrawer';
        return <div className={classes}  />
    }
    else {
        classes += ' rightDrawer';
        return <div className={classes} onClick={props.toggleDrawer} />
    }

    
}